import { type___locale___translations___collection } from "../../types/types.locale";

//	E X P O R T   L O C A L E

export const locale___MdSignPasswordResetModal: type___locale___translations___collection = {
	'close_modal': {
		de_de: '________________________',
		en_us: '________________________',
		es_es: '________________________',
		fr_fr: '________________________',
		it_it: 'chiudi',
	},
	'title': {
		de_de: '________________________',
		en_us: '________________________',
		es_es: '________________________',
		fr_fr: '________________________',
		it_it: 'Reset della password',
	},
	'description_1': {
		de_de: '________________________',
		en_us: '________________________',
		es_es: '________________________',
		fr_fr: '________________________',
		it_it: 'Per procedere al reset della password inserisci la tua email e segui le istruzioni via mail',
	},
	'description_2': {
		de_de: '________________________',
		en_us: '________________________',
		es_es: '________________________',
		fr_fr: '________________________',
		it_it: 'Normalmente la mail di reset arriva subito ma può essere necessario attendere fino a 10 minuti',
	},
	'email_placeholder': {
		de_de: '________________________',
		en_us: '________________________',
		es_es: '________________________',
		fr_fr: '________________________',
		it_it: 'latua@email.com',
	},
	'button_recover': {
		de_de: '________________________',
		en_us: '________________________',
		es_es: '________________________',
		fr_fr: '________________________',
		it_it: 'Recupera Password',
	},
	'toast___success': {
		de_de: '________________________',
		en_us: '________________________',
		es_es: '________________________',
		fr_fr: '________________________',
		it_it: 'Abbiamo inviato le istruzioni per resettare la tua password alla tua email',
	},
	'toast___error': {
		de_de: '________________________',
		en_us: '________________________',
		es_es: '________________________',
		fr_fr: '________________________',
		it_it: 'Errore nella reimpostazione della password, sei sicuro di aver inserito la mail corretta?',
	},
};