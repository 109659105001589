import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComUserPrivateComponent: type___locale___translations___collection = {
	'profile_is_private': {
		de_de: '{{1}} hat sein Profil privat gemacht. Folgen Sie ihm. Wenn er zustimmt, können Sie seine Inhalte sehen',
		en_us: '{{1}} has made his profile private, follow him and if he accepts you will be able to see his contents',
		es_es: '{{1}} ha hecho su perfil privado, síguelo y si acepta podrás ver sus contenidos',
		fr_fr: '{{1}} a rendu son profil privé, suivez-le et s\'il accepte vous pourrez voir son contenu',
		it_it: '{{1}} ha reso il suo profilo privato, seguilo e se accetterà potrai vedere i suoi contenuti',
	},
};