import { type___locale___translations___collection } from "../../../types/types.locale";

//	E X P O R T   L O C A L E

export const locale___SbEventViewComponent: type___locale___translations___collection = {
	'event_commands___notifications': {
		de_de: '__________________',
		en_us: '__________________',
		es_es: '__________________',
		fr_fr: '__________________',
		it_it: 'Gestione Notifiche',
	},
	'event_commands___settings': {
		de_de: 'Andere Einstellungen',
		en_us: 'Other settings',
		es_es: 'Otras configuraciones',
		fr_fr: 'Autres paramètres',
		it_it: 'Altre impostazioni',
	},
	'distance_from_you': {
		de_de: 'ca. {{1}} von Ihnen entfernt',
		en_us: 'about {{1}} from you',
		es_es: 'aproximadamente {{1}} de ti',
		fr_fr: 'à environ {{1}} de distance',
		it_it: 'a circa {{1}} da te',
	},
	'event_user_label': {
		de_de: 'Veranstaltung organisiert von',
		en_us: 'event organized by',
		es_es: 'evento organizado por',
		fr_fr: 'événement organisé par',
		it_it: 'evento organizzato da',
	},
};