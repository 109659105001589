import React from 'react'

//	S T Y L E

import './com-chat-message-viewed.scss'

//	S T A T E   P R O P S

import { props___ComChatMessageViewedComponent } from './com-chat-message-viewed.state';

//	F U N C S

import { funcs_datetime } from '../../funcs/funcs.datetime';

//	C L A S S

export default class ComChatMessageViewedComponent extends React.Component<props___ComChatMessageViewedComponent, {}>
{

//#region 																							D E C L A R A T I O N S

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="com-chat-message-viewed---container">
				<p>Visualizzato il { this.funcs___datetime.datetime___get___date(this.props.message_props['message_read_datetime']) } alle { this.funcs___datetime.datetime___get___time(this.props.message_props['message_read_datetime']) }</p>
			</div>
		</>;
	}

//#endregion

}