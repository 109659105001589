import { type___locale___translations___collection } from "../types/types.locale";

//	E X P O R T   L O C A L E   -->    funcs_geolocation

export const locale___funcs_datetime: type___locale___translations___collection = {
	'mon': {
		de_de: 'Montag',
		en_us: 'Monday',
		es_es: 'Lunes',
		fr_fr: 'Lundi',
		it_it: 'Lunedì',
	},
	'tue': {
		de_de: 'Dienstag',
		en_us: 'Tuesday',
		es_es: 'Martes',
		fr_fr: 'Mardi',
		it_it: 'Martedì',
	},
	'wed': {
		de_de: 'Mittwoch',
		en_us: 'Wednesday',
		es_es: 'Miércoles',
		fr_fr: 'Mercredi',
		it_it: 'Mercoledì',
	},
	'thu': {
		de_de: 'Donnerstag',
		en_us: 'Thursday',
		es_es: 'Jueves',
		fr_fr: 'Jeudi',
		it_it: 'Giovedì',
	},
	'fri': {
		de_de: 'Freitag',
		en_us: 'Friday',
		es_es: 'Viernes',
		fr_fr: 'Vendredi',
		it_it: 'Venerdì',
	},
	'sat': {
		de_de: 'Samstag',
		en_us: 'Saturday',
		es_es: 'Sábado',
		fr_fr: 'Samedi',
		it_it: 'Sabato',
	},
	'sun': {
		de_de: 'Sonntag',
		en_us: 'Sunday',
		es_es: 'Domingo',
		fr_fr: 'Dimanche',
		it_it: 'Domenica',
	},
	'time_ago___now': {
		de_de: 'Im Augenblick',
		en_us: 'Ahora Mismo',
		es_es: 'Just Now',
		fr_fr: 'Tout de suite',
		it_it: 'Proprio Adesso',
	},
	'time_ago___now___abbr': {
		de_de: 'Jetzt',
		en_us: 'Now',
		es_es: 'Ahora',
		fr_fr: 'Maintenant',
		it_it: 'Adesso',
	},
	'time_ago___minutes': {
		de_de: 'Vor {{1}} Minuten',
		en_us: '{{1}} minutes ago',
		es_es: 'Hace {{1}} minutos',
		fr_fr: 'Il y a {{1}} minutes',
		it_it: '{{1}} minuti fa',
	},
	'time_ago___minutes___abbr': {
		de_de: '_____________________',
		en_us: '_____________________',
		es_es: '_____________________',
		fr_fr: '_____________________',
		it_it: '{{1}} min',
	},
	'time_ago___hours': {
		de_de: 'Vor {{1}} Stunden',
		en_us: '{{1}} hours ago',
		es_es: 'Hace {{1}} horas',
		fr_fr: 'Il y a {{1}} heures',
		it_it: '{{1}} ore fa',
	},
	'time_ago___hours___abbr': {
		de_de: '_____________________',
		en_us: '{{1}} h',
		es_es: '_____________________',
		fr_fr: '_____________________',
		it_it: '{{1}} h',
	},
	'time_ago___days': {
		de_de: 'Vor {{1}} Tagen',
		en_us: '{{1}} days ago',
		es_es: 'Hace {{1}} días',
		fr_fr: 'Il y a {{1}} jours',
		it_it: '{{1}} giorni fa',
	},
	'time_ago___days___abbr': {
		de_de: '_____________________',
		en_us: '{{1}} d',
		es_es: '_____________________',
		fr_fr: '_____________________',
		it_it: '{{1}} g',
	},
	'time_ago___weeks': {
		de_de: 'Vor {{1}} Wochen',
		en_us: '{{1}} weeks ago',
		es_es: 'Hace {{1}} semanas',
		fr_fr: 'Il y a {{1}} semaines',
		it_it: '{{1}} settimane fa',
	},
	'time_ago___weeks___abbr': {
		de_de: '_____________________',
		en_us: '{{1}} w',
		es_es: '_____________________',
		fr_fr: '_____________________',
		it_it: '{{1}} sett',
	},
	'time_ago___months': {
		de_de: 'Vor {{1}} Monaten',
		en_us: '{{1}} months ago',
		es_es: 'Hace {{1}} meses',
		fr_fr: 'Il y a {{1}} mois',
		it_it: '{{1}} mesi fa',
	},
	'time_ago___months___abbr': {
		de_de: '_____________________',
		en_us: '{{1}} m',
		es_es: '_____________________',
		fr_fr: '_____________________',
		it_it: '{{1}} m',
	},
	'time_ago___years': {
		de_de: 'Vor {{1}} Jahren',
		en_us: '{{1}} years ago',
		es_es: 'Hace {{1}} años',
		fr_fr: 'Hace {{1}} años',
		it_it: '{{1}} anni fa',
	},
	'time_ago___years___abbr': {
		de_de: '_____________________',
		en_us: '{{1}} y',
		es_es: '_____________________',
		fr_fr: '_____________________',
		it_it: '{{1}} anni',
	},
};

//	E X P O R T   L O C A L E   -->    funcs_geolocation

export const locale___funcs_geolocation: type___locale___translations___collection = {
	'address_not_availble': {
		de_de: 'Adresse nicht verfügbar',
		en_us: 'Address not available',
		es_es: 'Dirección no disponible',
		fr_fr: 'Adresse non disponible',
		it_it: 'Indirizzo non disponibile',
	},
};

//	E X P O R T   L O C A L E   -->   funcs_measurements

export const locale___funcs_measurements: type___locale___translations___collection = {
	'unit___g': {
		de_de: '/ g',
		en_us: '/ g',
		es_es: '/ g',
		fr_fr: '/ g',
		it_it: '/ g',
	},
	'unit___g___extended': {
		de_de: 'pro gramm',
		en_us: 'per gram',
		es_es: 'por gramo',
		fr_fr: 'par gramme',
		it_it: 'al grammo',
	},
	'unit___kg': {
		de_de: '/ kg',
		en_us: '/ kg',
		es_es: '/ kg',
		fr_fr: '/ kg',
		it_it: '/ kg',
	},
	'unit___kg___extended': {
		de_de: 'pro kilo',
		en_us: 'per kilo',
		es_es: 'por kilo',
		fr_fr: 'par kilo',
		it_it: 'al chilo',
	},
	'unit___l': {
		de_de: '/ l',
		en_us: '/ l',
		es_es: '/ l',
		fr_fr: '/ l',
		it_it: '/ l',
	},
	'unit___l___extended': {
		de_de: 'pro liter',
		en_us: 'per liter',
		es_es: 'por litro',
		fr_fr: 'par litre',
		it_it: 'al litro',
	},
};

//	E X P O R T   L O C A L E   -->   funcs_numbers

export const locale___funcs_numbers: type___locale___translations___collection = {
	'unit___meters': {
		de_de: 'meter',
		en_us: 'meters',
		es_es: 'metros',
		fr_fr: 'mètres',
		it_it: 'metri',
	},
	'unit___kms': {
		de_de: 'km',
		en_us: 'km',
		es_es: 'km',
		fr_fr: 'km',
		it_it: 'km',
	},
};