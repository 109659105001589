import React from 'react';
import { IonContent, IonPage, withIonLifeCycle } from '@ionic/react';

//	C O M P O N E N T S

import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';

//	E X P O R T

class SbLandingLoadingPage extends React.Component<{}, {}>
{

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonPage>
				<IonContent forceOverscroll={ false }>
					<br />
					<br />
					<br />
					<br />
					<br />
					<br />
					<br />
					<br />
					<br />
					<ComSpinnerComponent size="standard"/>
				</IonContent>
			</IonPage>
		</>
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbLandingLoadingPage);