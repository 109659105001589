import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonLabel, IonPage, IonRefresher, IonSegment, IonSegmentButton, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './sb-poi-view-tales.scss';

//	F U N C S

import { funcs_geolocation } from '../../../funcs/funcs.geolocation';
import { funcs_numbers } from '../../../funcs/funcs.numbers';

//	S T A T E   -   P R O P S

import { props___SbPoiViewTalesPage, state___SbPoiViewTalesPage, state___SbPoiViewTalesPage___default } from './sb-poi-view-tales.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';
import { service_Router } from '../../../services/service-router';

//	C O M P O N E N T S

import Com404Component from '../../../components/com-404/com-404';
import ComInfiniteScrollerComponent from '../../../components/com-infinite-scroller/com-infinite-scroller';
import ComListEmptyComponent from '../../../components/com-list-empty/com-list-empty';
import ComPoiComponent from '../../../components/com-poi/com-poi';
import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';
import ComSpinnerInlineComponent from '../../../components/com-spinner-inline/com-spinner-inline';
import ComTaleComponent from '../../../components/com-tale/com-tale';

//	M O D A L S

import MdTalesViewModal from '../../../modals/md-tales-view/md-tales-view';


//	C L A S S

class SbPoiViewTalesPage extends React.Component<props___SbPoiViewTalesPage, state___SbPoiViewTalesPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _ROUTER: service_Router = new service_Router();

	private readonly funcs___geolocation: funcs_geolocation = new funcs_geolocation();
	private readonly funcs____numbers: funcs_numbers = new funcs_numbers();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbPoiViewTalesPage,
	) {
		super(props);
		this.state = state___SbPoiViewTalesPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___poi = async () => {
		const ___temp___poi___props: type___api___response = await this._API.poi___read___single(this.state.poi___id);
		if (!___temp___poi___props || ___temp___poi___props.response !== 'success' || ___temp___poi___props.data === null) { console.log('404'); }
		this.setState({
			poi___props: ___temp___poi___props.data,
			poi___props___is_loading: false,
		});
	}

	private read_props___poi_tales = async () => {
		const ___temp___poi_tales: type___api___response = await this._API.tales___read___multi('poi', this.state.poi___id, this.state.poi___tales.length, this.state.poi___tales___sort);
		this.setState({
			poi___tales: ___temp___poi_tales.data,
			poi___tales___is_loading: false,
		});
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___page___refresh = async (___e: CustomEvent<RefresherEventDetail>) => {
		await this.read_props___poi();
		await this.read_props___poi_tales();
		___e.detail.complete();
	}

	private handle___switch___sorting = (___sort: state___SbPoiViewTalesPage['poi___tales___sort']) => {
		this.setState({
			poi___tales___sort: ___sort,
			poi___tales: [],
			poi___tales___is_loading: true,
			poi___tales___is_complete: false,
		}, async () => {
			await this.read_props___poi_tales();
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		const ___poi_id: string | null = this._ROUTER.get_id(this.props);
		if (___poi_id) {
			this.setState({
				poi___id: ___poi_id,
			}, async () => {
				await this.read_props___poi();
				await this.read_props___poi_tales();
			});
		}
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<MdTalesViewModal isOpen={ this.state.ionModal___MdTaleViewModal___isOpen === true && this.state.ionModal___MdTaleViewModal___argument !== '' } tale_id={ this.state.ionModal___MdTaleViewModal___argument } event___onDidDismiss={() => { this.setState({ ionModal___MdTaleViewModal___isOpen: false, ionModal___MdTaleViewModal___argument: '' }); }}/>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref={ '/view/poi' + this.state.poi___id }></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					{(this.state.poi___props___is_loading === true) ? <>

						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<ComSpinnerComponent size="small"/>

					</> : <>

						<IonRefresher slot="fixed" onIonRefresh={ this.handle___page___refresh }>
							<ComSpinnerInlineComponent/>
						</IonRefresher>


						{(this.state.poi___props !== null) ? <>

							<div className="sb-page---container sb-poi-view-tales---container">

								<ComPoiComponent poi_props={ this.state.poi___props } size='large'/>

								<IonSegment value={ this.state.poi___tales___sort } onIonChange={(___e: any) => { this.handle___switch___sorting(___e.detail.value); }}>
									<IonSegmentButton value="popular">Più Popolari</IonSegmentButton>
									<IonSegmentButton value="standard">Più Recenti</IonSegmentButton>
								</IonSegment>

								<br />

								<div className="sb-user-profile---tab sb-user-profile---tab---is-tales">
									{this.state.poi___tales.map((___t: any, ___t___idx: number) => {
										return <>
											<ComTaleComponent key={ ___t___idx } tale___props={ ___t } event___onClick={() => { this.setState({ ionModal___MdTaleViewModal___isOpen: true, ionModal___MdTaleViewModal___argument: ___t['tale_id'] }) }}/>
										</>
									})}
									{(this.state.poi___tales___is_complete === true) ? <>
										<ComListEmptyComponent list_items_quantity={ this.state.poi___tales.length }/>
									</> : <>
										<ComInfiniteScrollerComponent is_loading={ this.state.poi___tales___is_loading } event___scrollTriggered={ this.read_props___poi_tales } />
									</>}
								</div>





							
							




















							</div>
						</> : <>
							<Com404Component/>
						</>}

					</>}
				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbPoiViewTalesPage);