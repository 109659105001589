import React from 'react'
import { IonCard } from '@ionic/react';

//	S T Y L E

import './com-catalog-item.scss'

//	S T A T E S   -   P R O P S

import { props___ComPoiCatalogItemComponent } from './com-catalog-item.state';

//	F U N C S

import { funcs_numbers } from '../../funcs/funcs.numbers';

//	C L A S S

export default class ComPoiCatalogItemComponent extends React.Component<props___ComPoiCatalogItemComponent, {}>
{

//#region 																							C O N S T R U C T O R

	private readonly funcs___numbers: funcs_numbers = new funcs_numbers();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonCard className="com-catalog-item---component">
				{(this.props.item_props['item_gallery'] && this.props.item_props['item_gallery'].length > 0) ? <>
					<img src={ this.props.item_props['item_gallery'][0] }/>
				</> : <>
					<img src={ this.props.item_props['item_gallery_fallback'] }/>
				</>}
				<h5>{ this.props.item_props['item_name'] }</h5>
				<p>{ this.funcs___numbers.format___price(this.props.item_props['item_price'], '€') }</p>
			</IonCard>
		</>;
	}

//#endregion

}