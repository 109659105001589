import { RouteComponentProps } from "react-router";
import { interface___is_logged } from "../../../interfaces/interface.logged";

//	P R O P S

export interface props___SbEventViewSettingsPage extends RouteComponentProps<{ id: string; }>, interface___is_logged {

}

//	S T A T E

export interface state___SbEventViewSettingsPage {

	event___id: string,
	event___props: any | null,
	event___props___is_loading: boolean, 

}

//	S T A T E   D E F A U L T

export const state___SbEventViewSettingsPage___default: state___SbEventViewSettingsPage = {

	event___id: '',
	event___props: null,
	event___props___is_loading: true, 

}