import { interface___is_logged } from "../../interfaces/interface.logged";
import { interface___modal } from "../../interfaces/interface.modals";
import { type___map___filters, type___map___style } from "../../types/types.types";

//	P R O P S

export interface props___MdDiscoverFiltersModal extends interface___modal, interface___is_logged {

	filters: type___map___filters
	
}

//	S T A T E

export interface state___MdDiscoverFiltersModal {

	selectors_are___is_loading: boolean,

	selector___show_events: boolean,
	selector___show_poi: boolean,
	selector___show_tales: boolean,
	
	selector___map_style: type___map___style

}

//	S T A T E   D E F A U L T

export const state___MdDiscoverFiltersModal___default: state___MdDiscoverFiltersModal = {
	
	selectors_are___is_loading: true,

	selector___show_events: true,
	selector___show_poi: true,
	selector___show_tales: false,
	
	selector___map_style: 'normal'

}