import { type___locale___translations___collection } from "../types/types.locale";

//	E X P O R T   L O C A L E   -->    service_Sharing

export const locale___service_Sharing: type___locale___translations___collection = {
	'share_text': {
		de_de: 'Schauen Sie, was ich auf nearby gefunden habe 🤩🤩 \nSchauen Sie sich "{{1}}" an\n',
		en_us: 'Look what I found on nearby 🤩🤩 \nCheck out "{{1}}"\n',
		es_es: 'Mira lo que encontré en nearby 🤩🤩 \nMira "{{1}}"\n',
		fr_fr: 'Regardez ce que j\'ai trouvé sur nearby 🤩🤩 \nDécouvrez "{{1}}"\n',
		it_it: 'Guarda cos\'ho trovato su nearby 🤩🤩 \nDai un\'occhiata a "{{1}}"\n',
	},
};

//	E X P O R T   L O C A L E   -->    service_Notifications

export const locale___service_Notifications: type___locale___translations___collection = {
	'notification_text___follow': {
		de_de: '{{1}} hat begonnen, Ihnen zu folgen',
		en_us: '{{1}} started following you',
		es_es: '{{1}} comenzó a seguirte',
		fr_fr: '{{1}} a commencé à vous suivre',
		it_it: '{{1}} ha iniziato a seguirti',
	},
	'notification_text___post': {
		de_de: '{{1}} hat etwas in Ihrem Profil geschrieben',
		en_us: '{{1}} wrote something on your profile',
		es_es: '{{1}} escribió algo en tu perfil',
		fr_fr: '{{1}} a écrit quelque chose sur votre profil',
		it_it: '{{1}} ha scritto qualcosa sul tuo profilo',
	},
	'notification_text___post_comment': {
		de_de: '{{1}} hat Ihren Beitrag kommentiert',
		en_us: '{{1}} commented on your post',
		es_es: '{{1}} comentó tu publicación',
		fr_fr: '{{1}} a commenté votre message',
		it_it: '{{1}} ha commentato il tuo post',
	},
	'notification_text___post_like': {
		de_de: '{{1}} mochte Ihren Beitrag',
		en_us: '{{1}} liked your post',
		es_es: 'A {{1}} le gustó tu publicación',
		fr_fr: '{{1}} a aimé votre message',
		it_it: '{{1}} ha messo like al tuo post',
	},
	'notification_text___post_tag': {
		de_de: '{{1}} hat Sie in seinem Beitrag markiert',
		en_us: '{{1}} tagged you in his post',
		es_es: '{{1}} te etiquetó en su publicación',
		fr_fr: '{{1}} vous a tagué dans son message',
		it_it: '{{1}} ti ha taggato nel suo post',
	},
	'notification_text___tale_like': {
		de_de: '{{1}} hat deine Tale gefallen',
		en_us: '{{1}} liked your Tale',
		es_es: 'A {{1}} le gustó tu Tale',
		fr_fr: '{{1}} a aimé votre Tale',
		it_it: '{{1}} ha messo un like alla tua Tale',
	},
	'notification_text___tale_tag': {
		de_de: '{{1}} hat Sie in seiner Tale markiert',
		en_us: '{{1}} tagged you in his Tale',
		es_es: '{{1}} te etiquetó en su Tale',
		fr_fr: '{{1}} vous a tagué dans son Tale',
		it_it: '{{1}} ti ha taggato nella sua Tale',
	},
	'notification_text___generic': {
		de_de: '____________________________',
		en_us: '____________________________',
		es_es: '____________________________',
		fr_fr: '____________________________',
		it_it: 'Hai una nuova notifica',
	},









};