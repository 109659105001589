/* 
 * 
 * 
 * 
 * 								L I B   E R R O R S   S H A R E D
 * 
 * 
 * 
 */

export const lib_errors_shared = {
	
	jwt___register___generic: 'jwt___register___generic',

	login___username___not_valid: 'login___username___not_valid',
	login___password___not_valid: 'login___password___not_valid',

	password_change___password___not_valid: 'password_change___password___not_valid',

	register___email___not_valid: 'register___email___not_valid',
	register___password___not_valid: 'register___password___not_valid',
	register___username___not_valid: 'register___username___not_valid',





	



}