//	P R O P S

export interface props___ComUserRequestComponent {

	user___props: any,

	event___onActionPerformed: () => any | void 

}


//	S T A T E

export interface state___ComUserRequestComponent {

	request_is_processed: boolean,

	ionToast___requestIsProcessed: 'accept' | 'reject' | 'error' | null

}

//	S T A T E   D E F A U L T

export const state___ComUserRequestComponent___default: state___ComUserRequestComponent = {

	request_is_processed: false,

	ionToast___requestIsProcessed: null

}