import { type___locale___translations___collection } from "../../../types/types.locale";

//	E X P O R T   L O C A L E

export const locale___SbLandingGeolocationPage: type___locale___translations___collection = {
	'title': {
		de_de: 'Nearby benötigt Ihren Standort',
		en_us: 'Nearby needs your location',
		es_es: 'Nearby necesita tu ubicación',
		fr_fr: 'Nearby a besoin de votre emplacement',
		it_it: 'Nearby ha bisogno della tua posizione',
	},
	'description_1': {
		de_de: 'Um ordnungsgemäß zu funktionieren, muss Nearby Ihren Standort nutzen. Wir werden Ihre Bewegungen weder aufzeichnen noch die erfassten Daten für andere als die in den Allgemeinen Geschäftsbedingungen beschriebenen Zwecke verwenden',
		en_us: 'To function properly, Nearby needs to use your location, we will not record your movements nor will we use the data collected for purposes other than those described in the terms and conditions',
		es_es: 'Para funcionar correctamente, Nearby necesita utilizar su ubicación, no registraremos sus movimientos ni utilizaremos los datos recopilados para fines distintos a los descritos en los términos y condiciones',
		fr_fr: 'Pour fonctionner correctement, Nearby a besoin d\'utiliser votre localisation, nous n\'enregistrerons pas vos mouvements et n\'utiliserons pas les données collectées à des fins autres que celles décrites dans les termes et conditions',
		it_it: 'Per funzionare correttamente, Nearby ha bisogno di utilizzare la tua posizione, non registreremo i tuoi spostamenti ne utilizzeremo i dati raccolti per fini diversi da quelli descritti nei termini e condizioni',
	},
	'description_2': {
		de_de: 'Ihre Echtzeit-Standortdaten verbleiben auf Ihrem Gerät, wir werden sie nicht aufzeichnen und wenn eine Aufzeichnung erforderlich ist, werden wir sie nicht länger als 24 Stunden speichern',
		en_us: 'Your real-time location data will remain on your device, we will not record it and if it is necessary to record it we will not store it for more than 24 hours',
		es_es: 'Tus datos de ubicación en tiempo real permanecerán en tu dispositivo, no los registraremos y si es necesario registrarlos no los almacenaremos por más de 24 horas',
		fr_fr: 'Vos données de localisation en temps réel resteront sur votre appareil, nous ne les enregistrerons pas et s\'il est nécessaire de les enregistrer nous ne les stockerons pas plus de 24 heures',
		it_it: 'I dati relativi alla tua posizione in tempo reale rimarranno nel tuo dispositivo, non li registreremo e nel caso fosse necessario registrarli non li immagazzineremo per più di 24 ore',
	},
	'more_info': {
		de_de: 'Lesen Sie, wie wir Ihre Daten verwenden',
		en_us: 'Read how we use your data',
		es_es: 'Lea cómo utilizamos sus datos',
		fr_fr: 'Découvrez comment nous utilisons vos données',
		it_it: 'Leggi come usiamo i tuoi dati',
	},
	'button___ask_for': {
		de_de: 'Zugriff anfordern',
		en_us: 'Request access',
		es_es: 'Solicitar acceso',
		fr_fr: 'Demander l\'accès',
		it_it: 'Richiedi l\'accesso',
	},
};