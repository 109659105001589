import { interface___is_logged } from "../../interfaces/interface.logged";
import { interface___modal, interface___modal___dismissible } from "../../interfaces/interface.modals";

//	P R O P S

export interface props___MdEntryInterestsModal extends interface___is_logged, interface___modal, interface___modal___dismissible {

}

//	S T A T E

export interface state___MdEntryInterestsModal {

	categories_events___collection: any[],
	categories_events___collection___is_loading: boolean,

	categories_poi___collection: any[],
	categories_poi___collection___is_loading: boolean,

	field___categories_selected___events_open: boolean,
	field___categories_selected___events: string[],
	field___categories_selected___poi: string[],
	field___categories_selected___is_loading: boolean,

}

//	S T A T E   D E F A U L T

export const state___MdEntryInterestsModal___default: state___MdEntryInterestsModal = {

	categories_events___collection: [],
	categories_events___collection___is_loading: true,

	categories_poi___collection: [],
	categories_poi___collection___is_loading: true,

	field___categories_selected___events_open: false,
	field___categories_selected___events: [],
	field___categories_selected___poi: [],
	field___categories_selected___is_loading: true,

}