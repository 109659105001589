import React from 'react';
import { Redirect, Route } from 'react-router-dom';

//	I N T E R F A C E S

import { interface___CustomRouteProps } from '../interfaces/interface.router';

//	E X P O R T   L O G G E D   R O U T E

export default class RouteProtectedUnlogged extends React.Component<interface___CustomRouteProps> {

//#region 																							R E N D E R

	render() {
		const { component: Component, isLogged, ...rest } = this.props;
		return <>
			<Route {...rest} render={(props) => isLogged ? ( <Redirect to="/404" /> ) : ( <Component {...props} /> )} />
		</>;
	}

//#endregion

}