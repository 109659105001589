//	S T A T E

export interface state___SbInfoStatsPage {

	stats_platform___props___is_loading: boolean,

	stats_platform___count_events: number | null, 
	stats_platform___count_poi: number | null, 
	stats_platform___count_poi_categories: number | null, 
	stats_platform___count_posts: number | null, 
	stats_platform___count_tales: number | null, 
	stats_platform___count_users: number | null, 

}

//	S T A T E   D E F A U L T

export const state___SbInfoStatsPage___default: state___SbInfoStatsPage = {

	stats_platform___props___is_loading: true,

	stats_platform___count_events: null, 
	stats_platform___count_poi: null, 
	stats_platform___count_poi_categories: null, 
	stats_platform___count_posts: null, 
	stats_platform___count_tales: null, 
	stats_platform___count_users: null, 

}