//	P R O P S

import { type___post___attachment_type } from "../../types/types.types"

export interface props___ComPostAttachmentComponent {

	post_props: any

}

//	S T A T E

export interface state___ComPostAttachmentComponent {

	post_attachment___props: any | null,
	post_attachment___props___is_loading: boolean,

}

//	S T A T E   D E F A U L T

export const state___ComPostAttachmentComponent___default: state___ComPostAttachmentComponent = {

	post_attachment___props: null,
	post_attachment___props___is_loading: true,

}