import React from 'react';
import { Area, Point } from 'react-easy-crop/types';
import Cropper from 'react-cropper';
import type CropperJS from 'cropperjs';

//	S T Y L E

import './md-media-editor.scss';
import 'cropperjs/dist/cropper.css';

//	S T A T E   -   P R O P S

import { props___MdMediaEditorModal, state___MdMediaEditorModal, state___MdMediaEditorModal___default } from './md-media-editor.state';

//	C O M P O N E N T S

import ComSpinnerComponent from '../../components/com-spinner/com-spinner';

//	C L A S S

export default class MdMediaEditorModal extends React.Component<props___MdMediaEditorModal, state___MdMediaEditorModal>
{

//#region 																							D E C L A R A T I O N S

	private cropper___component: React.RefObject<HTMLImageElement> = React.createRef<HTMLImageElement>();

/*
	zoom: number;
	rotation: number;
	aspect: number;
	maxZoom: number;
	minZoom: number;
	cropShape: "rect";
	objectFit: "contain";
	showGrid: boolean;
	style: {};
	classes: {};
	mediaProps: {};
	zoomSpeed: number;
	restrictPosition: boolean;
	zoomWithScroll: boolean;
*/

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdMediaEditorModal,
	) {
		super(props);
		this.state = state___MdMediaEditorModal___default;
	}

//#endregion

//#region 																							H A N D L E R S
	
	private handle___media_crop___confirm = () => {

		const ___cropper___imageElement: any = this.cropper___component.current;
		const ___cropper___cropper: CropperJS = ___cropper___imageElement?.cropper;

		if (___cropper___cropper) {
			___cropper___cropper.getCroppedCanvas({
				maxWidth: 2500 * this.props.input___aspect_ratio,
			//	maxHeight: 2500,
			}).toBlob((___blob: Blob) => {
				const ___reader: FileReader = new FileReader();
				___reader.onloadend = () => { this.handle___media_crop___finish(___reader.result as string); };
				if (___blob) { ___reader.readAsDataURL(___blob); }
			}, 'image/jpeg');
		}

	}

	private handle___media_crop___finish = async (___cropped_result: string) => {
	

		console.log('___cropped_result', ___cropped_result);

		const ___temp___image_cropped_collection: string[] = this.state.image_cropped___collection;
		___temp___image_cropped_collection[this.state.image_cropped___collection___index] = ___cropped_result;
		const ___temp___image_cropped_collection___index: number = this.state.image_cropped___collection___index + 1;
		this.setState({
			image_cropped___collection: ___temp___image_cropped_collection,
			image_cropped___collection___index: ___temp___image_cropped_collection___index,
		});

		if (this.state.image_cropped___collection___index >= (this.state.image_input___collection.length - 1))
		{
			console.log('dismissed')
			const ___gallery_result: string[] = this.state.image_cropped___collection;
			this.props.event___onDidDismiss(___gallery_result);
		}

	}

//	image_cropped___collection: string[],
//	image_cropped___collection___index: number,






	private cropper___onCropChange = (___crop: Point) => {
	//	this.setState({ cropper___crop: ___crop });
	};

	private cropper___onZoomChange = (___zoom: number) => {
	//	this.setState({ cropper___zoom: ___zoom });
	};

	private cropper___onCropComplete = (croppedArea: Area, ___croppedAreaPixels: Area) => {
	//	this.setState({ cropper___croppedAreaPixels: ___croppedAreaPixels });
	};

//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount() : void {
		this.setState({
			image_input___collection: [...this.props.input___image_list],
			image_cropped___collection___is_loading: false,
		});
	}

	componentDidUpdate(prevProps: props___MdMediaEditorModal, prevState: state___MdMediaEditorModal) : void {
		if (this.props !== prevProps) {
			this.setState({
				...state___MdMediaEditorModal___default
			}, () => {
				setTimeout(() => {
					this.setState({
						image_input___collection: [...this.props.input___image_list],
						image_cropped___collection___is_loading: false,
					});
				}, 10);
			});
		}
	}

	componentWillUnmount(): void {
		this.setState({
			...state___MdMediaEditorModal___default
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			{(this.props.isOpen && this.props.isOpen === true) ? <>
				<div className="md-media-editor---container">

					<div className="md-media-editor---indicator">
						{this.state.image_input___collection.map((_: any, ___i___idx: number) => {
							return <>
								{(___i___idx === this.state.image_cropped___collection___index) ? <>
									<i key={ ___i___idx } className="far fa-circle"></i>
								</> : <>
								{(___i___idx > this.state.image_cropped___collection___index) ? <>
										<i key={ ___i___idx } className="far fa-circle"></i>
									</> : <>
										<i key={ ___i___idx } className="far fa-check-circle"></i>
									</>}
								</>}
							</>;
						})}
					</div>

					<div className="md-media-editor---cropper">
						{(this.state.image_cropped___collection___is_loading === true) ? <>
							<br />
							<br />
							<br />
							<ComSpinnerComponent size="small"/>
						</> : <>
							<section>
								<Cropper 
									ref={ this.cropper___component }
									src={ this.state.image_input___collection[this.state.image_cropped___collection___index] }
									aspectRatio = { this.props.input___aspect_ratio }
									autoCropArea={ 1 }
									background={ false }
									crossOrigin={ 'anonymous' }
									center={ false }
									cropBoxMovable={ false }
									cropBoxResizable={ false }
									dragMode="move"
									guides={ false }
									highlight={ false }
									modal={ true }
									restore={ false }
									toggleDragModeOnDblclick={ false }
									viewMode={ 3 }
									style={{ height: 'auto', width: '100%' }}
								/>
							</section>
						</>}
					</div>
			
					<div className="md-media-editor---buttons">
						{/*
							<div onClick={() => { this.props.event___onDidDismiss(null); }}>
								<i className="fa-solid fa-times" style={{color:'var(--color---reddy)'}}></i>
							</div>
							<div onClick={ this.handle___media___undoChanges }>
								<i className="fas fa-undo-alt"></i>
							</div>
						*/}
						<div onClick={() => { this.handle___media_crop___confirm(); }}>
							<i className="fa-solid fa-check"></i>
						</div>
					</div>

				</div>
			</> : <></>}
		</>;
	}

//#endregion

}