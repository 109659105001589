import { RouteComponentProps } from "react-router";
import { interface___is_logged } from "../../../interfaces/interface.logged";

//	P R O P S

export interface props___SbPoiViewDigitalMenuPage extends RouteComponentProps<{ id: string; }>, interface___is_logged {

}

//	S T A T E

export interface state___SbPoiViewDigitalMenuPage {

	poi___id: string,
	poi___props: any | null,
	poi___props___is_loading: boolean, 

	poi_digitalmenu___props: any | null,
	poi_digitalmenu___props___is_loading: boolean,

	ionModal___MdDigitalMenuDishModal___isOpen: boolean,
	ionModal___MdDigitalMenuDishModal___target: string | null,

}

//	S T A T E   D E F A U L T

export const state___SbPoiViewDigitalMenuPage___default: state___SbPoiViewDigitalMenuPage = {

	poi___id: '',
	poi___props: null,
	poi___props___is_loading: true, 

	poi_digitalmenu___props: null,
	poi_digitalmenu___props___is_loading: true,

	ionModal___MdDigitalMenuDishModal___isOpen: false,
	ionModal___MdDigitalMenuDishModal___target: null,

}