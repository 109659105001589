import React from 'react'
import { Link } from 'react-router-dom';
import { IonCard } from '@ionic/react';

//	S T Y L E

import './com-discover-element.scss'

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';

//	S T A T E S   -   P R O P S

import { props___ComDiscoverElementComponent, state___ComDiscoverElementComponent, state___ComDiscoverElementComponent___default } from './com-discover-element.state';

//	S E R V I C E S 

import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComEventComponent from '../com-event/com-event';
import ComPoiComponent from '../com-poi/com-poi';
import ComSpinnerInlineComponent from '../com-spinner-inline/com-spinner-inline';

//	C L A S S

export default class ComDiscoverElementComponent extends React.Component<props___ComDiscoverElementComponent, state___ComDiscoverElementComponent>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComDiscoverElementComponent,
	)
	{
		super(props);
		this.state = state___ComDiscoverElementComponent___default;
	}

//#endregion

//#region 																							H A N D L E R S

	private read_props___logic = () => {
		switch (this.props.element___type) {
			case 'event': this.read_props___event(); break;
			case 'poi': this.read_props___poi(); break;
		}
	}

	private read_props___poi = async () => {
		const ___selected___poi___props: type___api___response = await this._API.poi___read___single(this.props.element___id);
		this.setState({
			element___props: ___selected___poi___props.data,
			element___props___is_loading: false,
		});
	}

	private read_props___event =  async () => {
		const ___selected___event___props: type___api___response = await this._API.events___read___single(this.props.element___id);
		this.setState({
			element___props: ___selected___event___props.data,
			element___props___is_loading: false,
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount(): void {
		this.setState({
			element___props___id: this.props.element___id,
			element___props___type: this.props.element___type,
			element___props: (this.props.element___props !== null) ? this.props.element___props : null,
			element___props___is_loading: (this.props.element___props !== null) ? false : true,
		}, () => {
			this.read_props___logic();
		});
	}

	componentDidUpdate(prevProps: props___ComDiscoverElementComponent, prevState: state___ComDiscoverElementComponent) : void {
		if (this.props !== prevProps) {
			this.setState({
				element___props___id: this.props.element___id,
				element___props___type:  this.props.element___type,
				element___props: (this.props.element___props !== null) ? this.props.element___props : null,
				element___props___is_loading: (this.props.element___props !== null) ? false : true,
			}, () => {
				this.read_props___logic();
			});
		}
	}

	componentWillUnmount(): void {
		this.setState({
			...state___ComDiscoverElementComponent___default
		});
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
				<div className="com-discover-element---container">
					{(this.state.element___props___is_loading === true || this.state.element___props === null) ? <>
						<IonCard>
							<ComSpinnerInlineComponent/>
						</IonCard>
					</> : <>
						{(() => { switch (this.state.element___props___type) {
							case 'event': return <>
								<Link to={ '/view/events/' + this.state.element___props___id }>
									<ComEventComponent event_props={ this.state.element___props } size="normal" />
								</Link>
							</>; break;
							case 'poi': return <>
								<Link to={ '/view/poi/' + this.state.element___props___id }>
									<ComPoiComponent poi_props={ this.state.element___props } size="normal"/>
								</Link>
							</>; break;
							default: return <>
								<IonCard>
									<ComSpinnerInlineComponent/>
								</IonCard>
							</>; break;
						}})()}
					</>}
				</div>
		</>;
	}

//#endregion

}