import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___MdDiscoverSearchPreviewModal: type___locale___translations___collection = {
	'no_results': {
		de_de: 'kein ergebnis',
		en_us: 'no results',
		es_es: 'sin resultados',
		fr_fr: 'pas de résultat',
		it_it: 'nessun risultato',
	},
	'load_more': {
		de_de: 'Mehr laden...',
		en_us: 'Load more...',
		es_es: 'Carga más...',
		fr_fr: 'Charger plus...',
		it_it: 'Carica altro...',
	},
};