import React from 'react';
import { IonBackButton, IonBadge, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonNote, IonPage, IonRefresher, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './sb-events-view-settings.scss';

//	S T A T E   -   P R O P S

import { props___SbEventViewSettingsPage, state___SbEventViewSettingsPage, state___SbEventViewSettingsPage___default } from './sb-events-view-settings.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	L I B S

import { lib_names_shared } from '../../../libs/lib.names.shared';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';
import { service_Router } from '../../../services/service-router';

//	C O M P O N E N T S

import Com404Component from '../../../components/com-404/com-404';
import ComEventComponent from '../../../components/com-event/com-event';
import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';
import ComSpinnerInlineComponent from '../../../components/com-spinner-inline/com-spinner-inline';

//	C L A S S

class SbEventViewSettingsPage extends React.Component<props___SbEventViewSettingsPage, state___SbEventViewSettingsPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _ROUTER: service_Router = new service_Router();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbEventViewSettingsPage,
	) {
		super(props);
		this.state = state___SbEventViewSettingsPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___poi = async () => {
		const ___temp___event___props: type___api___response = await this._API.events___read___single(this.state.event___id);
		if (!___temp___event___props || ___temp___event___props.response !== 'success' || ___temp___event___props.data === null) { console.log('404'); }
		this.setState({
			event___props: ___temp___event___props.data,
			event___props___is_loading: false,
		});
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___page___refresh = (___e: CustomEvent<RefresherEventDetail>) => {
		this.setState({
			event___props: null,
			event___props___is_loading: true,
		}, async () => {
			await this.read_props___poi();
			___e.detail.complete();
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		const ___event_id: string | null = this._ROUTER.get_id(this.props);
		if (___event_id) {
			this.setState({
				event___id: ___event_id,
			}, async () => {
				await this.read_props___poi();
			});
		}
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref={ '/view/events/' + this.state.event___id }></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>
					{(this.state.event___props___is_loading === true) ? <>

						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<ComSpinnerComponent size="small"/>

					</> : <>

						<IonRefresher slot="fixed" onIonRefresh={ this.handle___page___refresh }>
							<ComSpinnerInlineComponent/>
						</IonRefresher>

						{(this.state.event___props !== null) ? <>
							<div className="sb-page---container sb-events-view-settings---container">

								<ComEventComponent event_props={ this.state.event___props } size="large"/>

								<br />

								{(!((this.state.event___props['event_ended'] && this.state.event___props['event_ended'] === true) || (this.state.event___props['event_is_now'] && this.state.event___props['event_is_now'] === true))) ? <>

									<IonCard>
										<IonList>
											<IonItem className="is-ion-item-button">
												<i className="fas fa-key"></i>
												<IonLabel>Codice Autenticazione NoPR</IonLabel>
												<IonNote slot="end" color="dark">
													<b>{ this.state.event___props['event_id_nopr_auth'].toString().toUpperCase() }</b>
												</IonNote>
											</IonItem>
											{(this.state.event___props['event_id_nopr_organization'] && this.state.event___props['event_id_nopr_organization'] !== null) ? <>
												<IonList>
													<IonItem className="is-ion-item-button" button={ true } target="_blank" routerLink={ lib_names_shared.endpoints.external_nopr + 'events/view/' + this.state.event___props['event_id'] } routerDirection="forward">
														<img src="/assets/libraries/nopr.icon.svg" />
														<IonLabel>Gestisci in NoPR</IonLabel>
														<IonBadge slot="end" color="nopr">
															<span>Apri NoPR</span>
															<i className="fas fa-external-link-alt" style={{transform:'scale(0.85)',paddingLeft:'5pt'}}></i>
														</IonBadge>
													</IonItem>
												</IonList>
											</> : <>
												<IonList>
													<IonItem className="is-ion-item-button" button={ true } target="_blank" routerLink={ lib_names_shared.endpoints.external_nopr + 'events/import/from/nearby/' + this.state.event___props['event_id'] } routerDirection="forward">
														<img src="/assets/libraries/nopr.icon.svg" />
														<IonLabel>Configura in NoPR</IonLabel>
														<IonBadge slot="end" color="nopr">
															<span>Apri NoPR</span>
															<i className="fas fa-external-link-alt" style={{transform:'scale(0.85)',paddingLeft:'5pt'}}></i>
														</IonBadge>
													</IonItem>
												</IonList>
											</>}
										</IonList>
									</IonCard>

									<br />

									<IonCard>
										<IonList>
											<IonItem className="is-ion-item-button" button={ true } routerLink={ '/view/events/' + this.state.event___props['event_id'] + '/notifications' } routerDirection="forward">
												<i className="fas fa-bell"></i>
												<IonLabel>Gestione Notifiche</IonLabel>
											</IonItem>
										</IonList>
									</IonCard>
							
									<br />
								
									<IonCard>
										<IonList>
											<IonItem className="is-ion-item-button" button={ true } routerLink={ '/edit/events/' + this.state.event___props['event_id'] } routerDirection="forward">
												<i className="fas fa-edit"></i>
												<IonLabel>Modifica Evento</IonLabel>
											</IonItem>
											{/**
												<IonItem className="is-ion-item-button" button={ true } routerLink={ '/edit/events/' + this.state.event___props['event_id'] } routerDirection="forward">
													<i className="fas fa-people-arrows"></i>
													<IonLabel>Trasferisci Proprietà</IonLabel>
												</IonItem>
											*/}
										</IonList>
									</IonCard>
									
									<br />

									<IonCard color="danger">
										<IonList color="danger">
											<IonItem color="danger" className="is-ion-item-button" button={ true }>
												<i className="fas fa-trash-alt"></i>
												<IonLabel>Elimina Permanentemente</IonLabel>
											</IonItem>
										</IonList>
									</IonCard>
								
								</> : <></>}

							</div>
						</> : <>
							<Com404Component/>
						</>}

					</>}
				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbEventViewSettingsPage);