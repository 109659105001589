import { type___locale___translations___collection } from "../../../types/types.locale";

//	E X P O R T   L O C A L E

export const locale___SbSettingsMainPage: type___locale___translations___collection = {
	'account': {
		de_de: 'Konten',
		en_us: 'Account',
		es_es: 'Cuentas',
		fr_fr: 'Comptes',
		it_it: 'Account',
	},
	'account___profile': {
		de_de: 'Profil',
		en_us: 'Profile',
		es_es: 'Perfil',
		fr_fr: 'Profil',
		it_it: 'Profilo',
	},
	'account___account': {
		de_de: 'Konto und Zugang',
		en_us: 'Account and Access',
		es_es: 'Cuenta y acceso',
		fr_fr: 'Compte et accès',
		it_it: 'Account e Accesso',
	},
	'account___auth': {
		de_de: 'Authentifizierung',
		en_us: 'Authentication',
		es_es: 'Autenticación',
		fr_fr: 'Authentification',
		it_it: 'Autenticazione',
	},
	'account___privacy': {
		de_de: 'Privatsphäre',
		en_us: 'Privacy',
		es_es: 'Privacidad',
		fr_fr: 'Confidentialité',
		it_it: 'Privacy',
	},
	'account___logout': {
		de_de: 'Abmelden',
		en_us: 'Logout',
		es_es: 'Cerrar sesión',
		fr_fr: 'Déconnexion',
		it_it: 'Logout',
	},
	'preferences': {
		de_de: 'Präferenzen',
		en_us: 'Preferences',
		es_es: 'Preferencias',
		fr_fr: 'Préférences',
		it_it: 'Preferenze',
	},
	'preferences___interests': {
		de_de: 'Deine Interessen',
		en_us: 'Your interests',
		es_es: 'Tus intereses',
		fr_fr: 'Vos intérêts',
		it_it: 'I tuoi Interessi',
	},
	'preferences___language': {
		de_de: 'Sprache',
		en_us: 'Language',
		es_es: 'Lengua',
		fr_fr: 'Langue',
		it_it: 'Lingua',
	},
	'support': {
		de_de: 'Hilfe und Support',
		en_us: 'Assistance and Support',
		es_es: 'Asistencia y Soporte',
		fr_fr: 'Assistance et soutien',
		it_it: 'Assistenza e Supporto',
	},
	'support___suggest_a_feature': {
		de_de: 'Schlagen Sie eine Funktion vor',
		en_us: 'Suggest a feature',
		es_es: 'Sugerir una característica',
		fr_fr: 'Suggérer une fonctionnalité',
		it_it: 'Suggerisci una funzione',
	},
	'support___contact_us': {
		de_de: 'Kontakte und Hilfe',
		en_us: 'Contact Us and Support',
		es_es: 'Contactos y Asistencia',
		fr_fr: 'Contacts et Assistance',
		it_it: 'Contatti e Assistenza',
	},
	'support___faq': {
		de_de: 'Häufige Fragen',
		en_us: 'Frequently Asked Question',
		es_es: 'Preguntas frecuentes',
		fr_fr: 'Questions fréquentes',
		it_it: 'Domande frequenti',
	},
	'support___clear_cache': {
		de_de: 'Cache und Cookies löschen',
		en_us: 'Clear Cache and Cookies',
		es_es: 'Borrar caché y cookies',
		fr_fr: 'Vider le cache et les cookies',
		it_it: 'Cancella la cache ed i cookies',
	},
	'about': {
		de_de: 'Info',
		en_us: 'About',
		es_es: 'Información',
		fr_fr: 'Info',
		it_it: 'Info',
	},
	'about___stats': {
		de_de: 'Plattformstatistiken',
		en_us: 'Platform stats',
		es_es: 'Estadísticas de la plataforma',
		fr_fr: 'Statistiques de la plateforme',
		it_it: 'Statistiche piattaforma',
	},
	'about___eula': {
		de_de: 'EULA und Cookie-Richtlinie',
		en_us: 'EULA and Cookie Policy',
		es_es: 'CLUF y política de cookies',
		fr_fr: 'CLUF et politique en matière de cookies',
		it_it: 'EULA e Cookie Policy',
	},
	'about___partners': {
		de_de: 'Partner',
		en_us: 'Partners',
		es_es: 'Fogonadura',
		fr_fr: 'Partenaires',
		it_it: 'Partners',
	},
	'about___thanks': {
		de_de: 'Danke',
		en_us: 'Thanks to',
		es_es: 'Gracias',
		fr_fr: 'Merci',
		it_it: 'Ringraziamenti',
	},
	'about___version': {
		de_de: 'Anwendung',
		en_us: 'Application',
		es_es: 'Aplicación',
		fr_fr: 'Application',
		it_it: 'Applicazione',
	},
	'about___licences': {
		de_de: 'Lizenzen Dritter',
		en_us: 'Third parties Licences',
		es_es: 'Licencias de terceros',
		fr_fr: 'Licences tierces',
		it_it: 'Licenze di terze parti',
	},
	'about___copyright': {
		de_de: 'Copyright',
		en_us: 'Copyright',
		es_es: 'Copyright',
		fr_fr: 'Copyright',
		it_it: 'Copyright',
	},
};