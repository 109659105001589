import { interface___modal } from "../../interfaces/interface.modals";

//	P R O P S

export interface props___MdMediaEditorModal extends interface___modal {

	input___aspect_ratio: number,
	input___image_list: string[],

}

//	S T A T E

export interface state___MdMediaEditorModal {

	image_input___collection: string[],

	image_cropped___collection: string[],
	image_cropped___collection___index: number,
	image_cropped___collection___is_loading: boolean,

}

//	S T A T E   D E F A U L T

export const state___MdMediaEditorModal___default: state___MdMediaEditorModal = {

	image_input___collection: [],

	image_cropped___collection: [],
	image_cropped___collection___index: 0,
	image_cropped___collection___is_loading: true,

}