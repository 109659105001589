import { RouteComponentProps } from "react-router";
import { interface___is_logged } from "../../../interfaces/interface.logged";

//	P R O P S

export interface props___SbPoiViewSettingsPage extends RouteComponentProps<{ id: string; }>, interface___is_logged {

}

//	S T A T E

export interface state___SbPoiViewSettingsPage {

	poi___id: string,
	poi___props: any | null,
	poi___props___is_loading: boolean, 

	service_created___digitalmenu___is_loading: boolean,

	ionToast___service_created___digitalmenu___isVisible: boolean,

}

//	S T A T E   D E F A U L T

export const state___SbPoiViewSettingsPage___default: state___SbPoiViewSettingsPage = {

	poi___id: '',
	poi___props: null,
	poi___props___is_loading: true,

	service_created___digitalmenu___is_loading: false,

	ionToast___service_created___digitalmenu___isVisible: false,

}