import { type___locale___translations___collection } from "../../types/types.locale";

//	E X P O R T   L O C A L E

export const locale___MdSignPasswordChangeModal: type___locale___translations___collection = {
	'ionToast___password_changed': {
		de_de: '________________________',
		en_us: '________________________',
		es_es: '________________________',
		fr_fr: '________________________',
		it_it: 'La tua password è stata cambiata con successo',
	},

	'ionToast___password_old_invalid': {
		de_de: '________________________',
		en_us: '________________________',
		es_es: '________________________',
		fr_fr: '________________________',
		it_it: 'Le password non combaciano...',
	},

	'ionToast___password_error_generic': {
		de_de: '________________________',
		en_us: '________________________',
		es_es: '________________________',
		fr_fr: '________________________',
		it_it: 'Errore nel cambiamento della password',
	},
	'title': {
		de_de: '________________________',
		en_us: '________________________',
		es_es: '________________________',
		fr_fr: '________________________',
		it_it: 'Reset della password',
	},
	'description': {
		de_de: '________________________',
		en_us: '________________________',
		es_es: '________________________',
		fr_fr: '________________________',
		it_it: 'Al momento hai una password temporanea, per continuare ad utilizzare Nearby devi necessariamente creare una password sicura',
	},












	'________________________': {
		de_de: '________________________',
		en_us: '________________________',
		es_es: '________________________',
		fr_fr: '________________________',
		it_it: '________________________',
	},




};