import { interface___modal } from "../../interfaces/interface.modals";
import { type___digitalmenu___currency, type___digitalmenu___dish_measurement_unit } from "../../types/types.digitalmenu";

//	P R O P S

export interface props___MdDigitalMenuDishEditModal extends interface___modal {

	dish_id: string | null,
	dish_category: string | null,
	dish_digitalmenu: string,

}

//	S T A T E

export interface state___MdDigitalMenuDishEditModal {

	allergens_collections: any[],
	allergens_collections___is_loading: boolean,

	categories_collections: any[],
	categories_collections___is_loading: boolean,

	dish___id: any | null,
	dish___props: any | null,
	dish___props___is_loading: boolean,
	dish___props___is_submitting: boolean,

	field___dish___category: string,
	field___dish___name: string,
	field___dish___description: string,
	field___dish___price: number,
	field___dish___price_currency: type___digitalmenu___currency,

	field___dish___measurement_unit: type___digitalmenu___dish_measurement_unit,

	field___dish___allergens: string[],

	ionToast___dishSubmit___isSubmitted: boolean | null,

}

//	S T A T E   D E F A U L T

export const state___MdDigitalMenuDishEditModal___default: state___MdDigitalMenuDishEditModal = {

	allergens_collections: [],
	allergens_collections___is_loading: true,

	categories_collections: [],
	categories_collections___is_loading: true,

	dish___id: null,
	dish___props: null,
	dish___props___is_loading: true,
	dish___props___is_submitting: false,

	field___dish___category: null,

	field___dish___name: '',
	field___dish___description: '',
	field___dish___price: 0,
	field___dish___price_currency: 'eur',

	field___dish___measurement_unit: null,

	field___dish___allergens: [],

	ionToast___dishSubmit___isSubmitted: null,

}