import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonRefresher, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';
import { Link } from 'react-router-dom';

//	S T Y L E

import './sb-chats-list.scss';

//	S T A T E   -   P R O P S

import { props___SbChatsListPage, state___SbChatsListPage, state___SbChatsListPage___default } from './sb-chats-list.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';

//	C O M P O N E N T S

import ComListEmptyComponent from '../../../components/com-list-empty/com-list-empty';
import ComListSeparatorComponent from '../../../components/com-list-separator/com-list-separator';
import ComNotificationComponent from '../../../components/com-notification/com-notification';
import ComSpinnerInlineComponent from '../../../components/com-spinner-inline/com-spinner-inline';
import ComInfiniteScrollerComponent from '../../../components/com-infinite-scroller/com-infinite-scroller';
import ComChatConversationComponent from '../../../components/com-chat-conversation/com-chat-conversation';

//	C L A S S

class SbChatsListPage extends React.Component<props___SbChatsListPage, state___SbChatsListPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbChatsListPage,
	) {
		super(props);
		this.state = state___SbChatsListPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___chat_conversations = async () => {
		const ___temp___chat_conversations: type___api___response = await this._API.chats___read___multi('all', this.state.chat_conversations___collection.length);
		this.setState((prevState) => ({
			chat_conversations___collection: [...prevState.chat_conversations___collection, ...___temp___chat_conversations.data],
			chat_conversations___collection___is_loading: false,
			chat_conversations___collection___is_complete: (___temp___chat_conversations.response === 'no-data') ? true : false,
		}));
	}










//#endregion

//#region 																							H A N D L E R S

	private handle___page___refresh = (___e: CustomEvent<RefresherEventDetail>) => {
		this.setState({
			chat_conversations___collection: [],
			chat_conversations___collection___is_loading: true,
			chat_conversations___collection___is_complete: false,
		}, async () => {
			await this.read_props___chat_conversations();
			___e.detail.complete();
		});
	}

	private handle___page___load_more = () => {
		this.setState({
			chat_conversations___collection___is_loading: true
		}, async () => {
			await this.read_props___chat_conversations();
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	async ionViewDidEnter() : Promise<void> {
		await this.read_props___chat_conversations();
	}

	ionViewDidLeave() : void {
		this.setState({
			...state___SbChatsListPage___default
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/archive" />
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					<IonRefresher slot="fixed" onIonRefresh={ this.handle___page___refresh }>
						<ComSpinnerInlineComponent/>
					</IonRefresher>

					<IonListHeader>
						<IonLabel>
							<i className="fas fa-envelope"></i>
							<span>Conversazioni</span>
						</IonLabel>
					</IonListHeader>

					<section className="sb-chat-list---container">
						<br />
						{this.state.chat_conversations___collection.map((___c: any, ___c___idx: number) => {
							return <>
								<Link key={ ___c___idx } to={ '/chats/with/' + ___c['chat_with']['user_username'] }>
									<ComChatConversationComponent chat_props={ ___c } />
								</Link>
							</>;
						})}
					</section>

					{(this.state.chat_conversations___collection___is_complete === true) ? <>
						<ComListEmptyComponent list_items_quantity={ this.state.chat_conversations___collection.length }/>
					</> : <>
						<ComInfiniteScrollerComponent is_loading={ this.state.chat_conversations___collection___is_loading } event___scrollTriggered={ this.handle___page___load_more } />
					</>}

				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbChatsListPage);