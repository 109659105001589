import React from 'react'

//	S T Y L E

import './com-tale.scss'

//	S T A T E S   -   P R O P S

import { props___ComTaleComponent, state___ComTaleComponent, state___ComTaleComponent___default } from './com-tale.state';

//	F U N C S

import { funcs_datetime } from '../../funcs/funcs.datetime';

//	C L A S S

export default class ComTaleComponent extends React.Component<props___ComTaleComponent, state___ComTaleComponent>
{

//#region 																							D E C L A R A T I O N S

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComTaleComponent,
	) {
		super(props);
		this.state = state___ComTaleComponent___default;
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="com-tale-component---container" onClick={ this.props.event___onClick }>
				<img src={ this.props.tale___props['tale_media'] } />
				<span>{ this.funcs___datetime.datetime___get___age(this.props.tale___props['tale_datetime'], true) }</span>
			</div>
		</>;
	}

//#endregion

}