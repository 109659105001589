import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___Com404Component: type___locale___translations___collection = {
	'title': {
		de_de: 'Uuuups.....',
		en_us: 'Wooops.....',
		es_es: 'Uuuups.....',
		fr_fr: 'Oouups.....',
		it_it: 'Oooops.....',
	},
	'message': {
		de_de: 'Wir haben nicht gefunden, was Sie gesucht haben...',
		en_us: 'we didn\'t find what you were looking for...',
		es_es: 'no encontramos lo que buscabas...',
		fr_fr: 'nous n\'avons pas trouvé ce que vous cherchiez...',
		it_it: 'non abbiamo trovato ciò che stavi cercando...',
	},
	'footer': {
		de_de: 'es ist wahrscheinlich, dass dieser Inhalt gelöscht wurde oder der Link, dem Sie gefolgt sind, nicht mehr gültig ist...',
		en_us: 'it is likely that this content has been deleted or that the link you followed is no longer valid...',
		es_es: 'es probable que este contenido haya sido eliminado o que el enlace que seguiste ya no sea válido...',
		fr_fr: 'il est probable que ce contenu ait été supprimé ou que le lien que vous avez suivi ne soit plus valide...',
		it_it: 'è probabile che questo contenuto sia stato eliminato o che il link che hai seguito non sia più valido...',
	},
};