import { type___locale___translations___collection } from "../../../types/types.locale";

//	E X P O R T   L O C A L E

export const locale___SbPoiCategoryViewPage: type___locale___translations___collection = {
	'category_is_loading': {
		de_de: '...',
		en_us: '...',
		es_es: '...',
		fr_fr: '...',
		it_it: '...',
	},
};