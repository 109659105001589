import { type___locale___translations___collection } from "../../../types/types.locale";

//	E X P O R T   L O C A L E

export const locale___SbInfoPresentationPage: type___locale___translations___collection = {
	'go_to_login': {
		de_de: 'allez sur Connexion',
		en_us: 'go to Login',
		es_es: 'ir a iniciar sesión',
		fr_fr: 'Gehen Sie zu Anmelden',
		it_it: 'vai al Login',
	},
	'motto_title': {
		de_de: 'entdecken Sie die Welt um Sie herum',
		en_us: 'discover the world around you',
		es_es: 'descubre el mundo que te rodea',
		fr_fr: 'découvrez le monde qui vous entoure',
		it_it: 'scopri il mondo intorno a te',
	},
	'motto_description': {
		de_de: 'nearby ist die einzige Plattform, mit der Sie Orte erkunden und mehr über die Aktivitäten der Welt um Sie herum erfahren können',
		en_us: 'nearby is the only platform that lets you explore places and learn about the activities of the world around you',
		es_es: 'nearby es la única plataforma que te permite explorar lugares y aprender sobre las actividades del mundo que te rodea',
		fr_fr: 'nearby est la seule plateforme qui vous permet d\'explorer des lieux et de découvrir les activités du monde qui vous entoure',
		it_it: 'nearby è l\'unica piattaforma che ti fa esplorare i luoghi e conoscere le attività del mondo che ti circonda',
	},
	'preferences___interests': {
		de_de: 'Deine Interessen',
		en_us: 'Your interests',
		es_es: 'Tus intereses',
		fr_fr: 'Vos intérêts',
		it_it: 'I tuoi Interessi',
	},
	'preferences___language': {
		de_de: 'Sprache',
		en_us: 'Language',
		es_es: 'Lengua',
		fr_fr: 'Langue',
		it_it: 'Lingua',
	},
	'support___contact_us': {
		de_de: 'Kontakte und Hilfe',
		en_us: 'Contact Us and Support',
		es_es: 'Contactos y Asistencia',
		fr_fr: 'Contacts et Assistance',
		it_it: 'Contatti e Assistenza',
	},
	'support___faq': {
		de_de: 'Häufige Fragen',
		en_us: 'Frequently Asked Question',
		es_es: 'Preguntas frecuentes',
		fr_fr: 'Questions fréquentes',
		it_it: 'Domande frequenti',
	},
	'support___clear_cache': {
		de_de: 'Cache und Cookies löschen',
		en_us: 'Clear Cache and Cookies',
		es_es: 'Borrar caché y cookies',
		fr_fr: 'Vider le cache et les cookies',
		it_it: 'Cancella la cache ed i cookies',
	},
	'about___stats': {
		de_de: 'Plattformstatistiken',
		en_us: 'Platform stats',
		es_es: 'Estadísticas de la plataforma',
		fr_fr: 'Statistiques de la plateforme',
		it_it: 'Statistiche piattaforma',
	},
	'about___eula': {
		de_de: 'EULA und Cookie-Richtlinie',
		en_us: 'EULA and Cookie Policy',
		es_es: 'CLUF y política de cookies',
		fr_fr: 'CLUF et politique en matière de cookies',
		it_it: 'EULA e Cookie Policy',
	},
	'about___partners': {
		de_de: 'Partner',
		en_us: 'Partners',
		es_es: 'Fogonadura',
		fr_fr: 'Partenaires',
		it_it: 'Partners',
	},
	'about___thanks': {
		de_de: 'Danke',
		en_us: 'Thanks to',
		es_es: 'Gracias',
		fr_fr: 'Merci',
		it_it: 'Ringraziamenti',
	},
	'about___version': {
		de_de: 'Anwendung',
		en_us: 'Application',
		es_es: 'Aplicación',
		fr_fr: 'Application',
		it_it: 'Applicazione',
	},
	'about___licences': {
		de_de: 'Lizenzen Dritter',
		en_us: 'Third parties Licences',
		es_es: 'Licencias de terceros',
		fr_fr: 'Licences tierces',
		it_it: 'Licenze di terze parti',
	},
	'about___copyright': {
		de_de: 'Copyright',
		en_us: 'Copyright',
		es_es: 'Copyright',
		fr_fr: 'Copyright',
		it_it: 'Copyright',
	},
};