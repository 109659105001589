import { lib_notifications_shared } from "../../libs/lib.notifications.shared"

//	P R O P S

export interface props___ComNotificationComponent {

	notification___props: any

}

//	S T A T E

export interface state___ComNotificationComponent {

	notification___route: string,

}

//	S T A T E   D E F A U L T

export const state___ComNotificationComponent___default: state___ComNotificationComponent = {

	notification___route: lib_notifications_shared.generic.route,

}