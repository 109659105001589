import React from 'react';
import { IonContent, IonFooter, IonLabel, IonListHeader, IonModal, IonToast } from '@ionic/react';

//	L O C A L I Z A T I O N

import { locale___MdSignPasswordChangeModal } from './md-sign-password-change.locale';

//	S T Y L E

import './md-sign-password-change.scss';

//	S T A T E S   -   P R O P S

import { props___MdSignPasswordChangeModal, state___MdSignPasswordChangeModal, state___MdSignPasswordChangeModal___default } from './md-sign-password-change.state';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';
import { type___modal___config } from '../../types/types.modals';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';
import { service_LocalizationService } from '../../services/service-localization';
import ComPasswordSafetyComponent from '../../components/com-password-safety/com-password-safety';

//	C L A S S

export default class MdSignPasswordChangeModal extends React.Component<props___MdSignPasswordChangeModal, state___MdSignPasswordChangeModal>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							C O N F I G

	private readonly MdSignPasswordChangeModal___modal_config: type___modal___config = {
		backdropDismiss: false,
		handle: false,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent(); },
		onDidDismiss: () => { this.event___onDidDismiss(); }
	};

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdSignPasswordChangeModal,
	)
	{
		super(props);
		this.state = state___MdSignPasswordChangeModal___default;
	}

//#endregion

//#region 																							S U B M I T

	private submit___password_change = () => {
		if (this.state.field___password === this.state.field___password___repeat)
		{
			this.setState({
				password_change___is_loading: true,
			}, async () => {
				const ___password___is_resetted: type___api___response = await this._API.auth___perform___password_change(null, this.state.field___password);
				if (___password___is_resetted && ___password___is_resetted.response === 'success')
				{
					this.setState({
						toast___password_change_success___visible: true,
						password_change___is_loading: false,
					}, () => {
						this.props.event___onDidDismiss();
					});
				}
				else
				{
					this.setState({
						toast___password_change_error___visible: true,
						password_change___is_loading: false,
					});
				}
			});
		}
		else
		{
			this.setState({
				toast___password_change_unmatch___visible: true,
			});
		}
	}

//#endregion

//#region 																							L I F E C Y C L E

	private event___onDidPresent() : void
	{
		this.setState({
			password_change___is_loading: false,
		});
	}

	private event___onDidDismiss() : void
	{
		this.setState({
			...state___MdSignPasswordChangeModal___default
		}, () => {
			this.props.event___onDidDismiss();
		});
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonModal isOpen={ this.props.isOpen } { ...this.MdSignPasswordChangeModal___modal_config }>

				<IonToast isOpen={ this.state.toast___password_change_success___visible } message={ this._LOCALE.translate(locale___MdSignPasswordChangeModal.ionToast___password_changed) } duration={ 3000 } color="success" position="top" onDidDismiss={() => { this.setState({ toast___password_change_success___visible: false }); }}/>
				<IonToast isOpen={ this.state.toast___password_change_unmatch___visible } message={ this._LOCALE.translate(locale___MdSignPasswordChangeModal.ionToast___password_old_invalid) } duration={ 3000 } color="danger" position="top" onDidDismiss={() => { this.setState({ toast___password_change_unmatch___visible: false }); }}/>
				<IonToast isOpen={ this.state.toast___password_change_error___visible } message={ this._LOCALE.translate(locale___MdSignPasswordChangeModal.ionToast___password_error_generic) } duration={ 3000 } color="danger" position="top" onDidDismiss={() => { this.setState({ toast___password_change_error___visible: false }); }}/>

				<IonContent>
					<section className="md-sign-password-change---container">
						<br />
						<br />
						<img src="/assets/logo.horizontal.svg"></img>
						<br />
						<br />
						<IonListHeader style={{textAlign:'center'}}>
							<IonLabel>{ this._LOCALE.translate(locale___MdSignPasswordChangeModal.title) }</IonLabel>
						</IonListHeader>
						<br />
						<p>{ this._LOCALE.translate(locale___MdSignPasswordChangeModal.description) }</p>
						<br />
						<input placeholder="nuova password" type="text" className="input---nb-outlined" style={{textAlign:'center'}} value={ this.state.field___password } onInput={(___e: any) => this.setState({ field___password: ___e.target.value })}/>
						<br />
						<input placeholder="ripeti nuova password" type="text" className="input---nb-outlined" style={{textAlign:'center'}} value={ this.state.field___password___repeat } onInput={(___e: any) => this.setState({ field___password___repeat: ___e.target.value })}/>
						<ComPasswordSafetyComponent password={ this.state.field___password } event___onProcess={(___e: boolean) => { this.setState({ field___password___is_safe: ___e }); }}/>
					</section>
				</IonContent>

				<IonFooter>
					<section className="container---footer-section">
						<button className="is-element is-button is-color---folly is-full-width" onClick={ this.submit___password_change }>
							<i className="fas fa-lock"></i>
							<h4>Salva Nuova Password</h4>
						</button>
					</section>
				</IonFooter>

			</IonModal>
		</>;
	}

//#endregion

}