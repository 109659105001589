import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonFooter, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonToast, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './sb-settings-account.scss';

//	S T A T E   -   P R O P S

import { state___SbSettingsAccountPage, state___SbSettingsAccountPage___default } from './sb-settings-account.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';
import { type___user_setting___property } from '../../../types/types.types';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';

//	C O M P O N E N T S

import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';

//	C L A S S

class SbSettingsAccountPage extends React.Component<{}, state___SbSettingsAccountPage>
{

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: {},
	) {
		super(props);
		this.state = state___SbSettingsAccountPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___user = async () => {
		const ___temp___user___props: type___api___response = await this._API.users___read___single('me', null);
		if (!___temp___user___props || ___temp___user___props.response !== 'success' || ___temp___user___props.data === null) { console.log('404'); }
		this.setState({
			user___props: ___temp___user___props.data,
			user___props___is_loading: false,
		}, () => {
			this.setState({
				field___user___contact_email: this.state.user___props['user_contact_mail'],
				field___user___contact_phone: this.state.user___props['user_contact_phone'],
				user___props___is_loading: false,
			});
		});
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___user_contact___update = async () => {

		const ___user_values__to_update: type___user_setting___property[] = [
			{ key: 'user_contact_phone', value: this.state.field___user___contact_phone },
			{ key: 'user_contact_email', value: this.state.field___user___contact_email },
		];
		
		const ___user___is_submitted: type___api___response = await this._API.users_settings___update(___user_values__to_update);
		this.setState({
			ionToast___user_is_submitted___result: (___user___is_submitted && ___user___is_submitted.response === 'success') ? 'success' : 'error',
			user___props___is_loading: true,
		}, async () => {
			await this.read_props___user();
		});

	}

//#endregion

//#region 																							S U B M I T

	async ionViewWillEnter() : Promise<void>
	{
		await this.read_props___user();
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{

		return <>
			<IonPage>

				<IonToast isOpen={ this.state.ionToast___user_is_submitted___result === 'success' } message="Il salvataggio è andato a buon fine" duration={ 3000 } color='success' position='top' onDidDismiss={() => { this.setState({ ionToast___user_is_submitted___result: false }); }}/>
				<IonToast isOpen={ this.state.ionToast___user_is_submitted___result === 'error' } message="Errore nel salvataggio " duration={ 3000 } color='danger' position='top' onDidDismiss={() => { this.setState({ ionToast___user_is_submitted___result: false }); }}/>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/users/list"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					{(this.state.user___props___is_loading === true) ? <>
						
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />

						<ComSpinnerComponent size="small"/>

					</> : <>

						<IonListHeader>
							<IonLabel>Modifica dati di accesso</IonLabel>
						</IonListHeader>

						<br />

						<div className="sb-page---container sb-settings-account---container">

							<label htmlFor="field---user-name">
								<p className="input---nb-label is-required">contatti</p>
								<input type="text" id="field---user-email" className="input---nb-outlined" placeholder="email@email.com" value={ this.state.field___user___contact_email } onInput={(___e: any) => { this.setState({ field___user___contact_email: ___e.target.value}); }}/>
							</label>
							<label htmlFor="field---user-surname" style={{display:'block',paddingTop:'10pt'}}>
								<input type="text" id="field---user-phone" className="input---nb-outlined" placeholder="+39 333 333 3334" value={ this.state.field___user___contact_phone } onInput={(___e: any) => { this.setState({ field___user___contact_phone: ___e.target.value}); }}/>
							</label>

							<br />
							<br />

							<IonCard>
								<IonList>
									<IonItem className="is-ion-item-button" button={ true } routerLink="/settings/auth">
										<i className="fa-solid fa-lock"></i>
										<IonLabel>Modifica password</IonLabel>
									</IonItem>
								</IonList>
							</IonCard>

						</div>
					
					</>}

				</IonContent>

				<IonFooter>
					<section className="container---footer-section">
						<button className="is-element is-button is-color---folly is-full-width" onClick={ this.handle___user_contact___update }>
							<i className="fa-solid fa-check"></i>
							<h6>Salva</h6>
						</button>
					</section>
				</IonFooter>

			</IonPage>
		</>;

	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbSettingsAccountPage);