import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComPoiTimetableComponent: type___locale___translations___collection = {
	'open__h24': {
		de_de: 'Immer offen',
		en_us: 'Always Open',
		es_es: 'Siempre abierto',
		fr_fr: 'Toujours Ouvert',
		it_it: 'Sempre Aperto',
	},
	'open__now': {
		de_de: 'Es ist jetzt geöffnet',
		en_us: 'It\'s open now',
		es_es: 'ya esta abierto',
		fr_fr: 'C\'est ouvert maintenant',
		it_it: 'Adesso è aperto',
	},
	'closed___now': {
		de_de: 'Es ist jetzt geschlossen',
		en_us: 'It\'s closed now',
		es_es: 'esta cerrado ahora',
		fr_fr: 'C\'est fermé maintenant',
		it_it: 'Adesso è chiuso',
	},
	'closed___day': {
		de_de: 'Geschlossen',
		en_us: 'Closed',
		es_es: 'Cerrado',
		fr_fr: 'Fermé',
		it_it: 'Chiuso',
	},
};