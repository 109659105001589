import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComPoiVerifiedComponent: type___locale___translations___collection = {
	'poi_verified_popup': {
		de_de: 'Dies ist ein verifizierter Poi. Verifizierte Pois gehören zu Unternehmen, die unsere Richtlinien respektieren und ihre Identität durch unsere Mitarbeiter bestätigt haben',
		en_us: 'This is a verified Poi. Verified Pois belong to companies that respect our guidelines and have confirmed their identity through our staff',
		es_es: 'Este es un Poi verificado. Los Pois Verificados pertenecen a empresas que respetan nuestras directrices y han confirmado su identidad a través de nuestro personal.',
		fr_fr: 'Ceci est un Poi vérifié. Les Pois vérifiés appartiennent à des entreprises qui respectent nos directives et ont confirmé leur identité par l\'intermédiaire de notre personnel.',
		it_it: 'Questo è un Poi verificato. I Poi verificati appartengono ad aziende che rispettano le nostre linee guida ed hanno confermato la loro identità tramite il nostro staff',
	},
	'poi_verified_popup_link': {
		de_de: 'Erfahren Sie mehr über die Verifizierung',
		en_us: 'Learn more about verification',
		es_es: 'Más información sobre la verificación',
		fr_fr: 'En savoir plus sur la vérification',
		it_it: 'Scopri di più sulla verifica',
	}
};