import { type___notifications___lib, type___notifications_message___lib, type___notifications_push___lib } from "../types/types.notifications"

/* 
 * 
 * 
 * 
 * 								L I B   M E S S A G E S   S H A R E D
 * 
 * 
 * 
 */

export const lib_notifications_messages_shared: type___notifications_message___lib = {
	'message': {
		icon: '',
		route: '/chats/with/',
		route_has_param: true,
		type: 'message',
		title: {
			de_de: '{{1}} hat Ihnen eine Nachricht gesendet',
			en_us: '{{1}} sent you a message',
			es_es: '{{1}} te envió un mensaje',
			fr_fr: '{{1}} vous a envoyé un message',
			it_it: '{{1}} ti ha inviato un messaggio',
		},
		text: {
			de_de: '{{1}} hat Ihnen geschrieben: "{{2}}"',
			en_us: '{{1}} wrote to you: "{{2}}"',
			es_es: '{{1}} te escribió: "{{2}}"',
			fr_fr: '{{1}} vous a écrit : ""{2}}"',
			it_it: '{{1}} ti ha scritto: "{{2}}"',
		},
	},
	'message_like': {
		icon: '',
		route: '/chats/with/',
		route_has_param: true,
		type: 'message_like',
		title: {
			de_de: '{{1}} mochte eine Nachricht',
			en_us: '{{1}} liked a message',
			es_es: 'A {{1}} le gustó un mensaje',
			fr_fr: '{{1}} a aimé un message',
			it_it: '{{1}} ha messo like ad un messaggio',
		},
		text: {
			de_de: '{{1}} mochte Ihre Nachricht: "{{2}}"',
			en_us: '{{1}} liked your message: "{{2}}"',
			es_es: 'A {{1}} le gustó tu mensaje: "{{2}}"',
			fr_fr: '{{1}} a aimé votre message: "{{2}}"',
			it_it: '{{1}} ha messo like al tuo messaggio: "{{2}}"',
		},
	},
	'message_reply': {
		icon: '',
		route: '/chats/with/',
		route_has_param: true,
		type: 'message',
		title: {
			de_de: '{{1}} hat auf Ihre Nachricht geantwortet',
			en_us: '{{1}} replied to your message',
			es_es: '{{1}} respondió a tu mensaje',
			fr_fr: '{{1}} a répondu à votre message',
			it_it: '{{1}} ha risposto al tuo messaggio',
		},
		text: {
			de_de: '{{1}} antwortete: "{{2}}"',
			en_us: '{{1}} replied: "{{2}}"',
			es_es: '{{1}} respondió: "{{2}}"',
			fr_fr: '{{1}} a répondu : "{{2}}"',
			it_it: '{{1}} ti ha risposto: "{{2}}"',
		},
	},
	'message_tale': {
		icon: '',
		route: '/chats/with/',
		route_has_param: true,
		type: 'message',
		title: {
			de_de: '{{1}} hat auf Ihre Frage geantwortet',
			en_us: '{{1}} replied to your question',
			es_es: '{{1}} respondió a tu pregunta',
			fr_fr: '{{1}} a répondu à votre question',
			it_it: '{{1}} ha risposto alla tua tale',
		},
		text: {
			de_de: '{{1}} hat auf Ihre Frage geantwortet: "{{2}}"',
			en_us: '{{1}} replied to your question: "{{2}}"',
			es_es: '{{1}} respondió a tu pregunta: "{{2}}"',
			fr_fr: '{{1}} a répondu à votre question : "{{2}}"',
			it_it: '{{1}} ha risposto alla tua tale: "{{2}}"',
		},
	},
}

/* 
 * 
 * 
 * 
 * 								L I B   N O T I F I C A T I O N S   S H A R E D
 * 
 * 
 * 
 */

export const lib_notifications_push_shared: type___notifications_push___lib = {
	'invite_event': {
		icon: '',
		route: '/show/invite/event/',
		route_has_param: true,
		type: 'invite_event',
		title: {
			de_de: 'Es gibt eine neue Veranstaltung in Ihrer Nähe!',
			en_us: 'There\'s a new event near you!',
			es_es: '¡Hay un nuevo evento cerca de ti!',
			fr_fr: 'Il y a un nouvel événement près de chez vous!',
			it_it: 'C\'è un nuovo evento vicino a te!',
		},
		text: {
			de_de: 'Es gibt eine neue Veranstaltung in Ihrer Nähe! Entdecken Sie "{{1}}"',
			en_us: 'There\'s a new event near you! Discover "{{1}}"',
			es_es: '¡Hay un nuevo evento cerca de ti! Descubra "{{1}}"',
			fr_fr: 'Il y a un nouvel événement près de chez vous! Découvrez "{{1}}"',
			it_it: 'C\'è un nuovo evento vicino a te! Scopri "{{1}}"',
		},
	},
	'follow': {
		icon: '',
		route: '/view/user/',
		route_has_param: true,
		type: 'follow',
		title: {
			de_de: 'Neuer Follower',
			en_us: 'New Follower',
			es_es: 'Nuevo Seguidor',
			fr_fr: 'Nouveau Suiveur',
			it_it: 'Nuovo Follower',
		},
		text: {
			de_de: '{{1}} hat begonnen, Ihnen zu folgen',
			en_us: '{{1}} started following you',
			es_es: '{{1}} comenzó a seguirte',
			fr_fr: '{{1}} a commencé à vous suivre',
			it_it: '{{1}} ha iniziato a seguirti',
		},
	},
	'follow_accept': {
		icon: '',
		route: '/view/user/',
		route_has_param: true,
		type: 'follow_accept',
		title: {
			de_de: 'Anfrage angenommen',
			en_us: 'Request Accepted',
			es_es: 'Solicitud aceptada',
			fr_fr: 'Demande acceptée',
			it_it: 'Richiesta Accettata',
		},
		text: {
			de_de: '{{1}} hat Ihre Follow-Anfrage angenommen',
			en_us: '{{1}} accepted your follow request',
			es_es: '{{1}} aceptó tu solicitud de seguimiento',
			fr_fr: '{{1}} a accepté votre demande de suivi',
			it_it: '{{1}} ha accettato la tua richiesta di follow',
		},
	},
	'follow_request': {
		icon: '',
		route: '/view/user/',
		route_has_param: true,
		type: 'follow_request',
		title: {
			de_de: 'Neue Follow-Anfrage',
			en_us: 'New Follow request',
			es_es: 'Nueva solicitud de seguimiento',
			fr_fr: 'Nouvelle demande de suivi',
			it_it: 'Nuova richiesta di Follow',
		},
		text: {
			de_de: 'Du hast eine neue Folgeanfrage von {{1}}',
			en_us: 'You have a new follow request from {{1}}',
			es_es: 'Tienes una nueva solicitud de seguimiento de {{1}}',
			fr_fr: 'Vous avez une nouvelle demande de suivi de la part de {{1}',
			it_it: 'Hai una nuova richiesta di follow da {{1}}',
		},
	},
	'poi___new_permission': {
		icon: '',
		route: '/view/poi/',
		route_has_param: true,
		type: 'poi___new_permission',
		title: {
			de_de: 'Neue Rolle in einem Poi',
			en_us: 'New Role in a Poi',
			es_es: 'Nuevo rol en Poi',
			fr_fr: 'Nouveau rôle dans un Poi',
			it_it: 'Nuovo Ruolo in un Poi',
		},
		text: {
			de_de: '{{1}} hat Sie zu den Rollen eines Poi hinzugefügt',
			en_us: '{{1}} has added you to the roles of an Poi',
			es_es: '{{1}} te ha agregado a los roles de Poi',
			fr_fr: '{{1}} vous a ajouté aux rôles dans un Poi',
			it_it: '{{1}} ti ha aggiunto ai ruoli di un Poi',
		},
	},
	'post': {
		icon: '',
		route: '/show/post/',
		route_has_param: true,
		type: 'post',
		title: {
			de_de: 'Neuer Beitrag',
			en_us: 'New Post',
			es_es: 'Nueva publicación',
			fr_fr: 'Nouveau message',
			it_it: 'Nuovo Post',
		},
		text: {
			de_de: '{{1}} hat etwas in Ihrem Profil geschrieben',
			en_us: '{{1}} wrote something on your profile',
			es_es: '{{1}} escribió algo en tu perfil',
			fr_fr: '{{1}} a écrit quelque chose sur votre profil',
			it_it: '{{1}} ha scritto qualcosa sul tuo profilo',
		},
	},
	'post_comment': {
		icon: '',
		route: '/show/post/',
		route_has_param: true,
		type: 'post_comment',
		title: {
			de_de: 'Neuer Kommentar',
			en_us: 'New comment',
			es_es: 'Nuevo comentario',
			fr_fr: 'Nouveau commentaire',
			it_it: 'Nuovo commento',
		},
		text: {
			de_de: '{{1}} hat Ihren Beitrag kommentiert',
			en_us: '{{1}} commented on your post',
			es_es: '{{1}} comentó tu publicación',
			fr_fr: '{{1}} a commenté votre message',
			it_it: '{{1}} ha commentato il tuo post',
		},
	},
	'post_like': {
		icon: '',
		route: '/show/post/',
		route_has_param: true,
		type: 'post_like',
		title: {
			de_de: 'Neu wie auf dem Beitrag',
			en_us: 'New like on the post',
			es_es: 'Nuevo me gusta en la publicación',
			fr_fr: 'Nouveau j\'aime sur le post',
			it_it: 'Nuovo like al post',
		},
		text: {
			de_de: '{{1}} mochte Ihren Beitrag',
			en_us: '{{1}} liked your post',
			es_es: 'A {{1}} le gustó tu publicación',
			fr_fr: '{{1}} a aimé votre message',
			it_it: '{{1}} ha messo like al tuo post',
		},
	},
	'post_tag': {
		icon: '',
		route: '/show/post/',
		route_has_param: true,
		type: 'post_tag',
		title: {
			de_de: 'Sie wurden in einem Beitrag getaggt',
			en_us: 'You have been tagged in a post',
			es_es: 'Te han etiquetado en una publicación',
			fr_fr: 'Vous avez été tagué dans un post',
			it_it: 'Sei stato taggato in un post',
		},
		text: {
			de_de: '{{1}} hat Sie in seinem Beitrag markiert',
			en_us: '{{1}} tagged you in his post',
			es_es: '{{1}} te etiquetó en su publicación',
			fr_fr: '{{1}} vous a tagué dans son message',
			it_it: '{{1}} ti ha taggato nel suo post',
		},
	},
	'tale_like': {
		icon: '',
		route: '/show/tale/',
		route_has_param: true,
		type: 'tale_like',
		title: {
			de_de: 'Neues Like to Tale',
			en_us: 'New Like to "Tale"',
			es_es: 'Nuevo Me gusta ala Tale',
			fr_fr: 'Nouveau j\'aime a la Tale',
			it_it: 'Nuovo Like alla Tale',
		},
		text: {
			de_de: '{{1}} hat deine Tale gefallen',
			en_us: '{{1}} liked your Tale',
			es_es: 'A {{1}} le gustó tu Tale',
			fr_fr: '{{1}} a aimé votre Tale',
			it_it: '{{1}} ha messo un like alla tua Tale',
		},
	},
	'tale_tag': {
		icon: '',
		route: '/show/tale/',
		route_has_param: true,
		type: 'tale_tag',
		title: {
			de_de: 'Du wurdest in einer Tale markiert',
			en_us: 'You have been tagged in a Tale',
			es_es: 'Te han etiquetado en una Tale.',
			fr_fr: 'Vous avez été tagué dans un Tale',
			it_it: 'Sei stato taggato in una Tale',
		},
		text: {
			de_de: '{{1}} hat Sie in seiner Tale markiert',
			en_us: '{{1}} tagged you in his Tale',
			es_es: '{{1}} te etiquetó en su Tale',
			fr_fr: '{{1}} vous a tagué dans son Tale',
			it_it: '{{1}} ti ha taggato nella sua Tale',
		},
	},
	'generic': {
		icon: '',
		route: '/archive/notifications',
		route_has_param: false,
		type: 'generic',
		title: {
			de_de: 'Neue Benachrichtigung',
			en_us: 'New Notification',
			es_es: 'Nueva Notificación',
			fr_fr: 'Nouvelle notification',
			it_it: 'Nuova Notifica',
		},
		text: {
			de_de: 'Sie haben eine neue Benachrichtigung',
			en_us: 'You have a new notification',
			es_es: 'Tienes una nueva notificación',
			fr_fr: 'Vous avez une nouvelle notification',
			it_it: 'Hai una nuova notifica',
		},
	}
}

/*

	'____________________': {
		icon: '____________________',
		route: '____________________',
		route_has_param: boolean,
		type: '____________________',
		title: {
			de_de: '____________________',
			en_us: '____________________',
			es_es: '____________________',
			fr_fr: '____________________',
			it_it: '____________________',
		},
		text: {
			de_de: '____________________',
			en_us: '____________________',
			es_es: '____________________',
			fr_fr: '____________________',
			it_it: '____________________',
		},
	},

*/

/* 
 * 
 * 
 * 
 * 								L I B   N O T I F I C A T I O N S   S H A R E D
 * 
 * 
 * 
 */

export const lib_notifications_shared: type___notifications___lib = {
	...lib_notifications_messages_shared,
	...lib_notifications_push_shared,
}