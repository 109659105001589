import React from 'react'
import { IonToast } from '@ionic/react';

//	L O C A L I Z A T I O N S

import { locale___ComUserRequestComponent } from './com-user-request.locale';

//	S T Y L E

import './com-user-request.scss'

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';
import { type___user___follow___action_response } from '../../types/types.types';

//	P R O P S

import { props___ComUserRequestComponent, state___ComUserRequestComponent, state___ComUserRequestComponent___default } from './com-user-request.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';
import { service_RestApiService } from '../../services/service-api';

//	C L A S S

export default class ComUserRequestComponent extends React.Component<props___ComUserRequestComponent, state___ComUserRequestComponent>
{

//#region 																							C O N S T R U C T O R
		
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComUserRequestComponent,
	)
	{
		super(props);
		this.state = state___ComUserRequestComponent___default;
	}

//#endregion

//#region 																							H A N D L E R S

	handle___onActionPerformed = async (___want_to_follow: type___user___follow___action_response) => {
		const ___follow___accepted: type___api___response = await this._API.users_follows___follow_response(this.props.user___props['user_id'], ___want_to_follow);
		this.setState({
			request_is_processed: (___follow___accepted.response === 'success') ? true : false,
			ionToast___requestIsProcessed: (___follow___accepted.response === 'success') ? ___want_to_follow : 'error',
		}, () => {
			setTimeout(() => {
				this.props.event___onActionPerformed();
			}, 3100);
		});
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>

			<IonToast isOpen={(this.state.ionToast___requestIsProcessed === 'accept') ? true : false } message={ this._LOCALE.translate(locale___ComUserRequestComponent.toastNotice___request___is_accepted, [this.props.user___props['user_username']]) } duration={ 3000 } position="top" onDidDismiss={() => { this.setState({ ionToast___requestIsProcessed: null }); }}/>
			<IonToast isOpen={(this.state.ionToast___requestIsProcessed === 'reject') ? true : false } message={ this._LOCALE.translate(locale___ComUserRequestComponent.toastNotice___request___is_rejected, [this.props.user___props['user_username']]) } duration={ 3000 } position="top" onDidDismiss={() => { this.setState({ ionToast___requestIsProcessed: null }); }}/>
			<IonToast isOpen={(this.state.ionToast___requestIsProcessed === 'error') ? true : false } message={ this._LOCALE.translate(locale___ComUserRequestComponent.toastNotice___request___error) } duration={ 3000 } position="top" onDidDismiss={() => { this.setState({ ionToast___requestIsProcessed: null }); }}/>

			{(this.state.request_is_processed === true) ? <></> : <>
				<section className="com-user-request---component">

					<h6>{ this._LOCALE.translate(locale___ComUserRequestComponent.request___paragraph, [this.props.user___props['user_username']]) }</h6>

					<section>
						<div onClick={() => { this.handle___onActionPerformed('accept'); }}>
							<i className="fas fa-check"></i>
							<span>{ this._LOCALE.translate(locale___ComUserRequestComponent.request___accept) }</span>
						</div>
						<div onClick={() => { this.handle___onActionPerformed('reject'); }}>
							<i className="fas fa-times"></i>
							<span>{ this._LOCALE.translate(locale___ComUserRequestComponent.request___reject) }</span>
						</div>
					</section>

				</section>
			</>}

		</>;
	}

//#endregion

}