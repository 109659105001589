import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './sb-info-partners.scss';

//	C L A S S

class SbInfoPartnersPage extends React.Component<{}, {}>
{

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/discover/map"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>
					<section className="sb-info-partners---container">




						<section>

							<a href="https://www.prysmlab.com/" target="_blank">
								<img src="/assets/partners/prysm.png" alt="PRYSM"/>
							</a>

							<a href="https://www.nopr.me/" target="_blank">
								<img src="/assets/partners/nopr.png" alt="NoPR"/>
							</a>

							<a href="https://www.carshub.app/" target="_blank">
								<img src="/assets/partners/carshub.png" alt="CarsHub"/>
							</a>

							<a href="https://www.marcelloputzolu.com/" target="_blank">
								<img src="/assets/partners/mars.png" alt="MARS"/>
							</a>

							<a href="https://www.ritapanicommercialista.it/" target="_blank">
								<img src="/assets/partners/ritapanicommercialista.png" alt="MARS"/>
							</a>

						</section>

					</section>
				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbInfoPartnersPage);