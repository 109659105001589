import React from 'react'

//	S T Y L E

import './com-post-create.scss'

//	S T A T E S   -   P R O P S

import { props___ComPostCreateComponent, state___ComPostCreateComponent, state___ComPostCreateComponent___default } from './com-post-create.state';

//	M O D A L S

import MdPostsCreateModal from '../../modals/md-post-create/md-posts-create';

//	C L A S S

export default class ComPostCreateComponent extends React.Component<props___ComPostCreateComponent, state___ComPostCreateComponent>
{

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComPostCreateComponent,
	) {
		super(props);
		this.state = state___ComPostCreateComponent___default;
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___show_modal___MdPostsCreateModal = () => {
		this.setState({
			ionModal___MdPostsCreateModal___isOpen: !this.state.ionModal___MdPostsCreateModal___isOpen
		});
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>

			<MdPostsCreateModal isOpen={ this.state.ionModal___MdPostsCreateModal___isOpen } is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props } post_target___type={ this.props.post_creation_target.target } post_target___id={ this.props.post_creation_target.id } post_target___name={ this.props.post_creation_target.name } event___onDidDismiss={ this.handle___show_modal___MdPostsCreateModal } />
		
			<div className="com-post-create---container" onClick={ this.handle___show_modal___MdPostsCreateModal }>
				{(this.props.post_creation_target.target === 'poi') ? <>
					<i className="far fa-newspaper"></i>
					<span>Pubblica un aggiornamento</span>
				</> : <>
					{(this.props.post_creation_target['id'] === this.props.is_logged___props['user_id']) ? <>
						<i className="far fa-comment-dots"></i>
						<span>A cosa stai pensando?</span>
					</> : <>
						<i className="far fa-comments"></i>
						<span>Scrivi qualcosa...</span>
					</>}
				</>}
			</div>

		</>;
	}

//#endregion

}