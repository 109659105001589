import { type___text___emoji } from "../types/types.text";

/* 
 * 
 * 
 * 
 * 								L I B   E M O J I
 * 
 * 
 * 
 */

export const lib_emoji: type___text___emoji[] = [
	{
		text: ':shark:',
		emoji: '🦈'
	},
	{
		text: ':)',
		emoji: '😊'
	},
	{
		text: ':(',
		emoji: '😢'
	},
	{
		text: ':D',
		emoji: '😂'
	},
	{
		text: ';)',
		emoji: '😉'
	},
	{
		text: '<3',
		emoji: '❤️'
	},
	{
		text: '</3',
		emoji: '💔'
	},
	{
		text: ':+1:',
		emoji: '👍'
	},
	{
		text: ':o',
		emoji: '😮'
	},
	{
		text: ':\'(',
		emoji: '😭'
	},
	{
		text: ':P',
		emoji: '😜'
	},
	{
		text: 'B)',
		emoji: '😎'
	},
	{
		text: ":|",
		emoji: "😐"
	},
	{
		text: ":/",
		emoji: "😕"
	},
	{
		text: "O:)",
		emoji: "😇"
	},
	{
		text: ">:(",
		emoji: "😡"
	},
	{
		text: ":3",
		emoji: "😺"
	},
	{
		text: "XD",
		emoji: "😆"
	},
	{
		text: ":*",
		emoji: "😘"
	},
	{
		text: ":$",
		emoji: "😳"
	},
	{
		text: ":@",
		emoji: "😠"
	},
	{
		text: ":]",
		emoji: "😏"
	},
	{
		text: ":'D",
		emoji: "😂"
	},
	{
		text: "8)",
		emoji: "😎"
	},
	{
		text: ":S",
		emoji: "😖"
	},
	{
		text: ":X",
		emoji: "😶"
	},
	{
		text: ":c",
		emoji: "😞"
	},
	{
		text: ":v",
		emoji: "✌️"
	},
	{
		text: ">:O",
		emoji: "😲"
	},
	{
		text: ":L",
		emoji: "😔"
	},
	{
		text: "D:",
		emoji: "😱"
	},
	{
		text: ":z",
		emoji: "😴"
	},
	{
		text: ":>",
		emoji: "😏"
	},
	{
		text: "-_-",
		emoji: "😑"
	}




]