import React from 'react'
import { IonActionSheet, IonCard, IonItem, IonLabel, IonList } from '@ionic/react';

//	L O C A L I Z A T I O N

import { locale___ComMapPreviewAddressComponent } from './com-map-preview-address.locale';

//	F U N C S

import { funcs_geolocation } from '../../funcs/funcs.geolocation';

//	I N T E R F A C E S

import { interface___IonActionSheet } from '../../interfaces/interface.actionsheet';

//	S T A T E   -   P R O P S

import { props___ComMapPreviewAddressComponent, state___ComMapPreviewAddressComponent, state___ComMapPreviewAddressComponent___default } from './com-map-preview-address.state';

//	 S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C L A S S

export default class ComMapPreviewAddressComponent extends React.Component<props___ComMapPreviewAddressComponent, state___ComMapPreviewAddressComponent>
{

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

	private readonly funcs___geolocation: funcs_geolocation = new funcs_geolocation();

//#endregion

//#region 																							C O N F I G

	private readonly IonActionSheet___mapNavigation___config: interface___IonActionSheet = {
		header: this._LOCALE.translate(locale___ComMapPreviewAddressComponent.go_to_navigation),
		buttons: [
			{
				text: this._LOCALE.translate(locale___ComMapPreviewAddressComponent.go_to_navigation___apple_maps),
				handler: () => { 
					const ___navigation_url___ios: string = 'http://maps.apple.com/?ll=' + this.props.coords.lat.toString() + ',' + this.props.coords.lng.toString();
					const ___navigation_url___ios___window: WindowProxy | null = window.open(___navigation_url___ios, '_blank');
					___navigation_url___ios___window.focus();
				}
			},
			{
				text: this._LOCALE.translate(locale___ComMapPreviewAddressComponent.go_to_navigation___google_maps),
				handler: () => { 
					const ___navigation_url___android: string = 'https://www.google.com/maps?q=' + this.props.coords.lat.toString() + ',' + this.props.coords.lng.toString();
					const ___navigation_url___android___window: WindowProxy | null = window.open(___navigation_url___android, '_blank');
					___navigation_url___android___window.focus();
				}
			},
			{
				text: this._LOCALE.translate(locale___ComMapPreviewAddressComponent.go_to_navigation___cancel),
				role: 'cancel',
			}
		]
	};

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComMapPreviewAddressComponent,
	)
	{
		super(props);
		this.state = state___ComMapPreviewAddressComponent___default;
	}

//#endregion

//#region 																							H A N D L E R S

	private read_props___address = async () => {
		const ___address: string = await this.funcs___geolocation.geolocation___get_address___from_coords(this.state.coords);
		this.setState({
			address: ___address,
		});
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___open___map_address_route = () => {
		if (this.props.canOpenRoute === true) {
			this.setState({
				ionActionSheet___mapNavigation___isOpen: true
			});
		}
	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void>
	{
		await this.read_props___address();
	}

	componentDidUpdate(prevProps: props___ComMapPreviewAddressComponent, prevState: state___ComMapPreviewAddressComponent) : void
	{
		if (this.props !== prevProps) {
			this.setState({
				coords: this.props.coords
			}, async () => {
				await this.read_props___address();
			});
		}
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>

			<IonActionSheet isOpen={ this.state.ionActionSheet___mapNavigation___isOpen } {...this.IonActionSheet___mapNavigation___config} onDidDismiss={() => { this.setState({ ionActionSheet___mapNavigation___isOpen: false }); }} />

			<IonCard onClick={ this.handle___open___map_address_route }>
				<IonList onClick={ this.handle___open___map_address_route }>
					<IonItem lines="none" className="is-ion-item-button">
						<i className="fas fa-map-marked-alt"></i>
						<IonLabel>{ this.state.address }</IonLabel>
					</IonItem>
				</IonList>
			</IonCard>
			
			<span style={{ display: 'block', height: '10pt', margin: 0 }}></span>

		</>;
	}

//#endregion

}