import mapboxgl from 'mapbox-gl';

//	E X P O R T   F U N C S

export class funcs_mapbox
{

//#region 																							L O A D   E L E M E N T   I C O N

	public map___load___icon = async (___map_istance: mapboxgl.Map, ___icon: string, ___type: 'events' | 'poi') => {
		return new Promise<void>((resolve, reject) => {
			const ___marker___icon_path: string = '/assets/markers/' + ___type + '/' + ___icon + '.png';
			const ___marker___icon_name: string = 'map_' + ___type + '_' + ___icon;
			___map_istance.loadImage(___marker___icon_path, (___err, ___i) => {
				if (___err) { reject(___err); } else { ___map_istance.addImage(___marker___icon_name, ___i); resolve(); }
			});
		});
	};

//#endregion

}