import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___MdDiscoverFiltersModal: type___locale___translations___collection = {
	'topBarButton___cancel': {
		de_de: 'Stornieren',
		en_us: 'Cancel',
		es_es: 'Cancelar',
		fr_fr: 'Annuler',
		it_it: 'Annulla',
	},
	'showing': {
		de_de: 'Anzuzeigende Elemente',
		en_us: 'Items to Display',
		es_es: 'Artículos para mostrar',
		fr_fr: 'Éléments à afficher',
		it_it: 'Elementi da visualizzare',
	},
	'showing___poi': {
		de_de: 'Sehenswürdigkeiten (Poi)',
		en_us: 'Point of Interests (Poi)',
		es_es: 'Puntos de interés (Poi)',
		fr_fr: 'Points d\'intérêt (Poi)',
		it_it: 'Punti di interesse (Poi)',
	},
	'showing___events': {
		de_de: 'Veranstaltungen',
		en_us: 'Events',
		es_es: 'Eventos',
		fr_fr: 'Événements',
		it_it: 'Eventi',
	},
	'showing___tales': {
		de_de: 'Tales',
		en_us: 'Tales',
		es_es: 'Tales',
		fr_fr: 'Tales',
		it_it: 'Tales',
	},
	'map_style': {
		de_de: 'Kartenstil',
		en_us: 'Map Style',
		es_es: 'Estilo de mapa',
		fr_fr: 'Style de carte',
		it_it: 'Stile della mappa',
	},
	'map_style___normal': {
		de_de: 'standard',
		en_us: 'standard',
		es_es: 'estándar',
		fr_fr: 'standard',
		it_it: 'standard',
	},
	'map_style___satellite': {
		de_de: 'satellit',
		en_us: 'satellite',
		es_es: 'satélite',
		fr_fr: 'satellite',
		it_it: 'satellite',
	},
	'map_style___dark': {
		de_de: 'dark',
		en_us: 'dark',
		es_es: 'dark',
		fr_fr: 'dark',
		it_it: 'dark',
	},
	'button___save': {
		de_de: 'Wenden Sie Filter an',
		en_us: 'Apply Filters',
		es_es: 'Aplicar filtros',
		fr_fr: 'Appliquer des filtres',
		it_it: 'Applica filtri',
	}
};