import { RouteComponentProps } from "react-router";
import { interface___is_logged } from "../../../interfaces/interface.logged";

//	P R O P S

export interface props___SbEventViewNotificationsPage extends RouteComponentProps<{ id: string; }>, interface___is_logged {

}

//	S T A T E

export interface state___SbEventViewNotificationsPage {

	event___id: string,
	event___props: any | null,
	event___props___is_loading: boolean, 

	invite_expiration___datetime___min: string,
	invite_expiration___datetime___max: string,
	invite_expiration___datetime: string,

	credits___amount: number,

	sendable_invites___radius: number,
	sendable_invites___multiplier: number,

	sendable_invites___results: number,
	sendable_invites___credits_cost: number,
	sendable_invites___eula_accepted: boolean,





}

//	S T A T E   D E F A U L T

export const state___SbEventViewNotificationsPage___default: state___SbEventViewNotificationsPage = {

	event___id: '',
	event___props: null,
	event___props___is_loading: true, 

	credits___amount: 0,

	sendable_invites___radius: 10,
	sendable_invites___multiplier: 1,

	sendable_invites___results: 0,
	sendable_invites___credits_cost: 0,
	sendable_invites___eula_accepted: false,

	invite_expiration___datetime___min: undefined,
	invite_expiration___datetime___max: undefined,
	invite_expiration___datetime: undefined,





}