import { RouteComponentProps } from "react-router";
import { interface___is_logged } from "../../../interfaces/interface.logged";

//	P R O P S

export interface props___SbPoiViewFeedPage extends RouteComponentProps<{ id: string; }>, interface___is_logged {

}

//	S T A T E

export interface state___SbPoiViewFeedPage {

	poi___id: string,
	poi___props: any | null,
	poi___props___is_loading: boolean, 

	poi_posts___collection: any[],
	poi_posts___collection___is_loading: boolean,
	poi_posts___collection___is_complete: boolean,

}

//	S T A T E   D E F A U L T

export const state___SbPoiViewFeedPage___default: state___SbPoiViewFeedPage = {

	poi___id: '',
	poi___props: null,
	poi___props___is_loading: true, 

	poi_posts___collection: [],
	poi_posts___collection___is_loading: true,
	poi_posts___collection___is_complete: false,

}