import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComUserComponent: type___locale___translations___collection = {
	'followed_by_you': {
		de_de: '______________',
		en_us: '______________',
		es_es: '______________',
		fr_fr: '______________',
		it_it: 'lo segui già',
	},
	'followed_by': {
		de_de: '______________',
		en_us: '______________',
		es_es: '______________',
		fr_fr: '______________',
		it_it: '{{1}} contatti in comune',
	},
};