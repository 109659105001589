import { RouteComponentProps } from "react-router";
import { interface___is_logged } from "../../../interfaces/interface.logged";

//	P R O P S

export interface props___SbArchivePoiPage extends RouteComponentProps<{ id: string; }>, interface___is_logged {

}

//	S T A T E

export interface state___SbArchivePoiPage {

	poi___collection: any[],
	poi___collection___is_loading: boolean,
	poi___collection___is_complete: boolean,

}

//	S T A T E   D E F A U L T

export const state___SbArchivePoiPage___default: state___SbArchivePoiPage = {

	poi___collection: [],
	poi___collection___is_loading: true,
	poi___collection___is_complete: false,

}