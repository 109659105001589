import React from 'react';

//	S T Y L E

import './com-gallery-editor.scss'

//	T Y P E S

import { props___ComGalleryEditComponent, state___ComGalleryEditComponent, state___ComGalleryEditComponent___defaults } from './com-gallery-editor.state';

//	C L A S S

export default class ComGalleryEditComponent extends React.Component<props___ComGalleryEditComponent, state___ComGalleryEditComponent>
{

//#region 																							D E C L A R A T I O N S

	private item___dragged: number | null = null;

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComGalleryEditComponent,
	) {
		super(props);
		this.state = state___ComGalleryEditComponent___defaults;
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___gallery_file___onDragStart = (___index: number) => {
		this.item___dragged = ___index;
		this.setState({ action___item___is_dragging: true });
	};

	private handle___gallery_file___onDragEnter = (___index: number) => {
		if (this.item___dragged !== null) {
			const ___picture___array = [...this.state.field___gallery_pictures];
			const ___picture = ___picture___array.splice(this.item___dragged, 1)[0];
			___picture___array.splice(___index, 0, ___picture);
			this.setState({
				field___gallery_pictures: ___picture___array
			}, () => {
				this.item___dragged = ___index;
			});
		}
	};

	private handle___gallery_file___onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
	};

	private handle___gallery_file___onDragEnd = () => {
		this.props.event___onUpdate(this.state.field___gallery_pictures);
		this.item___dragged = null;
		this.setState({ action___item___is_dragging: false });
	};

	private handle___gallery_file___remove = (___index: number) => {
		this.setState((___prevState) => ({
			field___gallery_pictures: ___prevState.field___gallery_pictures.filter((_, i) => i !== ___index),
		}));
	}

//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount(): void {
		this.setState({
			field___gallery_pictures: this.props.gallery_pictures
		});
	}

	componentDidUpdate(prevProps: props___ComGalleryEditComponent, prevState: state___ComGalleryEditComponent) : void {
		if (this.props !== prevProps) {
			this.setState({
				field___gallery_pictures: this.props.gallery_pictures
			});
		}
	}

//#endregion

//#region 																							R E T U R N

	render(): React.ReactNode
	{
		return <>
			<div className="com-gallery-editor-component---container">
				{this.state.field___gallery_pictures.map((___img: string, ___img___idx: number) => <>
					<div key={ ___img___idx } draggable onDragStart={() => this.handle___gallery_file___onDragStart(___img___idx)} onDragEnter={() => this.handle___gallery_file___onDragEnter(___img___idx)} onDragOver={ this.handle___gallery_file___onDragOver } onDragEnd={ this.handle___gallery_file___onDragEnd } >
						<img key={ ___img___idx } src={ ___img }/>
						<i className="fas fa-trash com-gallery-editor-component---remove" onClick={() => { this.handle___gallery_file___remove(___img___idx) }}></i>
					</div>
				</>)}
			</div>
		</>;
	}

//#endregion

}