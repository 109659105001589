import { interface___is_logged } from "../../../interfaces/interface.logged";

//	P R O P S

export interface props___SbDiscoverFeedPage extends interface___is_logged {
	
}

//	S T A T E

export interface state___SbDiscoverFeedPage {

	feed___tales: any[],
	feed___tales___is_loading: boolean,
	feed___tales___is_complete: boolean,

	feed___posts: any[],
	feed___posts___is_loading: boolean,
	feed___posts___is_complete: boolean,




















	ionModal___MdTalesViewModal___isOpen: boolean,
	ionModal___MdTalesViewModal___argument: string | null,

}

//	S T A T E   D E F A U L T

export const state___SbDiscoverFeedPage___default: state___SbDiscoverFeedPage = {
	
	feed___tales: [],
	feed___tales___is_loading: true,
	feed___tales___is_complete: false,

	feed___posts: [],
	feed___posts___is_loading: true,
	feed___posts___is_complete: false,

















	ionModal___MdTalesViewModal___isOpen: false,
	ionModal___MdTalesViewModal___argument: null,

}