import { RouteComponentProps } from "react-router";
import { interface___is_logged } from "../../../interfaces/interface.logged";

//	P R O P S

export interface props___SbArchiveFavoritesPage extends RouteComponentProps<{ id: string; }>, interface___is_logged {

}

//	S T A T E

export interface state___SbArchiveFavoritesPage {

	favorites___collection: any[],
	favorites___collection___is_loading: boolean,
	favorites___collection___is_complete: boolean,

}

//	S T A T E   D E F A U L T

export const state___SbArchiveFavoritesPage___default: state___SbArchiveFavoritesPage = {

	favorites___collection: [],
	favorites___collection___is_loading: true,
	favorites___collection___is_complete: false,

}