import { Capacitor } from '@capacitor/core';
import { getPlatforms } from '@ionic/react';

//	E X P O R T   F U N C S

export class funcs_platform
{

//#region 																							I S   N A T I V E

	is_native() : boolean
	{
		const ___is_native: boolean = Capacitor.isNativePlatform();
		return ___is_native as boolean;
	}

//#endregion

//#region 																							G E T   T Y P E

	get_type() : 'ios' | 'android' | null
	{
		const ___platforms: string[] = getPlatforms();
		if (___platforms.includes('android')) { return 'android'; }
		if (___platforms.includes('ios')) { return 'ios'; }
		return null;
	}

//#endregion

}