import React from 'react';
import { IonContent, IonModal } from '@ionic/react';

//	L O C A L I Z A T I O N

import { locale___MdEntryCookiesModal } from './md-entry-cookies.locale';

//	S T Y L E

import './md-entry-cookies.scss';

//	L I B S

import { lib_names } from '../../libs/lib.names';

//	T Y P E S

import { type___cookies___accepted } from '../../types/types.cookies';
import { type___modal___config } from '../../types/types.modals';

//	S T A T E   -   P R O P S

import { props___MdEntryCookiesModal } from './md-entry-cookies.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C L A S S

export default class MdEntryCookiesModal extends React.Component<props___MdEntryCookiesModal, {}>
{

//#region 																							C O N F I G

	private readonly MdEntryCookiesModal___modal_config: type___modal___config = {
		backdropDismiss: false,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
	};

//#endregion

//#region 																							D E C L A R A T I O N S
	
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							H A N D L E R S

	private handle___cookie_select = (___ca: type___cookies___accepted) => {
		localStorage.setItem(lib_names.localStorage.user___cookies_accepted, ___ca);
		this.props.event___onDidDismiss(___ca);
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonModal isOpen={ this.props.isOpen } { ...this.MdEntryCookiesModal___modal_config }>
				<IonContent forceOverscroll={ false }>
					<section className="md-entry-cookies---container">
						
						<img src="/assets/logo.horizontal.svg"/>

						<h4>{ this._LOCALE.translate(locale___MdEntryCookiesModal.title) }</h4>
					
						<div>
							<span>{ this._LOCALE.translate(locale___MdEntryCookiesModal.description_1) }</span>
							<span>{ this._LOCALE.translate(locale___MdEntryCookiesModal.description_2) }</span>
							<span>{ this._LOCALE.translate(locale___MdEntryCookiesModal.warning) }</span>
							<a target="_blank" href="https://www.nearby.ws/info/eula">
								{ this._LOCALE.translate(locale___MdEntryCookiesModal.more_info) }
							</a>
						</div>

						<br />

						<section className="container---footer-section" style={{flexDirection:'column'}}>
							<button className="is-element is-button is-color---success is-full-width" onClick={() => { this.handle___cookie_select('full'); }}>
								<i className="fa-solid fa-check"></i>
								<h4>{ this._LOCALE.translate(locale___MdEntryCookiesModal.button___accept) }</h4>
							</button>
							<button className="is-element is-button is-color---folly is-full-width" onClick={() => { this.handle___cookie_select('partial'); }}>
								<i className="fa-solid fa-times"></i>
								<h4>{ this._LOCALE.translate(locale___MdEntryCookiesModal.button___reject) }</h4>
							</button>
						</section>

					</section>
				</IonContent>
			</IonModal>
		</>;
	}

//#endregion

}