import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonFooter, IonHeader, IonLabel, IonListHeader, IonPage, IonToast, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './sb-settings-authentication.scss';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	P R O P S   -   S T A T E S

import { props___SbSettingsAuthenticationPage, state___SbSettingsAuthenticationPage, state___SbSettingsAuthenticationPage___default } from './sb-settings-authentication.state';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';

//	C L A S S

class SbSettingsAuthenticationPage extends React.Component<props___SbSettingsAuthenticationPage, state___SbSettingsAuthenticationPage>
{

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public _PROPS: props___SbSettingsAuthenticationPage,
	)
	{
		super(_PROPS);
		this.state = state___SbSettingsAuthenticationPage___default;
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___change_password = async () => {
		if (this.state.field___password___new___x1 !== this.state.field___password___new___x2) {
			this.setState({
				toast___password_change___active: true,
				toast___password_change___type: 'error_new_not_match',
			});
		}
		else
		{
			const ___temp___password_change: type___api___response = await this._API.auth___perform___password_change(this.state.field___password___old, this.state.field___password___new___x1);
			if (___temp___password_change && ___temp___password_change.response === 'success')
			{
				this.setState({
					toast___password_change___active: true,
					toast___password_change___type: 'success',
				});
			}
			else
			{
				this.setState({
					toast___password_change___active: true,
					toast___password_change___type: 'error_generic',
				});
			}
		}
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				{(this.state.toast___password_change___active === true && this.state.toast___password_change___type !== null) ? <>
					{(() => {
						switch (this.state.toast___password_change___type) {
							case 'success': return <>
								<IonToast position="top" isOpen={ this.state.toast___password_change___active } message="Password cambiata con successo" color="success" duration={ 3000 } onDidDismiss={() => { this.setState({ toast___password_change___active: false, toast___password_change___type: null }); }}></IonToast>
							</>; break;
							case 'error_generic': return <>
								<IonToast position="top" isOpen={ this.state.toast___password_change___active } message="Impossibile cambiare la password, errore generico" color="danger" duration={ 3000 } onDidDismiss={() => { this.setState({ toast___password_change___active: false, toast___password_change___type: null }); }}></IonToast>
							</>; break;
							case 'error_new_not_match': return <>
								<IonToast position="top" isOpen={ this.state.toast___password_change___active } message="Le nuove password non combaciano" color="danger" duration={ 3000 } onDidDismiss={() => { this.setState({ toast___password_change___active: false, toast___password_change___type: null }); }}></IonToast>
							</>; break;
							case 'error_old_wrong': return <>
								<IonToast position="top" isOpen={ this.state.toast___password_change___active } message="Hai inserito la vecchia password in modo errato" color="danger" duration={ 3000 } onDidDismiss={() => { this.setState({ toast___password_change___active: false, toast___password_change___type: null }); }}></IonToast>
							</>; break;
						}
					})()}
				</> : <></>}

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/settings"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					<section className="sb-settings-authentication---container">

						<IonListHeader>
							<IonLabel>Cambia Password</IonLabel>
						</IonListHeader>

						<br />
						<br />

						<input placeholder="Vecchia Password" type="password" className="input---nb-outlined" value={ this.state.field___password___old } onInput={(___e: any) => this.setState({ field___password___old: ___e.target.value })}/>
						<br />
						<br />
						<input placeholder="Nuova Password" type="password" className="input---nb-outlined" value={ this.state.field___password___new___x1 } onInput={(___e: any) => this.setState({ field___password___new___x1: ___e.target.value })}/>
						<br />
						<input placeholder="Ripeti Nuova Password" type="password" className="input---nb-outlined" value={ this.state.field___password___new___x2 } onInput={(___e: any) => this.setState({ field___password___new___x2: ___e.target.value })}/>

					</section>

				</IonContent>

				<IonFooter>
					<section className="container---footer-section" onClick={ this.handle___change_password }>
						<button className="is-element is-button is-color---folly is-full-width">
							<i className="fa-solid fa-check"></i>
							<h6>Salva</h6>
						</button>
					</section>
				</IonFooter>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbSettingsAuthenticationPage);