import { RouteComponentProps } from "react-router";
import { type___geo___lngLat, type___poi___socials, type___poi___timetable } from "../../../types/types.types";
import { interface___is_logged } from "../../../interfaces/interface.logged";

//	P R O P S

export interface props___SbPoiEditPage extends RouteComponentProps<{ id: string; }>, interface___is_logged {

}

//	S T A T E

export interface state___SbPoiEditPage {

	collection___poi_attributes: any[],
	collection___poi_attributes___is_loading: boolean,

	poi___id: string | 'new',
	poi___props___is_loading: boolean,

	field___poi_category: any | null,
	field___poi_gallery: string[],
	field___poi_name: string,
	field___poi_description: string,

	field___poi_attributes: string[],
	
	field___poi_contact_phone_prefix: string,
	field___poi_contact_phone: string,
	field___poi_contact_email: string,
	field___poi_contact_socials: type___poi___socials,
	field___poi_contact_website: string,

	field___poi_coords: type___geo___lngLat | null,
	field___poi_coords___address: string,

	field___poi_timetable: type___poi___timetable,

	submitting___is_loading: boolean,






	ionAlert___askForClose___isVisible: boolean,

	ionModal___MdMediaEditorModal___isOpen: boolean,
	ionModal___MdPoiSelectCategoryModal___isOpen: boolean,
	ionModal___MdPoiTimetableModal___isOpen: boolean,
	ionModal___MdPositionSelectorModal___isOpen: boolean,

	ionToast___isOpen: boolean,
	ionToast___message: string,
	ionToast___type: 'success' | 'danger',

}

//	S T A T E   D E F A U L T

export const state___SbPoiEditPage___default: state___SbPoiEditPage = {

	collection___poi_attributes: [],
	collection___poi_attributes___is_loading: true,

	poi___id: '',
	poi___props___is_loading: true,

	field___poi_category: null,
	field___poi_gallery: [],
	field___poi_name: '',
	field___poi_description: '',

	field___poi_attributes: [],

	field___poi_contact_phone_prefix: '',
	field___poi_contact_phone: '',
	field___poi_contact_email: '',
	field___poi_contact_socials: { facebook: '', instagram: '', youtube: '', tiktok: '', twitter: '' },
	field___poi_contact_website: '',

	field___poi_coords: null,
	field___poi_coords___address: 'Nessun indirizzo selezionato',

	field___poi_timetable: 'none',

	submitting___is_loading: false,










	ionAlert___askForClose___isVisible: false,

	ionModal___MdMediaEditorModal___isOpen: false,
	ionModal___MdPoiSelectCategoryModal___isOpen: false,
	ionModal___MdPoiTimetableModal___isOpen: false,
	ionModal___MdPositionSelectorModal___isOpen: false,

	ionToast___isOpen: false,
	ionToast___message: '',
	ionToast___type: 'success',

}