import { RouteComponentProps } from "react-router";
import { interface___is_logged } from "../../../interfaces/interface.logged";

//	P R O P S

export interface props___SbElementPostPage extends RouteComponentProps<{ id: string; }>, interface___is_logged {

}

//	S T A T E

export interface state___SbElementPostPage {

	post___id: string,

	post___props: any | null,
	post___props___is_loading: boolean,

	post_comments___collection: any[],
	post_comments___collection___is_loading: boolean,
	post_comments___collection___is_complete: boolean,
	post_comments___collection___is_scrolled_down: boolean,









	field___new_comment___text: string,
	field___new_comment___is_submitting: boolean,




}

//	S T A T E   D E F A U L T

export const state___SbElementPostPage___default: state___SbElementPostPage = {

	post___id: '',

	post___props: null,
	post___props___is_loading: true,

	post_comments___collection: [],
	post_comments___collection___is_loading: true,
	post_comments___collection___is_complete: false,
	post_comments___collection___is_scrolled_down: false,








	field___new_comment___text: '',
	field___new_comment___is_submitting: false,











}