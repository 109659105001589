import { RouteComponentProps } from "react-router";

//	P R O P S

export interface props___SbElementTalePage extends RouteComponentProps<{ id: string; }> {

}

//	S T A T E

export interface state___SbElementTalePage {

	tale___id: string | null,

}

//	S T A T E   D E F A U L T

export const state___SbElementTalePage___default: state___SbElementTalePage = {

	tale___id: null,

}