//	S T A T E

export interface state___SbSettingsProfilePage {

	user___props: any | null,
	user___props___is_loading: boolean,

	field___picture___cover: string | null,
	field___picture___profile: string | null,

	field___user___username: string,
	field___user___name: string,
	field___user___bio: string,

	field___user___contact_email: string,
	field___user___contact_phone: string,

	ionModal___MdMediaEditorModal___isOpen: boolean,
	ionModal___MdMediaEditorModal___argument: string[] | null,
	ionModal___MdMediaEditorModal___aspectRatio: number,
	ionModal___MdMediaEditorModal___target: 'cover' | 'profile',

	ionToast___isSubmitted___result: 'success' | 'error' | false,

}

//	S T A T E   D E F A U L T

export const state___SbSettingsProfilePage___default: state___SbSettingsProfilePage = {

	user___props: null,
	user___props___is_loading: true,

	field___picture___cover: null,
	field___picture___profile:  null,

	field___user___username: '',
	field___user___name: '',
	field___user___bio: '',

	field___user___contact_email: '',
	field___user___contact_phone: '',

	ionModal___MdMediaEditorModal___isOpen: false,
	ionModal___MdMediaEditorModal___argument: null,
	ionModal___MdMediaEditorModal___aspectRatio: 0,
	ionModal___MdMediaEditorModal___target: null,
	
	ionToast___isSubmitted___result: false,

}