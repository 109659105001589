import React from 'react'

//	S T Y L E

import './com-chat-conversation-online.scss'

//	C L A S S

export default class ComChatConversationOnlineComponent extends React.Component<{}, {}>
{

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			{/**
				<div className="com-chat-conversation-online---container">
					<span>*</span>
				</div>
			 */}
		</>;
	}

//#endregion

}