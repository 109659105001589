import { RouteComponentProps } from "react-router";

//	S T A T E

export interface state___SbSettingsCachePage {

	localStorage___is_clearing: boolean,

}

//	S T A T E   D E F A U L T

export const state___SbSettingsCachePage___default: state___SbSettingsCachePage = {

	localStorage___is_clearing: false,

}