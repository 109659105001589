import { type___locale___translations___collection } from "../../../types/types.locale";

//	E X P O R T   L O C A L E

export const locale___SbInfoStatsPage: type___locale___translations___collection = {
	'stats___platform': {
		de_de: 'Plattformstatistiken',
		en_us: 'Platform Statistics',
		es_es: 'Estadísticas de la plataforma',
		fr_fr: 'Statistiques de la plateforme',
		it_it: 'Statistiche piattaforma',
	},
	'stats___platform___poi_categories': {
		de_de: 'Kategorien',
		en_us: 'Categories',
		es_es: 'Categorías',
		fr_fr: 'Catégories',
		it_it: 'Categorie',
	},
	'stats___platform___poi': {
		de_de: 'Sehenswürdigkeiten',
		en_us: 'Points of Interest',
		es_es: 'Puntos de interés',
		fr_fr: 'Points d\'interêts',
		it_it: 'Punti di Interesse',
	},
	'stats___platform___events': {
		de_de: 'Aktuelle Ereignisse',
		en_us: 'Current Events',
		es_es: 'Eventos actuales',
		fr_fr: 'Actualités',
		it_it: 'Eventi in corso',
	},
	'stats___platform___daily_views': {
		de_de: 'Tägliche Ansichten',
		en_us: 'Daily Views',
		es_es: 'Vistas diarias',
		fr_fr: 'Vues Quotidiennes',
		it_it: 'Visualizzazioni Giornaliere',
	},
	'stats___users': {
		de_de: 'Benutzerstatistiken',
		en_us: 'User Statistics',
		es_es: 'Estadísticas de usuario',
		fr_fr: 'Statistiques des utilisateurs',
		it_it: 'Statistiche Utenti',
	},
	'stats___users___registered': {
		de_de: 'Registrierte Benutzer',
		en_us: 'Registered Users',
		es_es: 'Usuarios Registrados',
		fr_fr: 'Utilisateurs enregistrés',
		it_it: 'Utenti registrati',
	},
	'stats___users___tales': {
		de_de: 'Geteilte Geschichten',
		en_us: 'Shared Tales',
		es_es: 'Cuentos compartidos',
		fr_fr: 'Contes partagés',
		it_it: 'Tales condivise',
	},
	'stats___users___posts': {
		de_de: 'Geteilte Beiträge',
		en_us: 'Shared Posts',
		es_es: 'Publicaciones compartidas',
		fr_fr: 'Publications partagées',
		it_it: 'Post condivisi',
	},
	'stats___users___reviews': {
		de_de: 'Bewertungen abgeschlossen',
		en_us: 'Reviews Given',
		es_es: 'Reseñas hechas',
		fr_fr: 'Commentaires terminés',
		it_it: 'Recensioni Effettuate',
	},
};