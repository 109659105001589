import React from 'react';
import { IonButton, IonButtons, IonCard, IonContent, IonFooter, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonModal, IonSelect, IonSelectOption, IonToast, IonToolbar } from '@ionic/react';

//	S T Y L E

import './md-digitalmenu-dish-edit.scss';

//	S T A T E   -   P R O P S

import { props___MdDigitalMenuDishEditModal, state___MdDigitalMenuDishEditModal, state___MdDigitalMenuDishEditModal___default } from './md-digitalmenu-dish-edit.state';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';
import { type___digitalmenu___dish_measurement_unit } from '../../types/types.digitalmenu';
import { type___modal___config } from '../../types/types.modals';

//	F U N C S

import { funcs_text } from '../../funcs/funcs.text';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComSpinnerInlineComponent from '../../components/com-spinner-inline/com-spinner-inline';
import ComSpinnerTagComponent from '../../components/com-spinner-tag/com-spinner-tag';

//	C L A S S

export default class MdDigitalMenuDishEditModal extends React.Component<props___MdDigitalMenuDishEditModal, state___MdDigitalMenuDishEditModal>
{

//#region 																							C O N F I G

	private readonly MdDigitalMenuDishEditModal___modal_config: type___modal___config = {
		breakpoints: [ 1 ],
		backdropDismiss: true,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent(); },
		onDidDismiss: () => { this.event___onDidDismiss(); }
	};

//#endregion

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();

	private readonly funcs___text: funcs_text = new funcs_text();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdDigitalMenuDishEditModal,
	) {
		super(props);
		this.state = state___MdDigitalMenuDishEditModal___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___dish = async () => {
		this.setState({

			dish___props: null,
			dish___props___is_loading: false,
		});
	}

	private read_props___dish_allergens = async () => {
		const ___temp___dish_categories_allergens: type___api___response = await this._API.digitalmenus_dishes_allergens___read___multi('alphabetical');
		this.setState({
			allergens_collections: ___temp___dish_categories_allergens.data,
			allergens_collections___is_loading: false,
		})
	}

	private read_props___dish_categories = async () => {
		const ___temp___dish_categories_collection: type___api___response = await this._API.digitalmenus_dishes_categories___read___multi('alphabetical');
		this.setState({
			categories_collections: ___temp___dish_categories_collection.data,
			categories_collections___is_loading: false,
		})
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___select___category = (___event: any) => {
		this.setState({
			field___dish___category: ___event.detail.value,
		});
	}

	private handle___select___measurement_unit = (___event: any) => {
		this.setState({
			field___dish___measurement_unit: ___event.detail.value as type___digitalmenu___dish_measurement_unit,
		});
	}

	private handle___select___allergen = (___allergen_id: string) => {
		let ___category_collection___temp: string[] = this.state.field___dish___allergens;
		switch (this.state.field___dish___allergens.includes(___allergen_id)) {
			case true: ___category_collection___temp = ___category_collection___temp.filter(___c => ___c !== ___allergen_id); break;
			case false: ___category_collection___temp.push(___allergen_id); break;
		}
		this.setState({
			field___dish___allergens: ___category_collection___temp,
		});
	}

	private handle___submit = () => {
		this.setState({
			dish___props___is_submitting: true,
		}, async () => {
			const ___temp___dish_is_submitted: type___api___response = await this._API.digitalmenus_dishes___submit(this.props.dish_digitalmenu, this.props.dish_id, this.state.field___dish___category, this.state.field___dish___name, this.state.field___dish___description, this.state.field___dish___price, this.state.field___dish___price_currency, this.state.field___dish___measurement_unit, this.state.field___dish___allergens);
			this.setState({
				ionToast___dishSubmit___isSubmitted: (___temp___dish_is_submitted && ___temp___dish_is_submitted.response === 'success') ? true : false,
			}, () => {
				if (___temp___dish_is_submitted.response === 'success') {
					this.props.event___onDidDismiss();
				}
			});
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	private async event___onDidPresent() : Promise<void> {
		await this.read_props___dish_allergens();
		await this.read_props___dish_categories();
		this.setState({
			dish___id: this.props.dish_id,
			field___dish___category: this.props.dish_category,
		}, async () => {
			if (this.props.dish_id !== null) {
				await this.read_props___dish();
			} else {
				this.setState({
					dish___props___is_loading: false,
				});
			}
		});
	}

	private event___onDidDismiss() : void
	{
		this.setState({
			...state___MdDigitalMenuDishEditModal___default
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>

			<IonToast isOpen={( this.state.ionToast___dishSubmit___isSubmitted === true )} message="Prodotto aggiunto" duration={ 3000 } color="success" position="top" onDidDismiss={() => { this.setState({ ionToast___dishSubmit___isSubmitted: null }); }}/>
			<IonToast isOpen={( this.state.ionToast___dishSubmit___isSubmitted === false )} message="Errore nell'aggiunta del prodotto" duration={ 3000 } color="danger" position="top" onDidDismiss={() => { this.setState({ ionToast___dishSubmit___isSubmitted: null }); }}/>

			<IonModal isOpen={ this.props.isOpen } {...this.MdDigitalMenuDishEditModal___modal_config}>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="end">
							<IonButton onClick={ this.props.event___onDidDismiss }>
								<b>chiudi</b>
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>
					{(this.state.dish___props___is_loading === true || this.state.dish___props___is_submitting === true) ? <>

						<br />
						<br />
						<br />
						<br />
						<ComSpinnerInlineComponent/>
					
					</> : <>

						{/**
						 * 
						 * 
						 * 
						 * 						F O T O   E   I N F O R M A Z I O N I
						 * 
						 * 
						 * 
						 */}

						<IonListHeader>
							<IonLabel>
								<i className="fas fa-info-circle"></i>
								<span>Informazioni prodotto</span>
							</IonLabel>
						</IonListHeader>
					
						<section className="md-modal---container md-digitalmenu-dish-edit---container">
							<IonCard>
								<IonList>
									<IonItem className="is-ion-item-button">
										{(this.state.categories_collections___is_loading === true) ? <>
											<ComSpinnerTagComponent />
										</> : <>
											<IonSelect key="munit___" interface="popover" label="Seleziona categoria" placeholder="Nessuna" value={ this.state.field___dish___category } onIonChange={ this.handle___select___category }>
												<IonSelectOption disabled={ true } key="___dskad" value="">Seleziona Categoria</IonSelectOption>
												{this.state.categories_collections.map((___dc: any, ___dc___idx: number) => {
													return <>
														<IonSelectOption key={ ___dc___idx['dish_category_id'] } value={ ___dc['dish_category_id'] }>{ ___dc['dish_category_name'] }</IonSelectOption>
													</>;
												})}
											</IonSelect>
										</>}
									</IonItem>
								</IonList>
							</IonCard>

							<br />

							<label htmlFor="field---poi-name">
								<p className="input---nb-label is-required">nome prodotto</p>
								<input type="text" id="field---poi-name" className="input---nb-outlined" placeholder="Nome del prodotto" value={ this.state.field___dish___name } onInput={(___e: any) => { this.setState({ field___dish___name: ___e.target.value}); }}/>
							</label>

							<br />

							<label htmlFor="field---poi-description">
								<p className="input---nb-label">descrizione o ingredienti</p>
								<textarea id="field---poi-description" className="input---nb-outlined" placeholder="descrizione o ingredienti" style={{height:'150pt'}} value={ this.state.field___dish___description } onInput={(___e: any) => { this.setState({ field___dish___description: this.funcs___text.text_input___process(___e.target.value) }); }}></textarea>
							</label>

							<br />

							<label htmlFor="field---poi-price">
								<p className="input---nb-label is-required">prezzo</p>
								<section style={{display:'flex',gap:'10pt'}}>
									<input type="number" id="field---poi-price" className="input---nb-outlined" placeholder="0.00" min={ 0 } value={ this.state.field___dish___price } onInput={(___e: any) => { this.setState({ field___dish___price: ___e.target.value }); }} style={{textAlign:'right'}}/>
									<p style={{fontWeight:'bold'}}>€</p>
								</section>
							</label>

							<br />

							<IonCard>
								<IonList>
									<IonItem>
										<IonSelect key="munit___" interface="popover" label="Seleziona unità di misura" placeholder="Nessuna" value={ this.state.field___dish___measurement_unit } onIonChange={ this.handle___select___measurement_unit }>
											<IonSelectOption key="munit___null" value="">Nessuna</IonSelectOption>
											<IonSelectOption key="munit___kg" value="kg">al Kilo</IonSelectOption>
											<IonSelectOption key="munit___l" value="l">al Litro</IonSelectOption>
											<IonSelectOption key="munit___g" value="g">al Grammo</IonSelectOption>
										</IonSelect>
									</IonItem>
								</IonList>
							</IonCard>

							<br />

							<p className="input---nb-label">allergeni</p>
							{(this.state.allergens_collections___is_loading === true) ? <>
								<ComSpinnerInlineComponent />
							</> : <>
								<section className="md-digitalmenu-dish-edit---allergens">
									{this.state.allergens_collections.map((___allergen: any, ___allergen___idx: number) => {
										const ___c_e___child___checkbox___id: string = 'md-entry-interests----cb-cat-event-child---' + ___allergen['dish_allergen_id'];
										return <>
											<input key={ ___allergen___idx + 1900 } type="checkbox" id={ ___c_e___child___checkbox___id } value={ ___allergen['dish_allergen_id'] } checked={ this.state.field___dish___allergens.includes(___allergen['dish_allergen_id']) } onInput={() => { this.handle___select___allergen(___allergen['dish_allergen_id']); }}/>
											<label key={ ___allergen___idx + 1100 } htmlFor={ ___c_e___child___checkbox___id } style={{borderColor:'var(--color---folly)',backgroundColor:'var(--color---folly)'}}>
												<i className="fas fa-check"></i>
												<i className="far fa-circle"></i>
												<span>{ ___allergen['dish_allergen_name'] }</span>
											</label>
										</>;
									})}
								</section>
							</>}

							<br />
							<br />

						</section>
		
					</>}
				</IonContent>

				<IonFooter>
					<section className="container---footer-section">
						{(this.state.field___dish___category !== null && this.state.field___dish___category !== '' && this.state.field___dish___name.length > 0) ? <>
							<button className="is-element is-button is-color---folly is-full-width" onClick={() => { this.handle___submit() }}>
								<i className="fas fa-check"></i>
								<h4>Salva</h4>
							</button>
						</> : <>
							<button className="is-element is-button is-color---shadow is-full-width">
								<i></i>
								<h4>Compila tutti i campi</h4>
							</button>
						</>}
					</section>
				</IonFooter>
				
			</IonModal>
		</>;
	}

//#endregion

}