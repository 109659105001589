import React from 'react'
import { Link } from 'react-router-dom';

//	S T Y L E

import './com-text-rich.scss'

//	S T A T E S   -   P R O P S

import { props___ComTextRichComponent, state___ComTextRichComponent, state___ComTextRichComponent___default } from './com-text-rich.state';

//	L I B S

import { lib_names_shared } from '../../libs/lib.names.shared';

//	F U N C S

import { funcs_text } from '../../funcs/funcs.text';

//	C L A S S

export default class ComTextRichComponent extends React.Component<props___ComTextRichComponent, state___ComTextRichComponent>
{

//#region 																							D E C L A R A T I O N S

	private readonly funcs___text: funcs_text = new funcs_text();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComTextRichComponent,
	)
	{
		super(props);
		this.state = state___ComTextRichComponent___default;
	}

//#endregion

//#region 																							P R O C E S S O R S

	private processor___text___split = () => {
		this.setState({
			text___words: this.props.text.split(lib_names_shared.regex.text___whitespace)
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void> {
		this.processor___text___split();
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<p className="com-text-rich-component---container">
				{this.state.text___words.map((___w: string, ___w___idx: number) => { return <>
					{(() => { switch (true) {
						case lib_names_shared.regex.text___hashtag.test(___w): return <>
							<Link className="text---is-hashtag" key={ ___w___idx } to={ '/discover/hashtag/' + this.funcs___text.text___process___only_letters(___w) }>{ ___w }</Link>
						</>; break;
						case lib_names_shared.regex.text___usertag.test(___w): return <>
							<Link className="text---is-user" key={ ___w___idx } to={ '/view/user/' + this.funcs___text.text___process___only_letters(___w) }>{ ___w }</Link>
						</>; break;
						case lib_names_shared.regex.text___url.test(___w): return <>
							<Link className="text---is-url" key={ ___w___idx } to={ ___w } target="_blank">{ ___w }</Link> 
						</>; break;
						default: return <>
							<span className="text---is-normal" key={ ___w___idx }>{ ___w }</span>
						</>; break;
					}})()}
					<> </>
				</>})}
			</p>
		</>;
	}

//#endregion

}