import { interface___modal } from "../../interfaces/interface.modals";

//	P R O P S

export interface props___MdEventSelectCategoryModal extends interface___modal {

}

//	S T A T E

export interface state___MdEventSelectCategoryModal {

	event_category___collection: any[],
	event_category___collection___is_loading: boolean,
	event_category___selected: any | null,

}

//	S T A T E   D E F A U L T

export const state___MdEventSelectCategoryModal___default: state___MdEventSelectCategoryModal = {

	event_category___collection: [],
	event_category___collection___is_loading: true,
	event_category___selected: null,

}