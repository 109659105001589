import React from 'react'

//	S T Y L E

import './com-tale-preview.scss'

//	S T A T E S   -   P R O P S

import { props___ComTalePreviewComponent } from './com-tale-preview.state';

//	C L A S S

export default class ComTalePreviewComponent extends React.Component<props___ComTalePreviewComponent, {}>
{

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<section className="com-tale-preview---component">
				<img src={ this.props.tale_props['tale_media'] } />
			</section>
		</>;
	}

//#endregion

}