import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComButtonShareComponent: type___locale___translations___collection = {
	'share': {
		de_de: 'Aktie',
		en_us: 'Share',
		es_es: 'Compartir',
		fr_fr: 'Partager',
		it_it: 'Condividi',
	},
};