//	P R O P S

export interface props___ComPasswordSafetyComponent {

	password: string,

	event___onProcess: (_: boolean) => void,

}

//	S T A T E

export interface state___ComPasswordSafetyComponent {

	password_strength: number,

}

//	S T A T E   D E F A U L T

export const state___ComPasswordSafetyComponent___default: state___ComPasswordSafetyComponent = {

	password_strength: 0,

}