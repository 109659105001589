//	L O C A L I Z A T I O N S

import { locale___funcs_geolocation } from "./funcs.all.locale";

//	T Y P E S

import { type___geo___lngLat } from "../types/types.types";

//	L I B S

import { lib_names } from "../libs/lib.names";

//	S E R V I C E S

import { service_LocalizationService } from "../services/service-localization";

//	E X P O R T   F U N C S

export class funcs_geolocation
{

//#region 																							N U M B E R S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							G E T   A D D R E S S   F R O M   C O O R D S

	async geolocation___get_address___from_coords(___coords: type___geo___lngLat) : Promise<string>
	{
		const ___s_route: string = 'https://api.mapbox.com/geocoding/v5/mapbox.places/' + ___coords.lng.toString() + ',' + ___coords.lat.toString() + '.json?language=it&access_token=' + lib_names.mapboxgl.accessToken_geocoding;
		const ___s_response: Response = await fetch(___s_route, { method: 'GET' });
		if (___s_response.ok)
		{
			const ___server_response: any = await ___s_response.json();
			const ___server_response___parsed: string = (___server_response['features'].length > 0) ? ___server_response['features'][0]['place_name_it'] : null;
			return ___server_response___parsed;
		}
		else
		{
			return this._LOCALE.translate(locale___funcs_geolocation.address_not_availble);
		//	try {
		//		const ___error_details: any = await ___s_response.json();
		//		return ___error_details.message;
		//	} catch (___e: any) { return ___e; }
		}
	}

//#endregion

}