import React from 'react';
import { IonAlert, IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonLabel, IonListHeader, IonModal, IonSelect, IonSelectOption, IonToast, IonToolbar } from '@ionic/react';

//	S T Y L E

import './md-posts-create.scss';

//	S T A T E   -   P R O P S

import { props___MdPostsCreateModal, state___MdPostsCreateModal, state___MdPostsCreateModal___default } from './md-posts-create.state';

//	I N T E R F A C E S

import { interface___IonAlert } from '../../interfaces/interface.alert';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';
import { type___modal___config } from '../../types/types.modals';

//	F U N C S

import { funcs_text } from '../../funcs/funcs.text';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComSpinnerInlineComponent from '../../components/com-spinner-inline/com-spinner-inline';

//	C L A S S

export default class MdPostsCreateModal extends React.Component<props___MdPostsCreateModal, state___MdPostsCreateModal>
{

//#region 																							C O N F I G

	private readonly MdPostsCreateModal___modal_config: type___modal___config = {
		breakpoints: [ 1 ],
		backdropDismiss: true,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent(); },
		onDidDismiss: () => { this.event___onDidDismiss(); }
	};

//#endregion

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();

	private readonly funcs___text: funcs_text = new funcs_text();

//#endregion

//#region 																							I N T E R F A C E S

	private readonly ionAlert___ask_close___props: interface___IonAlert = {
		header: 'Attenzione',
		message: 'Vuoi eliminare le modifiche a questo Post?',
		buttons: [
			{
				text: 'Si',
				handler: () => {
					this.props.event___onDidDismiss();
				}
			},
			{
				text: 'Annulla',
				role: 'cancel',
				handler: () => {
					this.setState({
						ionAlert___askForClose___visibility: false
					});
				}
			}
		]
	};

//#endregion


//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdPostsCreateModal,
	)
	{
		super(props);
		this.state = state___MdPostsCreateModal___default;
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___post___submit = () => {
		this.setState({
			post___is_submitting: true,
		}, async () => {
			const ___post___submit: type___api___response = await this._API.posts___submit(this.props.post_target___type, this.props.post_target___id, this.state.post___text, this.state.post___visibility);
			if (___post___submit.response === 'error') {
				this.setState({
					ionToast___error___isOpen: true, 
					post___is_submitting: false,
				});
			} else {
				this.props.event___onDidDismiss();
			}
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	private event___onDidPresent() : void
	{
		this.setState({
			...state___MdPostsCreateModal___default
		});
	}

	private event___onDidDismiss() : void
	{
		this.setState({
			...state___MdPostsCreateModal___default
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonModal isOpen={ this.props.isOpen } {...this.MdPostsCreateModal___modal_config}>

				<IonAlert isOpen={ this.state.ionAlert___askForClose___visibility } {...this.ionAlert___ask_close___props}></IonAlert>

				<IonToast isOpen={ this.state.ionToast___error___isOpen} message="Errore nella pubblicazione della tale, riprova...." onDidDismiss={() => { this.setState({ ionToast___error___isOpen: false }); }} position="top" />

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="end">
							<IonButton onClick={() => { this.setState({ ionAlert___askForClose___visibility: true }); }}>
								<b>Annulla</b>
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					<br />

					<IonListHeader>
						<IonLabel>Nuovo Post</IonLabel>
					</IonListHeader>

					{(this.state.post___is_submitting === true) ? <>

						<br />
						<br />
						<br />
						<br />
						<ComSpinnerInlineComponent/>
					
					</> : <>
					
						<section className="md-modal---container md-posts-create---container">
							{(this.props.post_target___type && this.props.post_target___name && this.props.post_target___id && this.props.is_logged && this.props.is_logged___props) ? <>
								
								{(() => { switch (this.props.post_target___type) {
									case 'poi': return <>
										<h6>stai pubblicando come { this.props.post_target___name }</h6>
									</>; break;
									case 'user': return <>
										{(this.props.post_target___id === null || this.props.is_logged___props['user_id'] === this.props.post_target___id) ? <>
											<h6>stai pubblicando sul tuo profilo</h6>
										</> : <>
											<h6>stai pubblicando sul profilo di <b>{ this.props.post_target___name }</b></h6>
										</>}
									</>; break;
									case 'self': return <>
										<h6>stai pubblicando sul tuo profilo</h6>
									</>; break;
								}})()}
								
								<br/>

								<textarea value={ this.state.post___text } className="input---nb-outlined" placeholder="Scrivi qualcosa" onInput={(___e: any) => { this.setState({ post___text: this.funcs___text.text_input___process(___e.target.value) }); }} ></textarea>
								
								<br />

								<IonSelect key="visibility" interface="popover" label="Visibilità Post" placeholder="Pubblico" value={ this.state.post___visibility } onIonChange={(___e: any) => { this.setState({ post___visibility: ___e.detail.value }); }}>
									<IonSelectOption key="visibility___public" value="public">Pubblico</IonSelectOption>
									<IonSelectOption key="visibility___private" value="private">Solo Follower</IonSelectOption>
								</IonSelect>

							</> : <>
								
								qualcosa è andato storto
							
							</>}

						
							{/*

							<ion-card (click)="modal___select_position();" *ngIf="this.state.post___coords !== null" className="post---position">
								<com-map-preview [___input___coords___lng]="this.state.post___coords[0]" [___input___coords___lat]="this.state.post___coords[1]" [___input___should___route]="false"></com-map-preview>
							</ion-card>

							<section *ngIf="this.state.post___share___type === null" className="post---attributes">
								<ion-chip *ngIf="this.state.post___coords === null" (click)="modal___select_position();" color="danger"><i className="fas fa-map-marker-alt"></i><span>Posizione</span></ion-chip>
								<ion-chip *ngIf="!(this.state.post___coords === null)" (click)="modal___select_position('remove');"><i className="fas fa-times"></i><span>Posizione</span></ion-chip>
							</section>

							<br>
							<br>

							<ion-item lines="none">
								<ion-toggle [(ngModel)]="this.state.post___public">Rendi il post pubblico</ion-toggle>
							</ion-item>

							<br>
							<br>

							<div className="global-custom---button" *ngIf="this.state.post___text.length > 0" (click)="post___submit();">
								<i className="fas fa-check"></i>
								<span>Salva</span>
							</div>
							<div className="global-custom---button" *ngIf="!(this.state.post___text.length > 0)" style="opacity:0.5;">
								<i className="fas fa-check"></i>
								<span>Salva</span>
							</div>

						*/}

						</section>

						<br />

					</>}

				</IonContent>

				<IonFooter>
					<section className="container---footer-section">
						<button className="is-element is-button is-color---folly is-full-width" onClick={ this.handle___post___submit }>
							<i className="fa-solid fa-check"></i>
							<h4>Pubblica</h4>
						</button>
					</section>
				</IonFooter>

			</IonModal>
		</>;
	}

//#endregion

}