import { RouteComponentProps } from "react-router";
import { interface___is_logged } from "../../../interfaces/interface.logged";

//	P R O P S

export interface props___SbDiscoverHashtagPage extends RouteComponentProps<{ id: string; }>, interface___is_logged {

}

//	S T A T E

export interface state___SbDiscoverHashtagPage {

	hashtag___string: string,

	hashtag___posts: any[],
	hashtag___posts___is_loading: boolean,
	hashtag___posts___is_complete: boolean,

}

//	S T A T E   D E F A U L T

export const state___SbDiscoverHashtagPage___default: state___SbDiscoverHashtagPage = {

	hashtag___string: '',
	hashtag___posts: [],
	hashtag___posts___is_loading: true,
	hashtag___posts___is_complete: false,

}