import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComEventInviteComponent: type___locale___translations___collection = {
	'you_re_invited': {
		de_de: 'Du hast eine Einladung!',
		en_us: 'You have an invitation!',
		es_es: '¡Tienes una invitación!',
		fr_fr: 'Vous avez une invitation!',
		it_it: 'Hai un invito!',
	},
	'you_been_invited_date': {
		de_de: 'Sie wurden am {{1}}',
		en_us: 'You were invited on the {{1}}',
		es_es: 'Te invitaron el día {{1}}.',
		fr_fr: 'Vous avez été invité le {{1}}',
		it_it: 'Sei stato invitato il {{1}}',
	},
	'from_who': {
		de_de: 'von',
		en_us: 'from',
		es_es: 'de',
		fr_fr: 'de',
		it_it: 'da',
	},
};