import { interface___is_logged } from "../../../interfaces/interface.logged";
import { interface___has_notifications } from "../../../interfaces/interface.notifications";

//	P R O P S

export interface props___SbArchivePage extends interface___is_logged, interface___has_notifications {

}

//	S T A T E

export interface state___SbArchivePage {

	conversations___collection: any[],
	conversations___collection___is_loading: boolean,

	notifications___collection: any[],
	notifications___collection___is_loading: boolean,

}

//	S T A T E   D E F A U L T

export const state___SbArchivePage___default: state___SbArchivePage = {

	conversations___collection: [],
	conversations___collection___is_loading: true,

	notifications___collection: [],
	notifications___collection___is_loading: true,

}