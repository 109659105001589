import { RouteComponentProps } from "react-router";
import { type___geo___lngLat } from "../../../types/types.types";
import { interface___is_logged } from "../../../interfaces/interface.logged";

//	P R O P S

export interface props___SbEventEditPage extends RouteComponentProps<{ id: string; }>, interface___is_logged {

}

//	S T A T E

export interface state___SbEventEditPage {

	collection___event_attributes: any[],
	collection___event_attributes___is_loading: boolean,

	event___id: string | 'new',
	event___props___is_loading: boolean,

	field___event_category: any | null,
	field___event_poster: string | null,
	field___event_name: string,
	field___event_description: string,

	field___event_datetime___begin: string,
	field___event_datetime___end: string,
	
	field___event_contact_phone: string,
	field___event_contact_email: string,

	field___event_coords: type___geo___lngLat | null,
	field___event_poi: any | null,

	field___event_user_public: boolean,

	submitting___is_loading: boolean,

	ionAlert___askForClose___isVisible: boolean,

	ionModal___MdMediaEditorModal___isOpen: boolean,
	ionModal___MdEventSelectCategoryModal___isOpen: boolean,
	ionModal___MdPoiSelectModal___isOpen: boolean,
	ionModal___MdPositionSelectorModal___isOpen: boolean,

	ionToast___isOpen: boolean,
	ionToast___message: string,
	ionToast___type: 'success' | 'danger',

}

//	S T A T E   D E F A U L T

export const state___SbEventEditPage___default: state___SbEventEditPage = {

	collection___event_attributes: [],
	collection___event_attributes___is_loading: true,

	event___id: '',
	event___props___is_loading: true,

	field___event_category: null,
	field___event_poster: null,
	field___event_name: '',
	field___event_description: '',

	field___event_datetime___begin: undefined,
	field___event_datetime___end: undefined,

	field___event_contact_phone: '',
	field___event_contact_email: '',

	field___event_coords: null,
	field___event_poi: null,

	field___event_user_public: true,

	submitting___is_loading: false,

	ionAlert___askForClose___isVisible: false,

	ionModal___MdMediaEditorModal___isOpen: false,
	ionModal___MdEventSelectCategoryModal___isOpen: false,
	ionModal___MdPoiSelectModal___isOpen: false,
	ionModal___MdPositionSelectorModal___isOpen: false,

	ionToast___isOpen: false,
	ionToast___message: '',
	ionToast___type: 'success',

}