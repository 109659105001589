/* 
 * 
 * 
 * 
 * 								L I B   I C O N S
 * 
 * 
 * 
 */

export const lib_icons : { [ key: string ]: string[] } = {
	events: [
		'carmeetup', 'concert', 'conference',
		'demonstration',
		'festival',
		'inauguration',
		'party',
	],
	poi: [
		'airport', 'archeology', 
		'bank', 'bakery', 'barber', 'beach', 'beautician', 'bikeshop', 'bnb', 'busstation',
		'camperrent', 'camping', 'car', 'carbodyshop', 'cardealer', 'carpark', 'carrent', 'carrepair', 'carwash', 'casino', 'cave', 'chargingstation', 'cinema', 'clothingstore', 'concert', 'craftshop', 'culture',
		'defibrillator', 'disco',
		'education', 'electronicshop', 'emergency',
		'farmhouse', 'fastfood', 'firefighter', 'firstaid', 'food', 'freetime', 'fuel',
		'grocery', 'gym',
		'h24', 'harwarestore', 'hospital', 'hospitality', 'hotel', 'house',
		'icecreamshop',
		'jewelry',
		'library',
		'manifacture', 'medicaloffice', 'monument', 'museum',
		'nature',
		'office', 'opengym',
		'panorama', 'park', 'party', 'personalservices', 'pharmacy', 'pizzeria', 'playroom', 'police', 'port', 'postoffice', 'printingservice', 'professional',
		'resort', 'restaurant',
		'services', 'shop', 'spa', 'sparepart', 'sport', 'sportsshop', 'stadium', 'subway', 'supermarket', 'swimmingpool',
		'takeaway', 'taxi', 'theatre', 'tiredealer', 'tobaccoshop', 'townhall', 'trainstation', 'track', 'transports',
		'urbex',
		'venue',
		'waterfall', 'wave', 'worship',
	],
	tales: [
		'generic',
	]
}