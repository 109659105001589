import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComPoiComponent: type___locale___translations___collection = {
	'far_from_you': {
		de_de: '{{1}} weit von dir entfernt',
		en_us: '{{1}} far from you',
		es_es: '{{1}} de ti',
		fr_fr: 'éloigné de toi {{1}}',
		it_it: 'distante da te {{1}}',
	},
	'far_from_you___loading': {
		de_de: 'berechnung der entfernung...',
		en_us: 'calculating the distance...',
		es_es: 'calculando la distancia...',
		fr_fr: 'calculer la distance...',
		it_it: 'calcolando la distanza...',
	},
	'is_open': {
		de_de: 'Es ist jetzt geöffnet!',
		en_us: 'It\'s open now!',
		es_es: '¡Ya está abierto!',
		fr_fr: 'C\'est ouvert maintenant!',
		it_it: 'Adesso è aperto!',
	},
	'is_closed': {
		de_de: 'Es ist jetzt geschlossen',
		en_us: 'It\'s closed now',
		es_es: 'Esta cerrado ahora',
		fr_fr: 'C\'est fermé maintenant',
		it_it: 'Adesso è chiuso',
	},
};