import { type___locale___translations___collection } from "../../../types/types.locale";

//	E X P O R T   L O C A L E

export const locale___SbElementInvite: type___locale___translations___collection = {
	'invite_received': {
		de_de: '______________________',
		en_us: '______________________',
		es_es: '______________________',
		fr_fr: '______________________',
		it_it: 'Hai ricevuto un invito ad un evento nelle vicinanze in base ai tuoi interessi',
	},
	'who_invited': {
		de_de: '______________________',
		en_us: '______________________',
		es_es: '______________________',
		fr_fr: '______________________',
		it_it: 'Ti ha invitato',
	},
	'go_to_event': {
		de_de: '______________________',
		en_us: '______________________',
		es_es: '______________________',
		fr_fr: '______________________',
		it_it: 'Vai all\'evento',
	},
};