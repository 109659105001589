import React from 'react'

//	S T Y L E

import './com-tale-create.scss'

//	S T A T E S   -   P R O P S

import { props___ComTaleCreateComponent, state___ComTaleCreateComponent, state___ComTaleCreateComponent___default } from './com-tale-create.state';

//	M O D A L S

import MdTalesCreateModal from '../../modals/md-tales-create/md-tales-create';

//	C L A S S

export default class ComTaleCreateComponent extends React.Component<props___ComTaleCreateComponent, state___ComTaleCreateComponent>
{

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComTaleCreateComponent,
	)
	{
		super(props);
		this.state = state___ComTaleCreateComponent___default;
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___tale_creation_modal___is_triggered = () => {
		this.setState({
			ionModal___MdTalesCreateModal___isOpen: true,
		});
	}

	private handle___tale_creation_modal___is_dismissed = () => {
		this.setState({
			ionModal___MdTalesCreateModal___isOpen: false,
		}, () => {
			if (this.props.event___onTaleCreationDismiss) {
				this.props.event___onTaleCreationDismiss(true);
			}
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount(): void {
		this.setState({
			...state___ComTaleCreateComponent___default
		});
	}

	componentWillUnmount(): void {
		this.setState({
			...state___ComTaleCreateComponent___default
		});
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>

			<MdTalesCreateModal isOpen={ this.state.ionModal___MdTalesCreateModal___isOpen } event___onDidDismiss={ this.handle___tale_creation_modal___is_dismissed }/>

			<div className="com-tale-create---component" onClick={ this.handle___tale_creation_modal___is_triggered }>
				<i className="fa-solid fa-plus"></i>
			</div>
		
		</>;
	}

//#endregion

}