import React from 'react';
import { IonContent, IonPage, withIonLifeCycle } from '@ionic/react';

//	L O C A L I Z A T I O N

import { locale___SbLandingGeolocationPage } from './sb-landing-geolocation.locale';

//	S T Y L E

import './sb-landing-geolocation.scss';

//	T Y P E S

import { type___geo___lngLat } from '../../../types/types.types';

//	S T A T E   -   P R O P S

import { props___SbLandingGeolocationPage } from './sb-landing-geolocation.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';
import { service_GeolocationService } from '../../../services/service-geolocation';

//	C L A S S

class SbLandingGeolocationPage extends React.Component<props___SbLandingGeolocationPage, {}>
{

//#region 																							D E C L A R A T I O N S
	
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _GEOLOCATION: service_GeolocationService = new service_GeolocationService();

//#endregion

//#region 																							H A N D L E R S

	private handle___geolocation___ask = async () => {
		await this._GEOLOCATION.geolocation___locate();
		this.props.event___onGeolocationAvailable();
	}

//#endregion

//#region 																							H A N D L E R S

	ionViewDidEnter() : void {
		this.handle___geolocation___ask();
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>
				<IonContent forceOverscroll={ false }>
					<section className="sb-landing-geolocation---container">

						<img src="/assets/logo.horizontal.svg"/>

						<br />
						<br />
				
						<h4>{ this._LOCALE.translate(locale___SbLandingGeolocationPage.title) }</h4>
						
						<div>
							<span>{ this._LOCALE.translate(locale___SbLandingGeolocationPage.description_1) }</span>
							<span>{ this._LOCALE.translate(locale___SbLandingGeolocationPage.description_2) }</span>
							<a target="_blank" href="https://www.nearby.ws/info/eula">
								{ this._LOCALE.translate(locale___SbLandingGeolocationPage.more_info) }
							</a>
						</div>
					
						<br />

						<section>
							<section className="container---footer-section" style={{flexDirection:'column'}}>
								<button className="is-element is-button is-color---folly is-full-width" onClick={ this.handle___geolocation___ask }>
									<i className="fas fa-location-arrow"></i>
									<h4>{ this._LOCALE.translate(locale___SbLandingGeolocationPage.button___ask_for) }</h4>
								</button>
							</section>
						</section>

					</section>
				</IonContent>
			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbLandingGeolocationPage);