import { interface___modal } from "../../interfaces/interface.modals";

//	P R O P S

export interface props___MdPoiSelectCategoryModal extends interface___modal {

}

//	S T A T E

export interface state___MdPoiSelectCategoryModal {

	poi_category___primary___collection: any[],
	poi_category___primary___collection___is_loading: boolean,
	poi_category___primary___selected: any | null,

	poi_category___secondary___collection: any[],
	poi_category___secondary___collection___is_loading: boolean,
	poi_category___secondary___selected: boolean,

}

//	S T A T E   D E F A U L T

export const state___MdPoiSelectCategoryModal___default: state___MdPoiSelectCategoryModal = {

	poi_category___primary___collection: [],
	poi_category___primary___collection___is_loading: true,
	poi_category___primary___selected: null,

	poi_category___secondary___collection: [],
	poi_category___secondary___collection___is_loading: true,
	poi_category___secondary___selected: null,

}