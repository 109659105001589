import { type___locale___translations___collection } from "../../../types/types.locale";

//	E X P O R T   L O C A L E

export const locale___SbEventsCategoryViewPage: type___locale___translations___collection = {
	'category_event': {
		de_de: 'Veranstaltungen',
		en_us: 'Events',
		es_es: 'Eventos',
		fr_fr: 'Événements',
		it_it: 'Eventi',
	},
	'category_event___all': {
		de_de: 'Alle Veranstaltungen',
		en_us: 'All Events',
		es_es: 'Todos Los Eventos',
		fr_fr: 'Tous Les Événements',
		it_it: 'Tutti gli Eventi',
	},
};