import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonRefresher, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';

//	L O C A L I Z A T I O N

import { locale___App } from '../../../App.locale';

//	S T Y L E

import './sb-poi-view-feed.scss';

//	S T A T E   -   P R O P S

import { props___SbPoiViewFeedPage, state___SbPoiViewFeedPage, state___SbPoiViewFeedPage___default } from './sb-poi-view-feed.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';
import { service_RestApiService } from '../../../services/service-api';
import { service_Router } from '../../../services/service-router';

//	C O M P O N E N T S

import Com404Component from '../../../components/com-404/com-404';
import ComInfiniteScrollerComponent from '../../../components/com-infinite-scroller/com-infinite-scroller';
import ComListEmptyComponent from '../../../components/com-list-empty/com-list-empty';
import ComPoiComponent from '../../../components/com-poi/com-poi';
import ComPostCreateComponent from '../../../components/com-post-create/com-post-create';
import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';
import ComSpinnerInlineComponent from '../../../components/com-spinner-inline/com-spinner-inline';
import ComPostComponent from '../../../components/com-post/com-post';

//	C L A S S

class SbPoiViewFeedPage extends React.Component<props___SbPoiViewFeedPage, state___SbPoiViewFeedPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _ROUTER: service_Router = new service_Router();
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbPoiViewFeedPage,
	) {
		super(props);
		this.state = state___SbPoiViewFeedPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___poi = async () => {
		const ___temp___poi___props: type___api___response = await this._API.poi___read___single(this.state.poi___id);
		if (!___temp___poi___props || ___temp___poi___props.response !== 'success' || ___temp___poi___props.data === null) { console.log('404'); }
		this.setState({
			poi___props: ___temp___poi___props.data,
			poi___props___is_loading: false,
		}, async () => {
			await this.read_props___poi_feed();
		});
	}

	read_props___poi_feed = async () => {
		const ___temp___poi_feed: type___api___response = await this._API.posts___read___multi('poi', this.state.poi___props['poi_id'], this.state.poi_posts___collection.length);
		this.setState((prevState) => ({
			poi_posts___collection: [...prevState.poi_posts___collection, ...___temp___poi_feed.data],
			poi_posts___collection___is_loading: false,
			poi_posts___collection___is_complete: (___temp___poi_feed.response === 'no-data') ? true : false,
		}));
	}

//#endregion

//#region 																							H A N D L E R S

	handle___page___refresh = (___e: CustomEvent<RefresherEventDetail>) => {
		this.setState({
			poi___props: null,
			poi___props___is_loading: true,
		}, async () => {
			await this.read_props___poi();
			___e.detail.complete();
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		const ___poi_id: string | null = this._ROUTER.get_id(this.props);
		if (___poi_id) {
			this.setState({
				poi___id: ___poi_id,
			}, async () => {
				await this.read_props___poi();
			});
		}
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref={ '/view/poi/' + this.state.poi___id } text={ this._LOCALE.translate(locale___App.element___IonBackButton) }></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>
					{(this.state.poi___props___is_loading === true) ? <>

						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<ComSpinnerComponent size="small"/>

					</> : <>

						<IonRefresher slot="fixed" onIonRefresh={ this.handle___page___refresh }>
							<ComSpinnerInlineComponent/>
						</IonRefresher>

						{(this.state.poi___props !== null) ? <>
							<div className="sb-page---container sb-poi-view-feed---container">

								<ComPoiComponent poi_props={ this.state.poi___props } size='large'/>

								<br />

								{(this.props.is_logged === true && this.props.is_logged___props !== null && this.state.poi___props['poi_is_mine'] && this.state.poi___props['poi_is_mine'] === true) ? <>
									<ComPostCreateComponent is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props } event___onDidCreate={() => {}} post_creation_target={{  target: 'poi', id: this.state.poi___props['poi_id'], name: this.state.poi___props['poi_name'] }}/>
									<br />
								</> : <></>}

								{this.state.poi_posts___collection.map((___p: any, ___p_i: number) => { return <>
									<ComPostComponent post___type="timeline" key={ ___p_i } post___props={ ___p } is_logged={ false } is_logged___props={ this.props.is_logged } post___can_route_comments={ this.props.is_logged___props }/>
								</>})}
								{(this.state.poi_posts___collection___is_complete === true) ? <>
									<ComListEmptyComponent list_items_quantity={ this.state.poi_posts___collection.length }/>
								</> : <>
									<ComInfiniteScrollerComponent is_loading={ this.state.poi_posts___collection___is_loading } event___scrollTriggered={ this.read_props___poi_feed } />
								</>}

							</div>
						</> : <>
							<Com404Component/>
						</>}

					</>}
				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbPoiViewFeedPage);