import { RouteComponentProps } from "react-router";
import { interface___is_logged } from "../../../interfaces/interface.logged";

//	P R O P S

export interface props___SbElementInvitePage extends RouteComponentProps<{ id: string; }>, interface___is_logged {

}

//	S T A T E

export interface state___SbElementInvitePage {

	invite___id: string,

	invite___props: any | null,
	invite___props___is_loading: boolean,

	invite___event___props: any | null,
	invite___event___props___is_loading: boolean, 




}

//	S T A T E   D E F A U L T

export const state___SbElementInvitePage___default: state___SbElementInvitePage = {

	invite___id: '',

	invite___props: null,
	invite___props___is_loading: true,

	invite___event___props: null,
	invite___event___props___is_loading: true, 











}