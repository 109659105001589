//	L I B S

import { lib_names } from '../libs/lib.names';
import { lib_names_shared } from '../libs/lib.names.shared';

//	T Y P E S

import { type___api___response } from '../types/types.api-response';

//	E X P O R T

export class service_RestAuthService
{

//#region 																							D E C L A R A T I O N S

	private _HEADERS: any = { 'Content-Type': 'application/json' };

//#endregion

//#region 																							R E T U R N   S E R V E R   R E S P O N S E

	private async return_server_response(___target: string, ___to_post: any | null) : Promise<any>
	{
		const ___s_route: string = lib_names_shared.endpoints.server_nearby + 'auth/' + ___target;
		const ___s_response: Response = await fetch(___s_route, { method: 'POST', headers: this._HEADERS, body: JSON.stringify(___to_post) });
		if (___s_response.ok) { const ___server_response: any = await ___s_response.json(); return ___server_response; }
		else { try { const ___error_details: any = await ___s_response.json(); return ___error_details.message; } catch (___e: any) { return ___e; }}
	}

//#endregion

//#region 																							C H E C K   I F   I S   L O G G E D

	async auth___checklogged() : Promise<type___api___response>
	{
		const ___to_post___login_token: string | null = localStorage.getItem(lib_names.localStorage.user___logintoken);
		const ___to_post: any = { 'token': ___to_post___login_token };
		return await this.return_server_response('checklogged', ___to_post);
	}

//#endregion

//#region 																							P E R F O R M   L O G I N

	async auth___perform___login(___username: string, ___password: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'username': ___username, 'password': ___password };
		return await this.return_server_response('login', ___to_post);
	}

//#endregion

//#region 																							P E R F O R M   R E G I S T E R

	async auth___perform___register(___username: string, ___email: string, ___password: string, ___born_date: Date) : Promise<type___api___response>
	{
		const ___to_post: any = { 'username': ___username, 'email': ___email, 'password': ___password, 'born_date': ___born_date };
		return await this.return_server_response('register', ___to_post);
	}

//#endregion

//#region 																							P E R F O R M   R E S E T   P A S S W O R D

	async auth___perform___reset_password(___email: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'user_email': ___email };
		return await this.return_server_response('reset_password', ___to_post);
	}

//#endregion

}