//	P R O P S

export interface props___ComTextRichComponent {

	text: string

}

//	S T A T E

export interface state___ComTextRichComponent {

	text___words: string[],

}

//	S T A T E   D E F A U L T

export const state___ComTextRichComponent___default: state___ComTextRichComponent = {

	text___words: [],

}