import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonLabel, RefresherEventDetail, IonListHeader, IonPage, IonRefresher, IonToolbar, withIonLifeCycle, IonButton, IonActionSheet } from '@ionic/react';

//	S T Y L E

import './sb-archive-notifications.scss';

//	S T A T E   -   P R O P S

import { props___SbArchiveNotificationsPage, state___SbArchiveNotificationsPage, state___SbArchiveNotificationsPage___default } from './sb-archive-notifications.state';

//	I N T E R F A C E S

import { interface___IonActionSheet } from '../../../interfaces/interface.actionsheet';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';

//	C O M P O N E N T S

import ComInfiniteScrollerComponent from '../../../components/com-infinite-scroller/com-infinite-scroller';
import ComListSeparatorComponent from '../../../components/com-list-separator/com-list-separator';
import ComListEmptyComponent from '../../../components/com-list-empty/com-list-empty';
import ComNotificationComponent from '../../../components/com-notification/com-notification';
import ComSpinnerInlineComponent from '../../../components/com-spinner-inline/com-spinner-inline';

//	C L A S S

class SbArchiveNotificationsPage extends React.Component<props___SbArchiveNotificationsPage, state___SbArchiveNotificationsPage>
{

//#region 																							C O N F I G S

	private readonly ionActionSheetConfig___viewAllNotifications = () : interface___IonActionSheet => {
		return {
			buttons: [
			{
				text: 'Segna tutto come letto',
				handler: () => { 
					this.setState({
						notifications___count: 0,
						notifications___collection: [],
						notifications___collection___is_loading: true,
						notifications___collection___is_complete: false,
					}, async () => {
						const __notification_read_all___temp: type___api___response = await this._API.notifications___mark_as_read('all', null);
						if (__notification_read_all___temp) {
							await this.read_props___notifications___count();
							await this.read_props___notifications_collection();
						}
					});
				}
			},
			{
				text: 'Annulla',
				role: 'cancel',
			}
		]};
	};

//#endregion

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbArchiveNotificationsPage,
	) {
		super(props);
		this.state = state___SbArchiveNotificationsPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___notifications_collection = async () => {
		const ___temp___notifications_collection: type___api___response = await this._API.notifications___read___multi('all', this.state.notifications___collection.length);
		this.setState((prevState) => ({
			notifications___collection: [...prevState.notifications___collection, ...___temp___notifications_collection.data],
			notifications___collection___is_loading: false,
			notifications___collection___is_complete: (___temp___notifications_collection.response === 'no-data') ? true : false,
		}));
	}

	private read_props___notifications___count = async () => {
		const ___notification_count___temp: type___api___response = await this._API.notifications___count();
		this.setState({
			notifications___count: (___notification_count___temp.response === 'success') ? ___notification_count___temp.data : 0,
		});
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___page___refresh = async (___e: CustomEvent<RefresherEventDetail>) => {
		this.setState({
			notifications___count: 0,
			notifications___collection: [],
			notifications___collection___is_loading: true,
			notifications___collection___is_complete: false,
		}, async () => {
			await this.read_props___notifications___count();
			await this.read_props___notifications_collection();
			___e.detail.complete();
		});
	}

	private handle___page___load_more = () => {
		this.setState({
			notifications___collection___is_loading: true
		}, async () => {
			await this.read_props___notifications_collection();
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		this.setState({
			notifications___count: this.props.notification_count,
		}, async () => {
			await this.read_props___notifications___count();
			await this.read_props___notifications_collection();
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonActionSheet isOpen={ this.state.IonActionSheet___viewAllNotifications___isOpen } { ...(this.ionActionSheetConfig___viewAllNotifications()) } onDidDismiss={() => { this.setState({ IonActionSheet___viewAllNotifications___isOpen: false }); }} />

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/archive" />
						</IonButtons>
						<IonButtons slot="end">
							<IonButton onClick={() => { this.setState({ IonActionSheet___viewAllNotifications___isOpen: true }); }}>
								<i className="fas fa-ellipsis"></i>
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					<IonRefresher slot="fixed" onIonRefresh={ this.handle___page___refresh }>
						<ComSpinnerInlineComponent/>
					</IonRefresher>

					<section className="sb-user-portfolio---container">

						<IonListHeader>
							<IonLabel>
								<i className="fas fa-bell"/>
								<span>
									<span>Le tue Notifiche</span>
									{(this.state.notifications___count && this.state.notifications___count > 0) ? <>
										<span style={{opacity:0.5,paddingLeft:'10pt'}}>({ this.state.notifications___count.toString() })</span>
									</> : <></>}
								</span>
							</IonLabel>
						</IonListHeader>

						<br />

						{this.state.notifications___collection.map((___n: any, ___n___idx: number) => {
							return <>
								<section key={ ___n___idx }>
									<ComNotificationComponent key={ ___n___idx } notification___props={ ___n } />
								</section>
								<ComListSeparatorComponent/>
							</>;
						})}

						{(this.state.notifications___collection___is_complete === true) ? <>
							<ComListEmptyComponent list_items_quantity={ this.state.notifications___collection.length }/>
						</> : <>
							<ComInfiniteScrollerComponent is_loading={ this.state.notifications___collection___is_loading } event___scrollTriggered={ this.handle___page___load_more } />
						</>}

						<br />
						<br />

					</section>

				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbArchiveNotificationsPage);