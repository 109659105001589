import { interface___component___has_destructive_action } from "../../interfaces/interface.components"
import { interface___is_logged } from "../../interfaces/interface.logged"

//	P R O P S

export interface props___ComPostCommentComponent extends interface___is_logged, interface___component___has_destructive_action {

	post_comment___props: any

}

//	S T A T E

export interface state___ComPostCommentComponent {

	post_comment___props: any | null,
	post_comment___props___is_loading: boolean,

	post_comment___is_destroyed: boolean,
	post_comment___is_destroyed___visible: boolean,

	ionActionSheet___postCommentAction___isOpen: boolean,
	ionAlert___postCommentAction___askForClose___isOpen: boolean,
	ionToast___postCommentAction___isDeleted: boolean | null,

}

//	S T A T E   D E F A U L T

export const state___ComPostCommentComponent___default: state___ComPostCommentComponent = {

	post_comment___props: null,
	post_comment___props___is_loading: true,

	post_comment___is_destroyed: false,
	post_comment___is_destroyed___visible: true,

	ionActionSheet___postCommentAction___isOpen: false,
	ionAlert___postCommentAction___askForClose___isOpen: false,
	ionToast___postCommentAction___isDeleted: null,

}