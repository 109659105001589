import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComMapPreviewComponent: type___locale___translations___collection = {
	'go_to_navigation': {
		de_de: 'Gehen Sie zum Navigator',
		en_us: 'Go to the navigator',
		es_es: 'Ir al navegador',
		fr_fr: 'Accédez au navigateur',
		it_it: 'Vai al navigatore',
	},
	'go_to_navigation___apple_maps': {
		de_de: 'Apple Maps',
		en_us: 'Apple Maps',
		es_es: 'Apple Maps',
		fr_fr: 'Apple Maps',
		it_it: 'Apple Maps',
	},
	'go_to_navigation___google_maps': {
		de_de: 'Google Maps',
		en_us: 'Google Maps',
		es_es: 'Google Maps',
		fr_fr: 'Google Maps',
		it_it: 'Google Maps',
	},
	'go_to_navigation___cancel': {
		de_de: 'Stornieren',
		en_us: 'Cancel',
		es_es: 'Cancelar',
		fr_fr: 'Annuler',
		it_it: 'Annulla',
	},
};