import { RouteComponentProps } from "react-router";
import { interface___is_logged } from "../../../interfaces/interface.logged";

//	P R O P S

export interface props___SbPoiViewPage extends RouteComponentProps<{ id: string; }>, interface___is_logged {

}

//	S T A T E

export interface state___SbPoiViewPage {

	poi___id: string,
	poi___props: any | null,
	poi___props___address: string, 
	poi___props___is_loading: boolean, 

	poi___favorites___is_loading: boolean,

	poi_events___collection: any[],
	poi_events___collection___is_loading: boolean,

	ionModal___MdMediaViewModal___visible: boolean,
	ionModal___MdMediaViewModal___argument: string | null,

}

//	S T A T E   D E F A U L T

export const state___SbPoiViewPage___default: state___SbPoiViewPage = {

	poi___id: '',
	poi___props: null,
	poi___props___address: '...', 
	poi___props___is_loading: true,

	poi___favorites___is_loading: false,

	poi_events___collection: [],
	poi_events___collection___is_loading: false,

	ionModal___MdMediaViewModal___visible: false,
	ionModal___MdMediaViewModal___argument: null,

}