import { RouteComponentProps } from "react-router";
import { interface___is_logged } from "../../../interfaces/interface.logged";

//	P R O P S

export interface props___SbSettingsPage extends RouteComponentProps<{ id: string; }>, interface___is_logged {

}

//	S T A T E

export interface state___SbSettingsMainPage {

	me___props: any | null,

	ionAlert___askForClose___isOpen: boolean,

	ionModal___MdEntryInterestsModal___isOpen: boolean,
	ionModal___MdEntryLanguageModal___isOpen: boolean,

}

//	S T A T E   D E F A U L T

export const state___SbSettingsMainPage___default: state___SbSettingsMainPage = {

	me___props: null,

	ionAlert___askForClose___isOpen: false,

	ionModal___MdEntryInterestsModal___isOpen: false,
	ionModal___MdEntryLanguageModal___isOpen: false,

}