import React from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonLabel, IonListHeader, IonModal, IonToolbar } from '@ionic/react';
import { debounce } from 'lodash';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';
import { type___modal___config } from '../../types/types.modals';

//	S T Y L E

import './md-poi-select.scss';

//	S T A T E   -   P R O P S

import { props___MdPoiSelectModal, state___MdPoiSelectModal, state___MdPoiSelectModal___default } from './md-poi-select.state';

//	L I B S

import { lib_names } from '../../libs/lib.names';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';
import { service_LocalizationService } from '../../services/service-localization';

//	C O M P O N E N T S

import ComInfiniteScrollerComponent from '../../components/com-infinite-scroller/com-infinite-scroller';
import ComListEmptyComponent from '../../components/com-list-empty/com-list-empty';
import ComPoiComponent from '../../components/com-poi/com-poi';

//	C L A S S

export default class MdPoiSelectModal extends React.Component<props___MdPoiSelectModal, state___MdPoiSelectModal>
{

//#region 																							C O N F I G

	private readonly MdPoiSelectModal___modal_config: type___modal___config = {
		breakpoints: [ 1 ],
		backdropDismiss: true,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent(); },
	};

//#endregion

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdPoiSelectModal,
	) {

		super(props);
		this.state = state___MdPoiSelectModal___default;
		
		this.constructor___debounce();
	
	}

	constructor___debounce() : void {

		this.handle___poi___search___input = this.handle___poi___search___input.bind(this);
		this.handle___poi___search = debounce(this.handle___poi___search.bind(this), lib_names.lodash.debounce_delay);

	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___search_results = async () => {
		const ___temp___search_results___props: type___api___response = await this._API.poi___read___multi(this.props.target, this.state.search___param, this.state.search___results.length);
		this.setState((prevState) => ({
			search___results: [...prevState.search___results, ...___temp___search_results___props.data],
			search___results___is_loading: false,
			search___results___is_complete: (___temp___search_results___props.response === 'no-data') ? true : false,
		}));
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___poi___search___input = (___search_param: string) => {
		this.setState({
			search___param: ___search_param,
		}, () => {
			this.handle___poi___search(___search_param);
		});
	}

	private handle___poi___search = (___search_param: string) => {
		this.setState({
			search___results: [],
			search___results___is_loading: true,
		}, async () => {
			if (this.state.search___param && this.state.search___param.length > 0) {
				await this.read_props___search_results();
			}
		});
	}

	private handle___poi___select = (___ep___s: any) => {
		this.props.event___onDidDismiss(___ep___s);
	}

//#endregion

//#region 																							L I F E C Y C L E

	private event___onDidPresent() : void {
		this.setState({
			...state___MdPoiSelectModal___default
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonModal isOpen={ this.props.isOpen } {...this.MdPoiSelectModal___modal_config} handleBehavior="cycle">

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="end">
							<IonButton onClick={() => { this.props.event___onDidDismiss(null); }}>
								<b>chiudi</b>
							</IonButton>
						</IonButtons>
					</IonToolbar>
					<IonListHeader>
						<IonLabel>
							<section style={{display:'flex',justifyContent:'space-between'}}>
								<span>
									<i className="fas fa-search" style={{paddingRight:'15pt'}}></i>
									<span>Seleziona Poi da abbinare</span>
								</span>
							</section>
						</IonLabel>
					</IonListHeader>
					<section className="md-poi-select---header">
						<label className="md-poi-select---search-bar" htmlFor="input---discover-search-full">
							<i className="fas fa-search"></i>
							<input type="text" id="input---discover-search-full" placeholder="Cerca tra i tuoi POI" value={ this.state.search___param } onInput={(___e: any) => { this.handle___poi___search___input(___e.target.value); }}/>
						</label>
					</section>
				</IonHeader>

				<IonContent forceOverscroll={ false }>
					<section className="md-poi-select---container">
						{(this.state.search___param && this.state.search___param.length > 0) ? <>
						
							{(this.state.search___results.length > 0) ? <>
								<section>
									{this.state.search___results.map((___result: any, ___result___idx: number) => {
										return <>
											<section key={ ___result___idx } onClick={() => { this.handle___poi___select(___result); }}>
												<ComPoiComponent poi_props={ ___result } size="normal"/>
											</section>
										</>;
									})}
								</section>
							</> : <></>}

							{(this.state.search___results___is_complete === true) ? <>
								<ComListEmptyComponent list_items_quantity={ this.state.search___results.length }/>
							</> : <>
								<ComInfiniteScrollerComponent is_loading={ this.state.search___results___is_loading } event___scrollTriggered={ this.read_props___search_results } />
							</>}
							
						</> : <>
							<br />
							<br />
							<br />
							<ComListEmptyComponent text="Nessun poi da selezionare"/>
						</>}
					</section>
				</IonContent>

			</IonModal>
		</>;
	}

//#endregion

}