import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	L O C A L I Z A T I O N

import { locale___App } from '../../../App.locale';

//	S T Y L E

import './sb-info-thanks.scss';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';

//	C L A S S

class SbInfoThanksPage extends React.Component<{}, {}>
{

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/discover" text={ this._LOCALE.translate(locale___App.element___IonBackButton) }></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>
					<section className="sb-info-thanks---container">

						<br />

						<img src="/assets/ui/images/image-thanks.svg" />

						<br />
						<br />
						<br />
						<br />

						<section>
							<p>Alessandro Statzu</p>
							<p>Alessio Martis</p>
							<p>Antonio Burrai</p>
							<p>Antonino Pani</p>
							<p>Andrea Demontis</p>
							<p>Cristiano Zurru</p>
							<p>Emanuele Cera</p>
							<p>Enrica Manca</p>
							<p>Gabriella Minnei</p>
							<p>Giacomo Collu</p>
							<p>Laura Minnei</p>
							<p>Marcello Putzolu</p>
							<p>Maria Sanna</p>
							<p>Matia Torlini</p>
							<p>Melania Manconi</p>
							<p>Nicola Battolu</p>
							<p>Paolo Camedda</p>
							<p>Rita Pani</p>
							<p>Silvia Lisci</p>
							<p>Simone Siddi</p>
							<p>Simone Martis</p>
						</section>

						<br />
						<br />
						<br />
						<br />

					</section>
				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbInfoThanksPage);