import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComListEmptyComponent: type___locale___translations___collection = {
	'no_results': {
		de_de: 'kein ergebnis...',
		en_us: 'no results...',
		es_es: 'sin resultados...',
		fr_fr: 'pas de résultat...',
		it_it: 'nessun risultato...',
	},
	'no_more_results': {
		de_de: 'keine weiteren ergebnisse',
		en_us: 'no more results',
		es_es: 'no hay otros resultados',
		fr_fr: 'pas d\'autres résultats',
		it_it: 'nessun altro risultato',
	},
};