import React from 'react'

//	L O C A L I Z A T I O N

import { locale___ComUserPrivateComponent } from './com-user-private.locale';

//	S T Y L E

import './com-user-private.scss'

//	P R O P S

import { props___ComUserPrivateComponent } from './com-user-private.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C L A S S

export default class ComUserPrivateComponent extends React.Component<props___ComUserPrivateComponent, {}>
{

//#region 																							C O N S T R U C T O R
		
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<section className="com-poi-disclaimer-view---component">
				{ this._LOCALE.translate(locale___ComUserPrivateComponent.profile_is_private, [this.props.user___props['user_username']]) }
			</section>
		</>;
	}

//#endregion

}