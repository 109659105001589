import { interface___is_logged } from "../../interfaces/interface.logged";
import { interface___modal } from "../../interfaces/interface.modals";

//	P R O P S

export interface props___MdPoiSelectModal extends interface___modal {

	target: string,

}

//	S T A T E

export interface state___MdPoiSelectModal {
	
	search___param: string,
	
	search___results: any[],
	search___results___is_loading: boolean,
	search___results___is_complete: boolean,

}

//	S T A T E   D E F A U L T

export const state___MdPoiSelectModal___default: state___MdPoiSelectModal = {

	search___param: '',
	
	search___results: [],
	search___results___is_loading: true,
	search___results___is_complete: false,

}