import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComTaleViewInner: type___locale___translations___collection = {
		












	'datetime_today_at': {
		de_de: 'Heute um 10.10 Uhr',
		en_us: 'Today at 10.10am',
		es_es: 'Hoy a las 10.10',
		fr_fr: 'Aujourd\'hui à 10h10',
		it_it: 'Oggi alle {{1}}',
	},
	'datetime_date_at': {
		de_de: '{{1}} um 10:10 Uhr',
		en_us: '{{1}} at 10:10',
		es_es: '{{1}} a las 10:10',
		fr_fr: '{{1}} à 10h10',
		it_it: '{{1}} alle {{2}}',
	},







	

};