import { interface___component___has_destructive_action } from "../../interfaces/interface.components"
import { interface___is_logged } from "../../interfaces/interface.logged"

//	P R O P S

export interface props___ComPostComponent extends interface___is_logged, interface___component___has_destructive_action {

	post___type: 'timeline' | 'normal',
	
	post___props: any
	post___can_route_comments: boolean,

}

//	S T A T E

export interface state___ComPostComponent {

	post___props: any | null,
	post___props___is_loading: boolean,

	post___is_destroyed: boolean,
	post___is_destroyed___visible: boolean,

	post_like___is_loading: boolean,










	ionActionSheet___postAction___isOpen: boolean,
	ionAlert___postAction___askForClose___isOpen: boolean,
	ionToast___postAction___isDeleted: boolean | null,

}

//	S T A T E   D E F A U L T

export const state___ComPostComponent___default: state___ComPostComponent = {

	post___props: null,
	post___props___is_loading: true,

	post___is_destroyed: false,
	post___is_destroyed___visible: true,

	post_like___is_loading: false,










	ionActionSheet___postAction___isOpen: false,
	ionAlert___postAction___askForClose___isOpen: false,
	ionToast___postAction___isDeleted: null,

}