import { type___locale___translations___collection } from "../../../types/types.locale";

//	E X P O R T   L O C A L E

export const locale___SbInfoDigitalMenuAllergensPage: type___locale___translations___collection = {
	'title': {
		de_de: 'Allergenliste',
		en_us: 'Allergen List',
		es_es: 'Lista de Alérgenos',
		fr_fr: 'Liste des Allergènes',
		it_it: 'Elenco degli Allergeni',
	},
};