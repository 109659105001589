//	L O C A L I Z A T I O N S

import { locale___funcs_measurements } from "./funcs.all.locale";

//	T Y P E S

import { type___digitalmenu___dish_measurement_unit } from '../types/types.digitalmenu';

//	S E R V I C E S

import { service_LocalizationService } from "../services/service-localization";

//	E X P O R T   F U N C S

export class funcs_measurements
{

//#region 																							N U M B E R S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							P R I C E   A T   M E A S U R E M E N T

	price_at_measurements(___measurement_unit: type___digitalmenu___dish_measurement_unit, ___extended: boolean = false) : string
	{
		if (___measurement_unit === null) { return ''; }
		const ___locale_string___picker: string = 'unit___' + ___measurement_unit.toString() + ((___extended === true) ? '___extended' : '');
		return this._LOCALE.translate(locale___funcs_measurements[___locale_string___picker]);
	}

//#endregion

}