import { interface___modal } from "../../interfaces/interface.modals";
import { type___poi___timetable, type___poi___timetable___week } from "../../types/types.types";

//	P R O P S

export interface props___MdPoiTimetableEditModal extends interface___modal {

	timetable_props: type___poi___timetable,

}

//	S T A T E

export interface state___MdPoiTimetableEditModal {

	timetable___is_loading: boolean,

	timetable___field___type: 'none' | 'h24' | 'custom',
	timetable___field___week: type___poi___timetable___week,
	
	timetable___field___newItem: 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun' | null,
	timetable___field___newItem___value___from: string | null, 
	timetable___field___newItem___value___to: string | null, 

}

//	S T A T E   D E F A U L T

export const state___MdPoiTimetableEditModal___default: state___MdPoiTimetableEditModal = {

	timetable___is_loading: true,

	timetable___field___type: 'custom',
	timetable___field___week: { 'mon': [], 'tue': [], 'wed': [], 'thu': [], 'fri': [], 'sat': [], 'sun': [] },

	timetable___field___newItem: null,
	timetable___field___newItem___value___from: null, 
	timetable___field___newItem___value___to: null, 

}