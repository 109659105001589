import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComUserRequestComponent: type___locale___translations___collection = {
	'toastNotice___request___is_accepted': {
		de_de: '_______________________',
		en_us: '_______________________',
		es_es: '_______________________',
		fr_fr: '_______________________',
		it_it: 'Hai accettato la richeista di {{1}}',
	},
	'toastNotice___request___is_rejected': {
		de_de: '_______________________',
		en_us: '_______________________',
		es_es: '_______________________',
		fr_fr: '_______________________',
		it_it: 'Hai rifiutato la richeista di {{1}}',
	},
	'toastNotice___request___error': {
		de_de: '_______________________',
		en_us: '_______________________',
		es_es: '_______________________',
		fr_fr: '_______________________',
		it_it: 'Errore nell\'elaborazione della richiesta...',
	},
	'request___paragraph': {
		de_de: '_______________________',
		en_us: '_______________________',
		es_es: '_______________________',
		fr_fr: '_______________________',
		it_it: '{{1}} ha chiesto di seguirti.',
	},
	'request___accept': {
		de_de: '_______________________',
		en_us: '_______________________',
		es_es: '_______________________',
		fr_fr: '_______________________',
		it_it: 'Accetta.',
	},
	'request___reject': {
		de_de: '_______________________',
		en_us: '_______________________',
		es_es: '_______________________',
		fr_fr: '_______________________',
		it_it: 'Rifiuta',
	},











};