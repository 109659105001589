import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___MdDiscoverCompassComponent: type___locale___translations___collection = {
	'show_all_events': {
		de_de: 'Alle Veranstaltungen ansehen',
		en_us: 'See all events',
		es_es: 'Ver todos los eventos',
		fr_fr: 'Voir tous les événements',
		it_it: 'Vedi tutti gli eventi',
	},
	'category_events': {
		de_de: 'Veranstaltungen',
		en_us: 'Events',
		es_es: 'Eventos',
		fr_fr: 'Événements',
		it_it: 'Eventi',
	},
};