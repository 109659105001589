import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___MdEntryInterestsModal: type___locale___translations___collection = {
	'topBarButton___close': {
		de_de: 'Stornieren',
		en_us: 'Cancel',
		es_es: 'Cancelar',
		fr_fr: 'Annuler',
		it_it: 'Annulla',
	},
	'title': {
		de_de: 'Um Ihnen ein besseres Erlebnis in der Nähe zu bieten, wählen Sie Ihre Interessen aus',
		en_us: 'To offer you a better experience on nearby choose your interests',
		es_es: 'Para ofrecerle una mejor experiencia en las cercanías, elija sus intereses.',
		fr_fr: 'Pour vous offrir une meilleure expérience à proximité choisissez vos centres d\'intérêt',
		it_it: 'Per offrirti una migliore esperienza su nearby scegli i tuoi interessi',
	},
	'subtitle': {
		de_de: 'Machen Sie sich keine Sorgen, wenn Sie Ihre Meinung ändern, können Sie sie später in den Einstellungen anpassen',
		en_us: 'don\'t worry, if you change your mind you can customize them later in the settings',
		es_es: 'no te preocupes, si cambias de opinión podrás personalizarlos más tarde en la configuración.',
		fr_fr: 'ne vous inquiétez pas, si vous changez d\'avis vous pourrez les personnaliser plus tard dans les paramètres',
		it_it: 'niente paura, se cambi idea potrai personalizzarli in seguito nelle impostazioni',
	},
	'category___events': {
		de_de: '_______________________________________',
		en_us: '_______________________________________',
		es_es: '_______________________________________',
		fr_fr: '_______________________________________',
		it_it: 'Eventi',
	},
	'button___save': {
		de_de: 'Speichern Sie meine Interessen',
		en_us: 'Save my Interest',
		es_es: 'Guardar mis intereses',
		fr_fr: 'Sauvegarder mes centres d\'intérêt',
		it_it: 'Salva i miei Interessi',
	},
	'button___skip': {
		de_de: 'Lassen sie es gehen...',
		en_us: 'Skip this...',
		es_es: 'Déjalo ir...',
		fr_fr: 'Laisse tomber...',
		it_it: 'Lascia perdere...',
	}
};