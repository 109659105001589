import { interface___is_logged } from "../../../interfaces/interface.logged";
import { interface___has_notifications } from "../../../interfaces/interface.notifications";

//	P R O P S

export interface props___SbChatsWithPage extends interface___is_logged {

}

//	S T A T E

export interface state___SbChatsWithPage {

	user___props___username: string,
	user___props: any | null,
	user___props___is_loading: boolean,

	chat_messages___list___is_empty: boolean,
	chat_messages___list: any[],
	chat_messages___list___is_loading: boolean,
	chat_messages___list___is_complete: boolean,

	field___message_text: string,





}

//	S T A T E   D E F A U L T

export const state___SbChatsWithPage___default: state___SbChatsWithPage = {

	user___props___username: '',
	user___props: null,
	user___props___is_loading: true,

	chat_messages___list___is_empty: true,
	chat_messages___list: [],
	chat_messages___list___is_loading: true,
	chat_messages___list___is_complete: false,

	field___message_text: '',




	
}