import { type___locale___translations___collection } from "../../../types/types.locale";

//	E X P O R T   L O C A L E

export const locale___SbPoiViewDigitalMenuPage: type___locale___translations___collection = {
	'button___allergen_list': {
		de_de: 'Informationen zu allergenen',
		en_us: 'allergen information',
		es_es: 'información de alérgenos',
		fr_fr: 'informations sur les allergènes',
		it_it: 'informazioni sugli allergeni',
	},
};