import { type___locale___translations___collection } from "../../../types/types.locale";

//	E X P O R T   L O C A L E

export const locale___SbInfoLicencesPage: type___locale___translations___collection = {
	'title': {
		de_de: 'Lizenzen Dritter',
		en_us: 'Third party licences',
		es_es: 'Licencias de terceros',
		fr_fr: 'Licences tierces',
		it_it: 'Licenze di terze parti',
	},
};