import { interface___is_logged } from "../../interfaces/interface.logged";
import { interface___modal } from "../../interfaces/interface.modals";
import { type___map___filters, type___map___style } from "../../types/types.types";

//	P R O P S

export interface props___MdDiscoverSearchModal extends interface___modal, interface___is_logged {

	search_param: string,
	
}

//	S T A T E

export interface state___MdDiscoverSearchModal {

	search___target: 'events' | 'poi' | 'users',
	
	search___param: string,
	
	search___results: any[],
	search___results___is_loading: boolean,
	search___results___is_complete: boolean,

}

//	S T A T E   D E F A U L T

export const state___MdDiscoverSearchModal___default: state___MdDiscoverSearchModal = {
	
	search___target: 'poi',
	
	search___param: '',
	
	search___results: [],
	search___results___is_loading: true,
	search___results___is_complete: false,

}