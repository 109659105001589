import { RouteComponentProps } from "react-router";

//	P R O P S

export interface props___SbPoiViewTalesPage extends RouteComponentProps<{ id: string; }> {

}

//	S T A T E

export interface state___SbPoiViewTalesPage {

	poi___id: string,
	poi___props: any | null,
	poi___props___is_loading: boolean, 

	poi___tales___sort: 'standard' | 'popular',
	poi___tales: any[],
	poi___tales___is_loading: boolean,
	poi___tales___is_complete: boolean,
	









	ionModal___MdTaleViewModal___isOpen: boolean,
	ionModal___MdTaleViewModal___argument: string,

}

//	S T A T E   D E F A U L T

export const state___SbPoiViewTalesPage___default: state___SbPoiViewTalesPage = {

	poi___id: '',
	poi___props: null,
	poi___props___is_loading: true, 

	poi___tales___sort: 'popular',
	poi___tales: [],
	poi___tales___is_loading: true,
	poi___tales___is_complete: false,














	
	ionModal___MdTaleViewModal___isOpen: false,
	ionModal___MdTaleViewModal___argument: '',

}