//	P R O P S

export interface props___ComGalleryEditComponent {

	gallery_pictures: string[]

	event___onUpdate: (_: string[]) => void

}

//	S T A T E

export interface state___ComGalleryEditComponent {

	field___gallery_pictures: string[]
	action___item___is_dragging: boolean

}

//	S T A T E   D E F A U L T S

export const state___ComGalleryEditComponent___defaults: state___ComGalleryEditComponent = {

	field___gallery_pictures: [],
	action___item___is_dragging: false

}