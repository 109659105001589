import { RouteComponentProps } from "react-router";
import { interface___is_logged } from "../../../interfaces/interface.logged";

//	P R O P S

export interface props___SbSettingsCreditsPage extends RouteComponentProps<{ id: string; }>, interface___is_logged {

}

//	S T A T E

export interface state___SbSettingsCreditsPage {

	credits_amount: number,
	credits_amount___is_loading: boolean,
	
}

//	S T A T E   D E F A U L T

export const state___SbSettingsCreditsPage___default: state___SbSettingsCreditsPage = {

	credits_amount: 0,
	credits_amount___is_loading: true,
	
}