import React from 'react';
import { IonButton, IonButtons, IonCard, IonContent, IonFooter, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonModal, IonSegment, IonSegmentButton, IonToggle, IonToolbar } from '@ionic/react';

//	L O C A L I Z A T I O N

import { locale___MdDiscoverFiltersModal } from './md-discover-filters.locale';

//	L I B S

import { lib_names } from '../../libs/lib.names';

//	T Y P E S

import { type___map___filters } from '../../types/types.types';
import { type___modal___config } from '../../types/types.modals';

//	S T Y L E

import './md-discover-filters.scss';

//	S T A T E   -   P R O P S

import { props___MdDiscoverFiltersModal, state___MdDiscoverFiltersModal, state___MdDiscoverFiltersModal___default } from './md-discover-filters.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C O M P O N E N T S

import ComMapPreviewComponent from '../../components/com-map-preview/com-map-preview';
import ComSpinnerInlineComponent from '../../components/com-spinner-inline/com-spinner-inline';

//	C L A S S

export default class MdDiscoverFiltersModal extends React.Component<props___MdDiscoverFiltersModal, state___MdDiscoverFiltersModal>
{

//#region 																							C O N F I G

	private readonly MdDiscoverFiltersModal___modal_config: type___modal___config = {
		breakpoints: [ 1 ],
		backdropDismiss: true,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent(); },
		onDidDismiss: () => { this.event___onDidDismiss(); },
	};

//#endregion

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdDiscoverFiltersModal,
	) {
		super(props);
		this.state = state___MdDiscoverFiltersModal___default;
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___map_filters___submit = (___is_apply: boolean) => {
		if (___is_apply === true)
		{
			const ___new_filters_props: type___map___filters = {
				show_events: this.state.selector___show_events,
				show_poi: this.state.selector___show_poi,
				show_tales: this.state.selector___show_tales,
				map_style: this.state.selector___map_style,
			};
			const ___new_filters_props___stringified: string = JSON.stringify(___new_filters_props);
			localStorage.setItem(lib_names.localStorage.user___map___filters, ___new_filters_props___stringified);
			this.props.event___onDidDismiss(___new_filters_props);
		}
		else
		{
			this.props.event___onDidDismiss(this.props.filters);
		}
	}

//#endregion

//#region 																							L I F E C Y C L E

	private event___onDidPresent() : void {
		this.setState({
			selector___show_events: this.props.filters.show_events,
			selector___show_poi: this.props.filters.show_poi,
			selector___show_tales: this.props.filters.show_tales,
			selector___map_style: this.props.filters.map_style
		}, () => {
			setTimeout(() => {
				this.setState({
					selectors_are___is_loading: false,
				});
			}, 15);
		});
	}

	private event___onDidDismiss() : void {
		this.setState({
			...state___MdDiscoverFiltersModal___default
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonModal isOpen={ this.props.isOpen } {...this.MdDiscoverFiltersModal___modal_config} handleBehavior="cycle">

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="end">
							<IonButton onClick={() => { this.handle___map_filters___submit(false); }}>
								<b>{ this._LOCALE.translate(locale___MdDiscoverFiltersModal.topBarButton___cancel) }</b>
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					<IonListHeader>
						<IonLabel>{ this._LOCALE.translate(locale___MdDiscoverFiltersModal.showing) }</IonLabel>
					</IonListHeader>

					<br />

					{(this.state.selectors_are___is_loading === true) ? <>
						<ComSpinnerInlineComponent />
					</> : <>
						<section className="md-discover-filters---container">
							<IonCard>
								<IonList>
									<IonItem className="is-ion-item-button">
										<img src="/assets/markers/poi.svg"/>
										<IonToggle checked={ this.state.selector___show_poi } onIonChange={(___e: any) => { this.setState({ selector___show_poi: ___e.detail.checked }); }}>
											<>{ this._LOCALE.translate(locale___MdDiscoverFiltersModal.showing___poi) }</>
										</IonToggle>
									</IonItem>
									<IonItem className="is-ion-item-button">
										<img src="/assets/markers/event.svg"/>
										<IonToggle checked={ this.state.selector___show_events } onIonChange={(___e: any) => { this.setState({ selector___show_events: ___e.detail.checked }); }}>
											<>{ this._LOCALE.translate(locale___MdDiscoverFiltersModal.showing___events) }</>
										</IonToggle>
									</IonItem>
									{(this.props.is_logged === true) ? <>
										<IonItem className="is-ion-item-button">
											<img src="/assets/markers/tale.svg"/>
											<IonToggle checked={ this.state.selector___show_tales } onIonChange={(___e: any) => { this.setState({ selector___show_tales: ___e.detail.checked }); }}>
												<>{ this._LOCALE.translate(locale___MdDiscoverFiltersModal.showing___tales) }</>
											</IonToggle>
										</IonItem>
									</> : <></>}
								</IonList>
							</IonCard>
						</section>
					</>}

					<br />

					<IonListHeader>
						<IonLabel>{ this._LOCALE.translate(locale___MdDiscoverFiltersModal.map_style) }</IonLabel>
					</IonListHeader>

					<br />
	
					{(this.state.selectors_are___is_loading === true) ? <>
						<ComSpinnerInlineComponent />
					</> : <>
						<section className="md-discover-filters---container">
							<ComMapPreviewComponent map_coords={{ lat: 39, lng: 9 }} map_style={ this.state.selector___map_style }/>
							<br />
							<IonSegment value={ this.state.selector___map_style } onIonChange={(___e: any) => { this.setState({ selector___map_style: ___e.detail.value }); }}>
								<IonSegmentButton value="normal">
									<IonLabel>{ this._LOCALE.translate(locale___MdDiscoverFiltersModal.map_style___normal) }</IonLabel>
								</IonSegmentButton>
								<IonSegmentButton value="satellite">
									<IonLabel>{ this._LOCALE.translate(locale___MdDiscoverFiltersModal.map_style___satellite) }</IonLabel>
								</IonSegmentButton>
								<IonSegmentButton value="dark">
									<IonLabel>{ this._LOCALE.translate(locale___MdDiscoverFiltersModal.map_style___dark) }</IonLabel>
								</IonSegmentButton>
							</IonSegment>
						</section>
					</>}

				</IonContent>

				<IonFooter>
					<section className="container---footer-section">
						<button className="is-element is-button is-color---folly is-full-width" onClick={() => { this.handle___map_filters___submit(true) }}>
							<i className="fas fa-filter"></i>
							<h4>{ this._LOCALE.translate(locale___MdDiscoverFiltersModal.button___save) }</h4>
						</button>
					</section>
				</IonFooter>

			</IonModal>
		</>;
	}

//#endregion

}