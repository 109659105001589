import React from 'react'

//	S T Y L E

import './com-text.scss'

//	S T A T E S   -   P R O P S

import { props___ComTextComponent } from './com-text.state';

//	C L A S S

export default class ComTextComponent extends React.Component<props___ComTextComponent, {}>
{

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="com-text-component---container">
				<p>{ this.props.text }</p>
			</div>
		</>;
	}

//#endregion

}