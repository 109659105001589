import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonFooter, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonNote, IonPage, IonToast, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './sb-poi-edit.scss';

//	L I B S

import { lib_names } from '../../../libs/lib.names';
import { lib_names_shared } from '../../../libs/lib.names.shared';

//	S T A T E   -   P R O P S

import { props___SbPoiEditPage, state___SbPoiEditPage, state___SbPoiEditPage___default } from './sb-poi-edit.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';
import { type___geo___lngLat, type___poi___socials, type___poi___timetable } from '../../../types/types.types';

//	F U N C S

import { funcs_text } from '../../../funcs/funcs.text';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';
import { service_Router } from '../../../services/service-router';

//	C O M P O N E N T S

import ComGalleryEditComponent from '../../../components/com-gallery-edit/com-gallery-editor';
import ComListEmptyComponent from '../../../components/com-list-empty/com-list-empty';
import ComMapPreviewComponent from '../../../components/com-map-preview/com-map-preview';
import ComMapPreviewAddressComponent from '../../../components/com-map-preview-address/com-map-preview-address';
import ComPoiCategoryComponent from '../../../components/com-poi-category/com-poi-category';
import ComPoiDisclaimerEditComponent from '../../../components/com-poi-disclaimer-edit/com-poi-disclaimer-edit';
import ComPoiTimetableTableComponent from '../../../components/com-poi-timetable-table/com-poi-timetable-table';
import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';
import ComSpinnerInlineComponent from '../../../components/com-spinner-inline/com-spinner-inline';

//	M O D A L S

import MdMediaEditorModal from '../../../modals/md-media-editor/md-media-editor';
import MdPoiSelectCategoryModal from '../../../modals/md-poi-select-category/md-poi-select-category';
import MdPoiTimetableEditModal from '../../../modals/md-poi-timetable-edit/md-poi-timetable-edit';
import MdPositionSelectorModal from '../../../modals/md-position-selector/md-position-selector';

//	C L A S S

class SbPoiEditPage extends React.Component<props___SbPoiEditPage, state___SbPoiEditPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _ROUTER: service_Router = new service_Router();

	private readonly funcs___text: funcs_text = new funcs_text();

	private media_files___array: string[] = state___SbPoiEditPage___default['field___poi_gallery'];

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbPoiEditPage,
	) {
		super(props);
		this.state = state___SbPoiEditPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___poi = async () => {
		const ___temp___poi___props: type___api___response = await this._API.poi___read___single(this.state.poi___id);
		if (!___temp___poi___props || ___temp___poi___props.response !== 'success' || ___temp___poi___props.data === null) { console.log('404'); }
		this.setState({
			
			field___poi_category: ___temp___poi___props.data['poi_category'],
			
			field___poi_gallery: ___temp___poi___props.data['poi_gallery'],
			field___poi_name: ___temp___poi___props.data['poi_name'],
			field___poi_description: ___temp___poi___props.data['poi_description'],
			
			field___poi_coords: { lng: ___temp___poi___props.data['poi_coords_lng'], lat: ___temp___poi___props.data['poi_coords_lat'] },

			field___poi_contact_phone: ___temp___poi___props.data['poi_contact_phone'] ?? '',
			field___poi_contact_email: ___temp___poi___props.data['poi_contact_email'] ?? '',
			field___poi_contact_socials: (___temp___poi___props.data['poi_contact_socials']) ? ___temp___poi___props.data['poi_contact_socials'] : 'none',
			field___poi_contact_website: ___temp___poi___props.data['poi_contact_website'],
		
			field___poi_attributes: ___temp___poi___props.data['poi_attributes'],

			field___poi_timetable: (___temp___poi___props.data['poi_timetable']) ? ___temp___poi___props.data['poi_timetable'] : 'none',








			
			poi___props___is_loading: false

		}, () => {
			this.media_files___array = this.state.field___poi_gallery;





		});
	}

	private read_props___attributes = async () => {
		const ___temp___poi_attributes___collection: type___api___response = await this._API.poi_attributes___read___multi();
		this.setState({
			collection___poi_attributes: ___temp___poi_attributes___collection.data,
			collection___poi_attributes___is_loading: false,
		});
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___SbPoiEdit___askClose = () => {
		this.setState({
			ionAlert___askForClose___isVisible: true
		});
	}

	private handle___poi_category___select = (___poi_category: any | undefined | null) => {
		this.setState({
			ionModal___MdPoiSelectCategoryModal___isOpen: false,
			field___poi_category: ___poi_category
		});
	}

	private handle___gallery___fileUpload = (___e: React.ChangeEvent<HTMLInputElement>) => {
		if (___e.target.files)
		{
			const ___files_array: File[] = Array.from(___e.target.files);
			for (let ___f_i = 0; ___f_i < ___files_array.length; ___f_i++)
			{
				const ___f: File = ___files_array[___f_i];
				const ___f___reader: FileReader = new FileReader();
				___f___reader.onloadend = () => {
					this.media_files___array.push(___f___reader.result as string);
					if (this.media_files___array.length === ___files_array.length) {
						this.setState({
							field___poi_gallery: this.media_files___array,
							ionModal___MdMediaEditorModal___isOpen: true
						});
					}
				
				};
				___f___reader.readAsDataURL(___f);
			}
		}
	}

	private handle___gallery___fileEditEnd = (___retrieved_gallery: string[] | null) => {
		this.setState({
			field___poi_gallery: (___retrieved_gallery && ___retrieved_gallery.length > 0) ? ___retrieved_gallery : this.state.field___poi_gallery,
			ionModal___MdMediaEditorModal___isOpen: false,
		});
	}

	private handle___attribute___select = (___attribute_id: string) => {
		let ___attribute_collection___temp: string[] = this.state.field___poi_attributes;
		switch (this.state.field___poi_attributes.includes(___attribute_id)) {
			case true: ___attribute_collection___temp = ___attribute_collection___temp.filter(___c => ___c !== ___attribute_id); break;
			case false: ___attribute_collection___temp.push(___attribute_id); break;
		}
		this.setState({
			field___poi_attributes: ___attribute_collection___temp,
		});
	}

	private handle___poi_timetable___edit = (___poi_timetable: type___poi___timetable | undefined | null) => {

		console.log('___poi_timetable',___poi_timetable)

		this.setState({
			field___poi_timetable: (___poi_timetable) ? ___poi_timetable : this.state.field___poi_timetable,
			ionModal___MdPoiTimetableModal___isOpen: false,
		});
	}

	private handle___poi_position___select = (___poi_position: { coords: type___geo___lngLat, address: string }) => {
		this.setState({
			ionModal___MdPositionSelectorModal___isOpen: false,
			field___poi_coords: ___poi_position.coords,
			field___poi_coords___address: ___poi_position.address
		});
	}

	private handle___poi_socials___edit = (___key: string, ___param: string) => {
		const ___socials_path: type___poi___socials = this.state.field___poi_contact_socials;
		___socials_path[___key] = ___param;
		this.setState({
			field___poi_contact_socials: ___socials_path
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	private submit___poi___check_if_can = () : boolean => {

		let ___can_submit: boolean = true;

		if (this.state.field___poi_category === null) { document.getElementById('field---poi-category').classList.add('input---nb-outlined---error'); ___can_submit = false; }
		if (this.state.field___poi_name.length < 3) { document.getElementById('field---poi-name').classList.add('input---nb-outlined---error'); ___can_submit = false; }
		if (this.state.field___poi_coords === null) { document.getElementById('field---poi-coords').classList.add('input---nb-outlined---error'); ___can_submit = false; }
		
		if (___can_submit === false) {
			this.setState({
				ionToast___type: 'danger',
				ionToast___message: 'Prima di salvare il punto di interesse controlla che tutti i campi evidenziati siano compilati correttamente',
				ionToast___isOpen: true,
			});
		}

		return ___can_submit as boolean;

	}

	private submit___poi = () => {

		const ___can_submit: boolean = this.submit___poi___check_if_can();

		if (___can_submit === true)
		{
	
			this.setState({
				submitting___is_loading: true,
			}, async () => {
				const ___poi___is_submitted: type___api___response = await this._API.poi___submit(this.state.poi___id, this.state.field___poi_category['poi_category_id'], this.state.field___poi_name, this.state.field___poi_description, this.state.field___poi_gallery, this.state.field___poi_coords, this.state.field___poi_contact_phone, this.state.field___poi_contact_email, this.state.field___poi_contact_website, this.state.field___poi_contact_socials, this.state.field___poi_attributes, this.state.field___poi_timetable);
				this.setState({
					ionToast___type: (___poi___is_submitted && ___poi___is_submitted.response === 'success') ? 'success' : 'danger',
					ionToast___message: (___poi___is_submitted && ___poi___is_submitted.response === 'success') ? 'Poi creato' : 'Errore nella creazione del Punto di Interesse',
					ionToast___isOpen: true,
				}, () => {
					if ((___poi___is_submitted && ___poi___is_submitted.response === 'success')) {
						window.history.back();
					}
				});
			});

		}

	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		const ___poi_id: string | null = this._ROUTER.get_id(this.props);
		if (___poi_id && ___poi_id !== 'new') {
			this.setState({
				poi___id: ___poi_id,
			}, async () => {
				await this.read_props___attributes();
				await this.read_props___poi();
			});
		} else {
			this.setState({
				poi___id: null,
				poi___props___is_loading: false,
				ionModal___MdPoiSelectCategoryModal___isOpen: true,
			});
		}
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonToast isOpen={ this.state.ionToast___isOpen } message={ this.state.ionToast___message } duration={ 3000 } color={ this.state.ionToast___type } position="top" onDidDismiss={() => { this.setState({ ionToast___isOpen: false }); }}/>

				{(this.state.field___poi_gallery.length > 0) ? <>
					<MdMediaEditorModal isOpen={ this.state.ionModal___MdMediaEditorModal___isOpen } input___aspect_ratio={ lib_names.aspectRatios.poi.ar } input___image_list={ this.state.field___poi_gallery } event___onDidDismiss={ this.handle___gallery___fileEditEnd } />
				</> : <></>}
				<MdPoiSelectCategoryModal isOpen={ this.state.ionModal___MdPoiSelectCategoryModal___isOpen } event___onDidDismiss={ this.handle___poi_category___select }/>	
				{(this.state.field___poi_timetable) ? <>
					<MdPoiTimetableEditModal isOpen={ this.state.ionModal___MdPoiTimetableModal___isOpen } timetable_props={ this.state.field___poi_timetable } event___onDidDismiss={ this.handle___poi_timetable___edit }/>	
				</> : <></>}
				<MdPositionSelectorModal isOpen={ this.state.ionModal___MdPositionSelectorModal___isOpen } position___input={ this.state.field___poi_coords } event___onDidDismiss={ this.handle___poi_position___select }/>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/discover"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					{(this.state.poi___props___is_loading === true || this.state.submitting___is_loading === true) ? <>
					
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<ComSpinnerComponent size="small"/>
					
					</> : <>

{/**
 * 
 * 
 * 
 * 						D I S C L A I M E R
 * 
 * 
 * 
 */}

						<div className="sb-poi-edit---container">
							<ComPoiDisclaimerEditComponent/>
						</div>

{/**
 * 
 * 
 * 
 * 						C A T E G O R I A
 * 
 * 
 * 
 */}

						<IonListHeader>
							<IonLabel>
								<i className="fas fa-icons"></i>
								<span>Categoria</span>
							</IonLabel>
						</IonListHeader>
						
						<div className="sb-poi-edit---container">
	
							<p className="input---nb-label is-required">categoria</p>
							<IonCard onClick={() => { this.setState({ ionModal___MdPoiSelectCategoryModal___isOpen: true }); }} id="field---poi-category">
								{(this.state.field___poi_category === null) ? <>
									<IonList>
										<IonItem className="is-ion-item-button">
											<i className="fa-solid fa-dice"></i>
											<IonLabel>Seleziona Categoria</IonLabel>
											<IonNote slot="end">
												<i className="fa-solid fa-pen"></i>
											</IonNote>
										</IonItem>
									</IonList>
								</> : <>
									<ComPoiCategoryComponent poi_category___props={ this.state.field___poi_category } isButton={ true }/>
									<IonNote slot="end">
										<i className="fa-solid fa-pen"></i>
									</IonNote>
								</>}
							</IonCard>

						</div>

{/**
 * 
 * 
 * 
 * 						F O T O   E   I N F O R M A Z I O N I
 * 
 * 
 * 
 */}

						<IonListHeader>
							<IonLabel>
								<i className="fas fa-info-circle"></i>
								<span>Foto e Informazioni</span>
							</IonLabel>
						</IonListHeader>

						<div className="sb-poi-edit---container">
						
							<p className="input---nb-label">galleria</p>
							<input type="file" id="field---poi-gallery" onChange={ this.handle___gallery___fileUpload } accept={ lib_names_shared.files.input_accepted } multiple style={{display:'none'}}/>
							{(this.state.field___poi_gallery.length > 0) ? <>
								<ComGalleryEditComponent gallery_pictures={ this.state.field___poi_gallery } event___onUpdate={(___e: string[]) => { this.setState({ field___poi_gallery: ___e }, () => { console.log('___e',___e) }) }}/>
								<br />
							</> : <></>}
							<label htmlFor="field---poi-gallery">
								<IonCard>
									<IonList>
										<IonItem className="is-ion-item-button">
											<i className="fa-solid fa-images"></i>
											<IonLabel>Aggiungi immagini</IonLabel>
											<IonNote slot="end">
												<i className="fa-solid fa-plus"></i>
											</IonNote>
										</IonItem>
									</IonList>
								</IonCard>
							</label>

							<br />

							<label htmlFor="field---poi-name">
								<p className="input---nb-label is-required">nome</p>
								<input type="text" id="field---poi-name" className="input---nb-outlined" placeholder="Nome del punto di interesse" value={ this.state.field___poi_name } onInput={(___e: any) => { this.setState({ field___poi_name: ___e.target.value}); }}/>
							</label>

							<br />

							<label htmlFor="field---poi-description">
								<p className="input---nb-label is-required">descrizione</p>
								<textarea id="field---poi-description" className="input---nb-outlined" placeholder="Descrizione del punto di interesse" style={{height:'250pt'}} value={ this.state.field___poi_description } onInput={(___e: any) => { this.setState({ field___poi_description: this.funcs___text.text_input___process(___e.target.value) }); }}></textarea>
							</label>

							<br />

							<p className="input---nb-label">orari di apertura</p>
							<IonCard onClick={() => { this.setState({ ionModal___MdPoiTimetableModal___isOpen: true }); }}>
								<IonList>
									<IonItem className="is-ion-item-button">
										<i className="fa-solid fa-clock"></i>
										<IonLabel>Modifica orari</IonLabel>
										<IonNote slot="end">
											<i className="fa-solid fa-ellipsis"></i>
										</IonNote>
									</IonItem>
									{(() => {
										switch (this.state.field___poi_timetable)
										{
											case 'none': return <>
												<p>nessun orario</p>
											</>; break;
											case 'h24': return <>
												<p>aperto h24</p>
											</>; break; 
											default: return <>
												<ComPoiTimetableTableComponent timetable_week_props={ this.state.field___poi_timetable }/>
											</>; break;
										}
									})()}
								</IonList>
							</IonCard>

						</div>

{/**
 * 
 * 
 * 
 * 						A T T R I B U T I
 * 
 * 
 * 
 */}

{/*

						<IonListHeader>
							<IonLabel>
								<i className="fas fa-ellipsis-h"></i>
								<span>Attributi</span>
							</IonLabel>
						</IonListHeader>

						<div className="sb-poi-edit---container">
							{(this.state.collection___poi_attributes___is_loading === true) ? <>
								<br />
								<ComSpinnerInlineComponent/>
								<br />
							</> : <>
								<div className="sb-poi-edit---attributes---container">
									{this.state.collection___poi_attributes.map((___p_a: any, ___p_a___idx: number) => {
										const ___c_child___checkbox___id: string = 'sb-poi-edit----cb-attribute---' + ___p_a['poi_attribute_id'];
										return <>
											<input type="checkbox" id={ ___c_child___checkbox___id } value={ ___p_a['poi_attribute_id'] } checked={ this.state.field___poi_attributes.includes(___p_a['poi_attribute_id']) } onInput={() => { this.handle___attribute___select(___p_a['poi_attribute_id']); }}/>
											<label htmlFor={ ___c_child___checkbox___id }>
												<i className="fas fa-check"></i>
												<i className="far fa-circle"></i>
												<span>{ ___p_a['poi_attribute_name'] }</span>
											</label>
										</>;
									})}
								</div>
							</>}
						</div>

*/}

{/**
 * 
 * 
 * 
 * 						I N D I R I Z Z O   E   P O S I Z I O N E
 * 
 * 
 * 
 */}

						<IonListHeader>
							<IonLabel>
								<i className="fas fa-map-marked-alt"></i>
								<span>Indirizzo e Posizione</span>
							</IonLabel>
						</IonListHeader>

						<div className="sb-poi-edit---container">
							{(this.state.field___poi_coords !== null) ? <>
								<p className="input---nb-label is-required">indirizzo e posizione sulla mappa</p>
								<section onClick={() => { this.setState({ ionModal___MdPositionSelectorModal___isOpen: true }); }}>
									<ComMapPreviewAddressComponent coords={ this.state.field___poi_coords } canOpenRoute={ false }/>
								</section>
								<section onClick={() => { this.setState({ ionModal___MdPositionSelectorModal___isOpen: true }); }}>
									<ComMapPreviewComponent map_coords={ this.state.field___poi_coords } canOpenRoute={ false }/>
								</section>
								<br />
								<IonCard onClick={() => { this.setState({ ionModal___MdPositionSelectorModal___isOpen: true }); }}>
									<IonList>
										<IonItem className="is-ion-item-button" button={ true }>
											<i className="fa-solid fa-map-marked-alt"></i>
											<IonLabel>Modifica posizione</IonLabel>
										</IonItem>
									</IonList>
								</IonCard>
							</> : <>
								<p className="input---nb-label is-required">inserisci la posizione sulla mappa</p>
								<IonCard onClick={() => { this.setState({ ionModal___MdPositionSelectorModal___isOpen: true }); }} id="field---poi-coords">
									<IonList>
										<IonItem className="is-ion-item-button" button={ true }>
											<i className="fa-solid fa-map-marked-alt"></i>
											<IonLabel>Inserisci posizione</IonLabel>
										</IonItem>
									</IonList>
								</IonCard>
							</>}
						</div>

{/**
 * 
 * 
 * 
 * 						C O N T A T T I
 * 
 * 
 * 
 */}

						<IonListHeader>
							<IonLabel>
								<i className="fas fa-mail-bulk"></i>
								<span>Contatti</span>
							</IonLabel>
						</IonListHeader>

						<div className="sb-poi-edit---container">

							<label htmlFor="field---poi-contact-phone">
								<p className="input---nb-label">telefono</p>
								<input type="text" id="field---poi-contact-phone" className="input---nb-outlined" placeholder="+39 333 333 3334" value={ this.state.field___poi_contact_phone } onInput={(___e: any) => { this.setState({ field___poi_contact_phone: ___e.target.value}); }}/>
							</label>

							<label htmlFor="field---poi-contact-email">
								<p className="input---nb-label">email</p>
								<input type="text" id="field---poi-contact-email" className="input---nb-outlined" placeholder="email@email.com" value={ this.state.field___poi_contact_email } onInput={(___e: any) => { this.setState({ field___poi_contact_email: ___e.target.value}); }}/>
							</label>

							<label htmlFor="field---poi-contact-phone">
								<p className="input---nb-label">sito web</p>
								<input type="text" id="field---poi-contact-phone" className="input---nb-outlined" placeholder="www.iltuosito.com" value={ this.state.field___poi_contact_website } onInput={(___e: any) => { this.setState({ field___poi_contact_website: ___e.target.value}); }}/>
							</label>

						</div>

{/**
 * 
 * 
 * 
 * 						S O C I A L
 * 
 * 
 * 
 */}

						<IonListHeader>
							<IonLabel>
								<i className="fas fa-share-alt"></i>
								<span>Social</span>
							</IonLabel>
						</IonListHeader>
						
						<div className="sb-poi-edit---container">
							<label htmlFor="field---poi-contact-social---facebook">
								<p className="input---nb-label"><i className="fab fa-facebook"></i>facebook</p>
								<input type="text" id="field---poi-contact-social---facebook" className="input---nb-outlined" placeholder="https://www.facebook.com/latuapagina" value={ this.state.field___poi_contact_socials['facebook'] } onInput={(___e: any) => { this.handle___poi_socials___edit('facebook', ___e.target.value); }}/>
							</label>
							<label htmlFor="field---poi-contact-social---instagram">
								<p className="input---nb-label"><i className="fab fa-instagram"></i>instagram</p>
								<input type="text" id="field---poi-contact-social---instagram" className="input---nb-outlined" placeholder="https://www.instagram.com/iltuoprofilo" value={ this.state.field___poi_contact_socials['instagram'] } onInput={(___e: any) => { this.handle___poi_socials___edit('instagram', ___e.target.value); }}/>
							</label>
							<label htmlFor="field---poi-contact-social---youtube">
								<p className="input---nb-label"><i className="fab fa-youtube"></i>youtube</p>
								<input type="text" id="field---poi-contact-social---youtube" className="input---nb-outlined" placeholder="https://www.youtube.com/iltuocanale" value={ this.state.field___poi_contact_socials['youtube'] } onInput={(___e: any) => { this.handle___poi_socials___edit('youtube', ___e.target.value); }}/>
							</label>
							<label htmlFor="field---poi-contact-social---tiktok">
								<p className="input---nb-label"><i className="fab fa-tiktok"></i>tiktok</p>
								<input type="text" id="field---poi-contact-social---tiktok" className="input---nb-outlined" placeholder="https://www.tiktok.com/iltuoprofilo" value={ this.state.field___poi_contact_socials['tiktok'] } onInput={(___e: any) => { this.handle___poi_socials___edit('tiktok', ___e.target.value); }}/>
							</label>
							<label htmlFor="field---poi-contact-social---twitter">
								<p className="input---nb-label"><i className="fab fa-twitter"></i>twitter</p>
								<input type="text" id="field---poi-contact-social---twitter" className="input---nb-outlined" placeholder="https://www.twitter.com/iltuoprofilo" value={ this.state.field___poi_contact_socials['twitter'] } onInput={(___e: any) => { this.handle___poi_socials___edit('twitter', ___e.target.value); }}/>
							</label>
						</div>

						<br />
						<br />
						<br />

					</>}
				</IonContent>

				<IonFooter>
					{(this.state.poi___props___is_loading === true || this.state.submitting___is_loading === true) ? <>
						<ComSpinnerInlineComponent/>
					</> : <>
						<section className="container---footer-section" onClick={ this.submit___poi }>
							<button className="is-element is-button is-color---folly is-full-width">
								<i className="fa-solid fa-check"></i>
								{(this.state.poi___id === null) ? <>
									<h4>Pubblica</h4>
								</> : <>
									<h4>Aggiorna il Poi</h4>
								</>}
							</button>
						</section>
					</>}
				</IonFooter>

			</IonPage>

		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbPoiEditPage);