import React from 'react';
import { IonCard, IonContent, IonItem, IonLabel, IonList, IonListHeader, IonModal, IonNote } from '@ionic/react';

//	S T Y L E

import './md-poi-select-category.scss';

//	S T A T E   -   P R O P S

import { props___MdPoiSelectCategoryModal, state___MdPoiSelectCategoryModal, state___MdPoiSelectCategoryModal___default } from './md-poi-select-category.state';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';
import { type___modal___config } from '../../types/types.modals';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComPoiCategoryComponent from '../../components/com-poi-category/com-poi-category';
import ComSpinnerInlineComponent from '../../components/com-spinner-inline/com-spinner-inline';

//	C L A S S

export default class MdPoiSelectCategoryModal extends React.Component<props___MdPoiSelectCategoryModal, state___MdPoiSelectCategoryModal>
{

//#region 																							C O N F I G

	private readonly MdPoiSelectCategoryModal___modal_config: type___modal___config = {
		breakpoints: [0, 1],
		backdropDismiss: true,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent(); },
		onDidDismiss: () => { this.event___onDidDismiss(); }
	};

//#endregion

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdPoiSelectCategoryModal,
	)
	{
		super(props);
		this.state = state___MdPoiSelectCategoryModal___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___poi_categories___primary = async () => {
		const ___temp___ticket_props: type___api___response = await this._API.poi_categories___read___multi(null);
		this.setState({
			poi_category___primary___collection: (___temp___ticket_props.response === 'success') ? ___temp___ticket_props['data'] : [],
			poi_category___primary___collection___is_loading: false,
		});
	}

	private read_props___poi_categories___secondary = async () => {
		const ___temp___ticket_props: type___api___response = await this._API.poi_categories___read___multi(this.state.poi_category___primary___selected['poi_category_id']);
		this.setState({
			poi_category___secondary___collection: (___temp___ticket_props.response === 'success') ? ___temp___ticket_props['data'] : [],
			poi_category___secondary___collection___is_loading: false,
		});
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___poi_category___select___primary = (___pc___p: any) => {
		this.setState({
			poi_category___secondary___collection: [],
			poi_category___secondary___collection___is_loading: true,
			poi_category___primary___selected: ___pc___p,
		}, async () => {
			await this.read_props___poi_categories___secondary();
		});
	}

	private handle___poi_category___select___secondary = (___pc___s: any) => {
		this.setState({
			poi_category___secondary___selected: ___pc___s,
		}, () => {
			this.props.event___onDidDismiss(this.state.poi_category___secondary___selected);
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	private async event___onDidPresent() : Promise<void>
	{
		await this.read_props___poi_categories___primary();
	}

	private event___onDidDismiss() : void
	{
		this.props.event___onDidDismiss(this.state.poi_category___secondary___selected);
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonModal isOpen={ this.props.isOpen } { ...this.MdPoiSelectCategoryModal___modal_config }>
				<IonContent forceOverscroll={ false }>
					<div className="md-poi-category-select---container">

						<br />

						<IonListHeader>
							<IonLabel>
								<i className="fas fa-list"></i>
								<span>Seleziona Categoria Evento</span>
							</IonLabel>
						</IonListHeader>

						<br />
						<br />
						
						{(this.state.poi_category___primary___selected === null) ? <>
							
							{(this.state.poi_category___primary___collection___is_loading === true) ? <>
								<br />
								<br />
								<ComSpinnerInlineComponent/>
							</> : <>
								{this.state.poi_category___primary___collection.map((___pc___p: any, ___pc___p___idx: number) => {
									return <>
										<IonCard key={ ___pc___p___idx } button={ true } onClick={() => { this.handle___poi_category___select___primary(___pc___p); }}>
											<IonList>
												<ComPoiCategoryComponent poi_category___props={ ___pc___p } isButton={ true }/>
											</IonList>
										</IonCard>
									</>;
								})}
							</>}

						</> : <>

							<IonItem onClick={() => { this.setState({ poi_category___primary___selected: null }); }} lines="none">
								<IonNote slot="start"><i className="fas fa-chevron-left"></i></IonNote>
								<IonLabel>torna a { this.state.poi_category___primary___selected['poi_category_name'] }</IonLabel>
							</IonItem>

							<br />

							{(this.state.poi_category___secondary___collection___is_loading === true) ? <>
								<br />
								<br />
								<ComSpinnerInlineComponent/>
							</> : <>
								{this.state.poi_category___secondary___collection.map((___pc___s: any, ___pc___s___idx: number) => {
									return <>
										<IonCard key={ ___pc___s___idx } button={ true } onClick={() => { this.handle___poi_category___select___secondary(___pc___s); }}>
											<IonList>
												<ComPoiCategoryComponent poi_category___props={ ___pc___s } isButton={ true }/>
											</IonList>
										</IonCard>
									</>;
								})}
							</>}

						</>}
						
					</div>
				</IonContent>
			</IonModal>
		</>;
	}

//#endregion

}