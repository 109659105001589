import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___MdDUserFollowsModal: type___locale___translations___collection = {
	'topBarButton___close': {
		de_de: 'Schließen',
		en_us: 'Close',
		es_es: 'Cerca',
		fr_fr: 'Fermer',
		it_it: 'Chiudi',
	},
	'title___followers': {
		de_de: 'Anhänger',
		en_us: 'Followers',
		es_es: 'Seguidores',
		fr_fr: 'Abonnés',
		it_it: 'Followers',
	},
	'title___followings': {
		de_de: 'Er Folgt',
		en_us: 'Is Following',
		es_es: 'El Esta Siguiendo',
		fr_fr: 'Il Suit',
		it_it: 'Sta seguendo',
	}
};