import React from 'react'

//	S T Y L E

import './com-chat-conversation-unread.scss'

//	S T A T E   -   P R O P S

import { props___ComChatConversationUnreadComponent } from './com-chat-conversation-unread.state';

//	C L A S S

export default class ComChatConversationUnreadComponent extends React.Component<props___ComChatConversationUnreadComponent, {}>
{

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="com-chat-conversation-unread---container">
				<div>{ this.props.message_unread_quantity.toString() }</div>
			</div>
		</>;
	}

//#endregion

}