import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComCopyrightComponent: type___locale___translations___collection = {
	'row1': {
		de_de: '© Copyright 2022 - {{1}} by PRYSM',
		en_us: '© Copyright 2022 - {{1}} by PRYSM',
		es_es: '© Copyright 2022 - {{1}} by PRYSM',
		fr_fr: '© Copyright 2022 - {{1}} by PRYSM',
		it_it: '© Copyright 2022 - {{1}} by PRYSM',
	},
	'row2': {
		de_de: 'nearby ist eine eingetragene Marke, die ausschließlich PRYSM gehört',
		en_us: 'nearby is a registered trademark owned exclusively by PRYSM',
		es_es: 'nearby es una marca registrada propiedad exclusiva de PRYSM',
		fr_fr: 'nearby est une marque déposée appartenant exclusivement à PRYSM',
		it_it: 'nearby è un marchio registrato di proprietà esclusiva di PRYSM',
	},
	'row3': {
		de_de: 'jede unerlaubte Vervielfältigung, auch teilweise, ist untersagt',
		en_us: 'any unauthorized reproduction, even partial, is prohibited',
		es_es: 'queda prohibida cualquier reproducción no autorizada, incluso parcial',
		fr_fr: 'toute reproduction non autorisée, même partielle, est interdite',
		it_it: 'ogni sua riproduzione anche parziale non autorizzata è vietata',
	},
};