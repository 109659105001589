import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComPoiDisclaimerViewComponent: type___locale___translations___collection = {
	'disclaimer': {
		de_de: 'Diese Sehenswürdigkeit und ihre Inhalte werden direkt von ihrem Eigentümer und/oder Administrator verwaltet. Nearby übernimmt daher keine Verantwortung für den Inhalt und die Richtigkeit der gemeldeten Informationen. Wenn Sie weitere Informationen benötigen oder etwas melden möchten, kontaktieren Sie uns',
		en_us: 'this point of interest and its contents are managed directly by its owner and/or administrator therefore Nearby assumes no responsibility for the contents and truthfulness of the information reported, if you need more information or want to report something contact us',
		es_es: 'este punto de interés y sus contenidos son gestionados directamente por su propietario y/o administrador por lo que Nearby no asume ninguna responsabilidad por el contenido y veracidad de la información reportada, si necesitas más información o quieres reportar algo contáctanos',
		fr_fr: 'ce point d\'intérêt et son contenu sont gérés directement par son propriétaire et/ou administrateur, donc Nearby n\'assume aucune responsabilité quant au contenu et à la véracité des informations rapportées, si vous avez besoin de plus d\'informations ou si vous souhaitez signaler quelque chose, contactez-nous',
		it_it: 'questo punto di interesse ed i suoi contenuti sono gestiti direttamente dal suo proprietario e/o amministratore per tanto Nearby non si assume nessuna responsabilità sui contenuti e sulla veridicità delle informazioni riportate, se hai bisogno di maggiori informazioni o vuoi segnalare qualcosa contattaci',
	},
};