import React from 'react'
import { IonActionSheet, IonAlert, IonToast } from '@ionic/react';
import { Link } from 'react-router-dom';

//	L O C A L I Z A T I O N

import { locale___ComPostComponent } from './com-post.locale';

//	S T Y L E

import './com-post.scss'

//	I N T E R F A C E S

import { interface___IonAlert } from '../../interfaces/interface.alert';
import { interface___IonActionSheet } from '../../interfaces/interface.actionsheet';

//	T Y P E S

import { type___action___element___like } from '../../types/types.actions';
import { type___api___response } from '../../types/types.api-response';
import { type___post___attachment_type, type___post___target } from '../../types/types.types';

//	F U N C S

import { funcs_datetime } from '../../funcs/funcs.datetime';

//	S T A T E S   -   P R O P S

import { props___ComPostComponent, state___ComPostComponent, state___ComPostComponent___default } from './com-post.state';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';
import { service_LocalizationService } from '../../services/service-localization';

//	C O M P O N E N T S

import ComPostAttachmentComponent from '../com-post-attachment/com-post-attachment';
import ComSpinnerTagComponent from '../com-spinner-tag/com-spinner-tag';
import ComTextRichComponent from '../com-text-rich/com-text-rich';

//	C L A S S

export default class ComPostComponent extends React.Component<props___ComPostComponent, state___ComPostComponent>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();

//#endregion

//#region 																							C O N F I G

	private readonly ionActionSheet___postAction___config: interface___IonActionSheet = {
		buttons: [
			{
				text: this._LOCALE.translate(locale___ComPostComponent.normal___postAction___delete),
				role: 'destructive',
				handler: () => {
					this.setState({
						ionAlert___postAction___askForClose___isOpen: true,
					});
				}
			},
			{
				text: this._LOCALE.translate(locale___ComPostComponent.normal___postAction___cancel),
				role: 'cancel',
				handler: () => {
					this.setState({
						ionActionSheet___postAction___isOpen: false,
					});
				}
			}
		]
	};

	private readonly ionAlert___postAction___askForClose___config: interface___IonAlert = {
		header: this._LOCALE.translate(locale___ComPostComponent.normal___postAction___delete___confirm_title),
		message: this._LOCALE.translate(locale___ComPostComponent.normal___postAction___delete___confirm_text),
		buttons: [
			{
				text: this._LOCALE.translate(locale___ComPostComponent.normal___postAction___delete___confirm_yes),
				handler: async () => {
					const ___post_comment___is_deleted: type___api___response = await this._API.posts___delete(this.state.post___props['post_id']);
					this.setState({
						ionToast___postAction___isDeleted: (___post_comment___is_deleted['response'] === 'success') ? true : false,
						post___is_destroyed: (___post_comment___is_deleted['response'] === 'success') ? true : false,
					}, () => {
						setTimeout(() => {
							this.setState({
								post___is_destroyed___visible: false,
							}, () => {
								if (this.props.event___onDestroy) {
									this.props.event___onDestroy();
								}
							});
						}, 1000);
					});
				}
			},
			{
				text: this._LOCALE.translate(locale___ComPostComponent.normal___postAction___delete___confirm_cancel),
				role: 'cancel',
				handler: () => {
					this.setState({
						ionAlert___postAction___askForClose___isOpen: false
					});
				}
			}
		]
	};

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComPostComponent,
	) {
		super(props);
		this.state = state___ComPostComponent___default;
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___toggle___like = (___action: type___action___element___like) => {
	//	if (this.state.post___props['post_user_is_me'] && this.state.post___props['post_user_is_me'] === true)
	//	{
			this.setState({
				post_like___is_loading: true,
			}, async () => {
				const ___post_like___temp: type___api___response = await this._API.posts_likes___toggle(this.state.post___props['post_id'], ___action);
				if (___post_like___temp && ___post_like___temp.response === 'success') {
					const ___post_props___temp: any = this.state.post___props;
					___post_props___temp['post_likes_liked'] = !(___post_props___temp['post_likes_liked'] as boolean);
					___post_props___temp['post_likes_count'] = (___action === 'like') ? ___post_props___temp['post_likes_count'] + 1 : ___post_props___temp['post_likes_count'] - 1;
					this.setState({
						post___props: ___post_props___temp,
						post_like___is_loading: false,
					});
				} else {
					this.setState({
						post_like___is_loading: false,
					});
				}
			});
	//	}
	}

//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount() : void {
		this.setState({
			post___props: this.props.post___props,
			post___props___is_loading: false,
		});
	}

	componentWillUnmount() : void {
		this.setState({
			...state___ComPostComponent___default
		});
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			{(this.state.post___props___is_loading === true) ? <></> : <>
				{(this.state.post___is_destroyed === false) ? <>

					<IonActionSheet isOpen={ this.state.ionActionSheet___postAction___isOpen } {...this.ionActionSheet___postAction___config} />
					<IonAlert isOpen={ this.state.ionAlert___postAction___askForClose___isOpen } {...this.ionAlert___postAction___askForClose___config} />
					<IonToast isOpen={(this.state.ionToast___postAction___isDeleted === false) ? true : false } message={ this._LOCALE.translate(locale___ComPostComponent.normal___postAction___delete___toast_error) } duration={ 3000 } color="danger" position="top" onDidDismiss={() => { this.setState({ ionToast___postAction___isDeleted: null }); }}/>
					<IonToast isOpen={(this.state.ionToast___postAction___isDeleted === true) ? true : false } message={ this._LOCALE.translate(locale___ComPostComponent.normal___postAction___delete___toast_ok) } duration={ 3000 } position="top" onDidDismiss={() => { this.setState({ ionToast___postAction___isDeleted: null }); }}/>

					<section className="com-post-component---container">

						<div className="com-post-component---options">
							{(
								(this.state.post___props['post_writer_is_me'] && this.state.post___props['post_writer_is_me'] === true) ||
								(this.state.post___props['post_target_is_me'] && this.state.post___props['post_target_is_me'] === true)
							) ? <>
									<i className="fas fa-ellipsis" onClick={() => { this.setState({ ionActionSheet___postAction___isOpen: true }); }}></i>
							</> : <></>}
						</div>

						{(() => { switch (this.props.post___type) {
							case 'timeline': return <>

								<section className="com-post-component---head---timeline">
									<div>
										{(this.funcs___datetime.datetime___get___date(this.props.post___props['post_datetime']) === this.funcs___datetime.datetime___get___date(new Date())) ? <>
											<span>{ this._LOCALE.translate(locale___ComPostComponent.timeline___datetime___today, [this.funcs___datetime.datetime___get___time(this.props.post___props['post_datetime'])]) }</span>
										</> : <>
											<span>{ this._LOCALE.translate(locale___ComPostComponent.timeline___datetime___at, [this.funcs___datetime.datetime___get___date(this.props.post___props['post_datetime']), this.funcs___datetime.datetime___get___time(this.props.post___props['post_datetime'])]) }</span>
										</>}
									</div>
								</section>
				
							</>; break;
							case 'normal': return <>
									
								<section className="com-post-component---head---normal">
									{(() => { switch (this.state.post___props['post_target_type'] as type___post___target) {
										case 'poi': return <>
											<div>
												<Link to={ '/view/poi/' + this.state.post___props['post_target']['poi_id'] }>
													{(this.props.post___props['post_target']['poi_gallery'] && this.props.post___props['post_target']['poi_gallery'].length > 0) ? <>
														<img src={ this.props.post___props['post_target']['poi_gallery'][0] }/>
													</> : <></>}
												</Link>
											</div>
											<h5>
												<Link to={ '/view/poi/' + this.state.post___props['post_target']['poi_id'] }>
													<span>{ this.state.post___props['post_target']['poi_name'] }</span>
												</Link>
											</h5>
										</>; break;
										case 'user': 
										case 'self': return <>
											<div>
												{(this.state.post___props['post_target'] === null || this.state.post___props['post_target_type'] === 'self') ? <>
													<Link to={ '/view/user/' + this.state.post___props['post_writer']['user_username'] }>
														<img src={ this.state.post___props['post_writer']['user_image_profile'] }/>
													</Link>
												</> : <>
													<div>
														<Link to={ '/view/user/' + this.state.post___props['post_writer']['user_username'] }>
															<img src={ this.state.post___props['post_writer']['user_image_profile'] }/>
														</Link>
														<Link to={ '/view/user/' + this.state.post___props['post_target']['user_username'] }>
															<img src={ this.state.post___props['post_target']['user_image_profile'] }/>
														</Link>
													</div>
												</>}
											</div>
											<h5>
												<Link to={ '/view/user/' + this.state.post___props['post_writer']['user_username'] }>
													<span>{ this.state.post___props['post_writer']['user_username'] }</span>
												</Link>
												{(this.state.post___props['post_target'] !== null && this.state.post___props['post_target_type'] !== 'self') ? <>
													<span className="com-post---users---separator"> ha scritto a </span>
													<Link to={ '/view/user/' + this.state.post___props['post_target']['user_username'] }>
														<span>{ this.state.post___props['post_target']['user_username'] }</span>
													</Link>
												</> : <></>}
											</h5>
										</>; break;
									}})()}
								</section>

								{/*(this.state.post___props['post_writer_is_me'] === true || this.state.post___props['post_target_is_me'] === true) ? <>
									<i className="fas fa-ellipsis-v" onClick={() => { action___action_sheet(); }}></i>
								</> : <></>*/}

							</>; break;
						}})()}

						<section className="com-post-component---content">
							<ComTextRichComponent text={ this.state.post___props['post_text'] }/>
							<ComPostAttachmentComponent post_props={ this.state.post___props } />
						</section>

						{(this.props.is_logged === true && this.props.is_logged___props !== null) ? <>
							<section className="com-post-component---bottom-bar">
								<section>
									{(this.funcs___datetime.datetime___get___date(this.state.post___props['post_datetime']) === this.funcs___datetime.datetime___get___date(new Date())) ? <>
										<span>{ this._LOCALE.translate(locale___ComPostComponent.normal___datetime_today_at, [this.funcs___datetime.datetime___get___time(this.state.post___props['post_datetime'])]) }</span>
									</> : <>
										<span>{ this._LOCALE.translate(locale___ComPostComponent.normal___datetime_date_at, [this.funcs___datetime.datetime___get___date(this.state.post___props['post_datetime']), this.funcs___datetime.datetime___get___time(this.state.post___props['post_datetime'])]) }</span>
									</>}
								</section>
									{(() => { switch (this.state.post___props['post_attachment_type'] as type___post___attachment_type) {
										case 'tale': return <>
											<section>
												<Link to={ '/show/tale/' + this.state.post___props['post_attachment_id'] }>
													<span>Vedi la Tale</span>
													<i className="fas fa-arrow-right"></i>
												</Link>
											</section>
										</>; break;
										default: return <>
											<section>
												{(() => { switch (this.state.post___props['post_likes_liked']) {
													case true: return <>
														<div onClick={() => { this.handle___toggle___like('unlike'); }}>
															{(this.state.post_like___is_loading === true) ? <>
																<ComSpinnerTagComponent/>
															</> : <>
																<i className="fas fa-heart" style={{paddingLeft:'10pt',color:'var(--color---folly)'}}></i>
																{(this.state.post___props['post_likes_count'] > 0) ? <>{ this.state.post___props['post_likes_count'] }</> : <></>}
															</>}
														</div>
													</>; break;
													case false: default: return <>
														<div onClick={() => { this.handle___toggle___like('like'); }}>
															{(this.state.post_like___is_loading === true) ? <>
																<ComSpinnerTagComponent/>
															</> : <>
																<i className="far fa-heart" style={{paddingLeft:'10pt'}}></i>
																{(this.state.post___props['post_likes_count'] > 0) ? <>{ this.state.post___props['post_likes_count'] }</> : <></>}
															</>}
														</div>
													</>; break;
												}})()}
												{(this.props.post___can_route_comments === true) ? <>
													<Link to={ '/show/post/' + this.state.post___props['post_id'] }>
														<i className="far fa-comment" style={{paddingLeft:'10pt'}}></i>
														{(this.state.post___props['post_comments_count'] > 0) ? <>{ this.state.post___props['post_comments_count'] }</> : <></>}
													</Link>
												</> : <>
													<div>
														<i className="far fa-comment" style={{paddingLeft:'10pt'}}></i>
														{(this.state.post___props['post_comments_count'] > 0) ? <>{ this.state.post___props['post_comments_count'] }</> : <></>}
													</div>
												</>}
											</section>
										</>; break;
									}})()}
							</section>
						</> : <></>}

					</section>

				</> : <>

					{(this.state.post___is_destroyed___visible === true) ? <>
						<section className="com-post-component---destroyed">
							<p>{ this._LOCALE.translate(locale___ComPostComponent.normal___post_is_deleted) }</p>
						</section>
					</> : <></>}

				</>}
			</>}
		</>;
	}

//#endregion

}