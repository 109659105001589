import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___Com404Component: type___locale___translations___collection = {
	'title': {
		de_de: 'Uuuups.....',
		en_us: 'Wooops.....',
		es_es: 'Uuuups.....',
		fr_fr: 'Oouups.....',
		it_it: 'Oooops.....',
	},
	'message': {
		de_de: '______________________',
		en_us: '______________________',
		es_es: '______________________',
		fr_fr: '______________________',
		it_it: 'L\'app non riesce ad accedere alla fotocamera del dispositivo',
	},
	'footer': {
		de_de: '______________________',
		en_us: '______________________',
		es_es: '______________________',
		fr_fr: '______________________',
		it_it: 'Sei sicuro di aver garantito l\'accesso? Se non lo hai fatto vai nelle impostazioni ed abilita l\'accesso alla fotocamera ed al microfono',
	},
	'button___retry': {
		de_de: '______________________',
		en_us: '______________________',
		es_es: '______________________',
		fr_fr: '______________________',
		it_it: 'Riprova',
	},
	'button___close': {
		de_de: '______________________',
		en_us: '______________________',
		es_es: '______________________',
		fr_fr: '______________________',
		it_it: 'Chiudi',
	},
};