//	S T A T E

export interface state___SbInfoDigitalMenuAllergensPage {

	allergens_collection: any[],
	allergens_collection___is_loading: boolean,

}

//	S T A T E   D E F A U L T

export const state___SbInfoDigitalMenuAllergensPage___default: state___SbInfoDigitalMenuAllergensPage = {

	allergens_collection: [],
	allergens_collection___is_loading: true,

}