import { RouteComponentProps } from "react-router";
import { interface___has_notifications } from "../../../interfaces/interface.notifications";

//	P R O P S

export interface props___SbArchiveNotificationsPage extends RouteComponentProps<{ id: string; }>, interface___has_notifications {

}

//	S T A T E

export interface state___SbArchiveNotificationsPage {

	notifications___count: number,

	notifications___collection: any[],
	notifications___collection___is_loading: boolean,
	notifications___collection___is_complete: boolean,

	IonActionSheet___viewAllNotifications___isOpen: boolean,

}

//	S T A T E   D E F A U L T

export const state___SbArchiveNotificationsPage___default: state___SbArchiveNotificationsPage = {

	notifications___count: 0,

	notifications___collection: [],
	notifications___collection___is_loading: true,
	notifications___collection___is_complete: false,

	IonActionSheet___viewAllNotifications___isOpen: false,

}