import { interface___is_logged } from "../../interfaces/interface.logged"
import { type___post___target } from "../../types/types.types"

//	P R O P S

export interface props___ComPostCreateComponent extends interface___is_logged {

	post_creation_target: {
		target: type___post___target,
		id: string | 'me',
		name: string,
	}

	event___onDidCreate: () => any|void|never

}

//	S T A T E

export interface state___ComPostCreateComponent {

	ionModal___MdPostsCreateModal___isOpen: boolean,

}

//	S T A T E   D E F A U L T

export const state___ComPostCreateComponent___default: state___ComPostCreateComponent = {

	ionModal___MdPostsCreateModal___isOpen: false,

}