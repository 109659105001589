import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComPoiDisclaimerEditComponent: type___locale___translations___collection = {
	'title': {
		de_de: 'Aufmerksamkeit',
		en_us: 'Attention',
		es_es: 'Atención',
		fr_fr: 'Attention',
		it_it: 'Attenzione',
	},
	'description': {
		de_de: 'Durch die Erstellung oder Änderung eines Point of Interest übernehmen Sie die volle Verantwortung für den Inhalt und erklären implizit, dass Sie Eigentümer, Rechteinhaber und/oder befugt sind, das betreffende Bild und/oder Point of Interest zu verwalten',
		en_us: 'By creating or modifying a point of interest you assume all responsibility for the contents and implicitly declare that you are the owner, the rights holder and/or that you are authorized to manage the image and/or point of interest in question',
		es_es: 'Al crear o modificar un punto de interés usted asume toda la responsabilidad por los contenidos e implícitamente declara que es el propietario, el titular de los derechos y/o que está autorizado a gestionar la imagen y/o punto de interés en cuestión.',
		fr_fr: 'En créant ou modifiant un point d\'intérêt vous assumez l\'entière responsabilité du contenu et déclarez implicitement que vous en êtes le propriétaire, le titulaire des droits et/ou que vous êtes autorisé à gérer l\'image et/ou le point d\'intérêt en question.',
		it_it: 'Creando o modificando un punto di interesse ti assumi tutte le responsabilità riguardo i contenuti e dichiari implicitamente che ne sei il proprietario, il detentore dei diritti e/o che sei autorizzato a gestire l\'immagine e/o il punto di interesse in oggetto',
	},
	'learn_more': {
		de_de: 'lesen Sie mehr über die Allgemeinen Geschäftsbedingungen...',
		en_us: 'read more about the terms and conditions...',
		es_es: 'lea más sobre los términos y condiciones...',
		fr_fr: 'en savoir plus sur les termes et conditions...',
		it_it: 'leggi altro sui termini e le condizioni...',
	},
};