import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonFooter, IonHeader, IonLabel, IonListHeader, IonPage, IonToast, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './sb-info-suggestions.scss';

//	L I B S

import { lib_names } from '../../../libs/lib.names';
import { lib_names_shared } from '../../../libs/lib.names.shared';

//	S T A T E   -   P R O P S

import { state___SbInfoSuggestionsPage, state___SbInfoSuggestionsPage___default } from './sb-info-suggestions.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';

//	C O M P O N E N T S

import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';

//	M O D A L S

import MdMediaEditorModal from '../../../modals/md-media-editor/md-media-editor';

//	C L A S S

class SbInfoSuggestionsPage extends React.Component<{}, state___SbInfoSuggestionsPage>
{

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: {},
	) {
		super(props);
		this.state = state___SbInfoSuggestionsPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___user = async () => {
		const ___temp___user___props: type___api___response = await this._API.users___read___single('me', null);
		if (!___temp___user___props || ___temp___user___props.response !== 'success' || ___temp___user___props.data === null) { console.log('404'); }
		this.setState({
			user___props: ___temp___user___props.data,
			user___props___is_loading: false,
		}, () => {
			this.setState({
				
				field___user___username: this.state.user___props['user_username'],
				field___user___name: this.state.user___props['user_name'],

				field___user___contact_email: this.state.user___props['user_contact_mail'],
				field___user___contact_phone: this.state.user___props['user_contact_phone'],

				user___props___is_loading: false,

			});
		});
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___image___fileUpload = (___target: 'cover' | 'profile', ___e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			ionModal___MdMediaEditorModal___target: ___target,
		}, () => {
			if (___e.target.files)
			{
				const ___files_array: File[] = Array.from(___e.target.files);
				if (___files_array.length > 0) {
					const ___f: File = ___files_array[0];
					const ___f___reader: FileReader = new FileReader();
					___f___reader.onloadend = () => {
						switch (this.state.ionModal___MdMediaEditorModal___target) {
							case 'cover':
								this.setState({
									ionModal___MdMediaEditorModal___argument: [___f___reader.result as string],
									ionModal___MdMediaEditorModal___aspectRatio: lib_names.aspectRatios.user___cover.ar,
									ionModal___MdMediaEditorModal___isOpen: true
								});
							break;
							case 'profile':
								this.setState({
									ionModal___MdMediaEditorModal___argument: [___f___reader.result as string],
									ionModal___MdMediaEditorModal___aspectRatio: lib_names.aspectRatios.user___profile.ar,
									ionModal___MdMediaEditorModal___isOpen: true
								});
							break;
						}
					};
					___f___reader.readAsDataURL(___f);
				}
			}
		});
	}

	private handle___image___fileEditEnd = (___retrieved_medias: string[] | null) => {
		switch (this.state.ionModal___MdMediaEditorModal___target) {
			case 'cover':
				this.setState({
					field___picture___cover: (___retrieved_medias && ___retrieved_medias.length > 0) ? ___retrieved_medias[0] : this.state.field___picture___cover,
					ionModal___MdMediaEditorModal___isOpen: false,
					ionModal___MdMediaEditorModal___aspectRatio: 0,
					ionModal___MdMediaEditorModal___argument: null,
				});
			break;
			case 'profile':
				this.setState({
					field___picture___profile: (___retrieved_medias && ___retrieved_medias.length > 0) ? ___retrieved_medias[0] : this.state.field___picture___profile,
					ionModal___MdMediaEditorModal___isOpen: false,
					ionModal___MdMediaEditorModal___aspectRatio: 0,
					ionModal___MdMediaEditorModal___argument: null,
				});
			break;
		}
	}

//#endregion

//#region 																							L I F E C Y C L E

	private submit___user_update =  () => {
		this.setState({
			user___props___is_loading: true,
		}, async () => {
			const ___user___is_submitted: type___api___response = await this._API.users___submit_changes(this.state.field___picture___cover, this.state.field___picture___profile, this.state.field___user___name, this.state.field___user___bio, this.state.field___user___contact_phone, this.state.field___user___contact_email);
			this.setState({
				ionToast___isSubmitted___result: (___user___is_submitted && ___user___is_submitted.response === 'success') ? 'success' : 'error',
			}, async () => {
				await this.read_props___user();
			});
		});

/*
	
		@Prop() user_password: string;
		
		@Prop() : string;
	
		@Prop({ unique: [ true, lib_errors.duplicated.duplicated_email ] }) user_contact_mail: string;
		@Prop() user_contact_phone: string;
	
		@Prop() user_profile_verified: boolean;
		@Prop() user_profile_public: boolean;
	
		@Prop(raw([{ type: { type: String, default: 'Point' }, coordinates: { type: [Number, Number], default: [0, 0] } }])) user_positions: { type: string, coordinates: [number, number] }[];
	
*/

	}

//#endregion

//#region 																							S U B M I T

	async ionViewWillEnter() : Promise<void> {
		await this.read_props___user();
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				{(this.state.ionModal___MdMediaEditorModal___argument !== null && this.state.ionModal___MdMediaEditorModal___aspectRatio > 0) ? <>
					<MdMediaEditorModal isOpen={ this.state.ionModal___MdMediaEditorModal___isOpen } input___aspect_ratio={ this.state.ionModal___MdMediaEditorModal___aspectRatio } input___image_list={ this.state.ionModal___MdMediaEditorModal___argument } event___onDidDismiss={ this.handle___image___fileEditEnd } />
				</> : <></>}

				<IonToast isOpen={ this.state.ionToast___isSubmitted___result === 'success' } message="Il salvataggio è andato a buon fine" duration={ 3000 } color='success' position='top' onDidDismiss={() => { this.setState({ ionToast___isSubmitted___result: false }); }}/>
				<IonToast isOpen={ this.state.ionToast___isSubmitted___result === 'error' } message="Errore nel salvataggio " duration={ 3000 } color='danger' position='top' onDidDismiss={() => { this.setState({ ionToast___isSubmitted___result: false }); }}/>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/users/list"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					
					
					

				</IonContent>

				<IonFooter>
					
					




					
				</IonFooter>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbInfoSuggestionsPage);