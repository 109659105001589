//	P R O P S

export interface props___ComButtonShareComponent {

	title: string,
	url_part: string,

}

//	S T A T E

export interface state___ComButtonShareComponent {

	ionToast___is_shared___isVisible: boolean,

}

//	S T A T E   D E F A U L T

export const state___ComButtonShareComponent___default: state___ComButtonShareComponent = {

	ionToast___is_shared___isVisible: false,
}