import { interface___modal } from "../../interfaces/interface.modals";

//	P R O P S

export interface props___ComTaleViewInner extends interface___modal {

	tale_id: string,

}

//	S T A T E

export interface state___ComTaleViewInner {

	tale___props: any | null,
	tale___props___is_loading: boolean,

	tale_poi___props: any | null,
	tale_poi___props___is_loading: boolean,

	tale_like___is_loading: boolean,

	tale_message___is_writing: boolean,
	tale_message___is_sending: boolean,
	tale_message___text: string,

	ionActionSheet___taleActions___isOpen: boolean,
	ionAlert___taleRemove___isOpen: boolean,
	ionToast___taleAction___isDeleted: boolean | null,

}

//	S T A T E   D E F A U L T

export const state___ComTaleViewInner___default: state___ComTaleViewInner = {
	
	tale___props: null,
	tale___props___is_loading: true,

	tale_poi___props: null,
	tale_poi___props___is_loading: true,

	tale_like___is_loading: false,

	tale_message___is_writing: false,
	tale_message___is_sending: false,
	tale_message___text: '',

	ionActionSheet___taleActions___isOpen: false,
	ionAlert___taleRemove___isOpen: false,
	ionToast___taleAction___isDeleted: null,

}