import React from 'react'

//	L O C A L I Z A T I O N

import { locale___ComEventInviteComponent } from './com-event-invite.locale';

//	S T Y L E

import './com-event-invite.scss'

//	S T A T E S   -   P R O P S

import { props___ComEventInviteComponent } from './com-event-invite.state';

//	F U N C S

import { funcs_datetime } from '../../funcs/funcs.datetime';

//	S E R V I C E S 

import { service_LocalizationService } from '../../services/service-localization';
import { Link } from 'react-router-dom';

//	C L A S S

export default class ComEventInviteComponent extends React.Component<props___ComEventInviteComponent, {}>
{

//#region 																							C O N S T R U C T O R

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<section className="com-event-invite---container">
	
				<i className="far fa-envelope-open"></i>
				<h6>{ this._LOCALE.translate(locale___ComEventInviteComponent.you_re_invited) }</h6>
				<p>
					<span>{ this._LOCALE.translate(locale___ComEventInviteComponent.you_been_invited_date, [this.funcs___datetime.datetime___get___date(this.props.invite_props['invite_created_datetime'])]) } </span>
					{(this.props.invite_props['invite_sender'] !== null) ? <>
						<span>{ this._LOCALE.translate(locale___ComEventInviteComponent.from_who) } </span>
						<Link to={ '/view/user/' + this.props.invite_props['invite_sender']['user_username'] }>{ this.props.invite_props['invite_sender']['user_username'] }</Link>
					</> : <></>}
				</p>

				{/*
				 * 
				 * 				event invite expire
				 * 
				 */}

			</section>
		</>;
	}

//#endregion

}