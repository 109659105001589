import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonFooter, IonHeader, IonPage, IonRefresher, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './sb-element-post.scss';

//	S T A T E   -   P R O P S

import { props___SbElementPostPage, state___SbElementPostPage, state___SbElementPostPage___default } from './sb-element-post.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	F U N C S

import { funcs_text } from '../../../funcs/funcs.text';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';
import { service_RestApiService } from '../../../services/service-api';
import { service_Router } from '../../../services/service-router';

//	C O M P O N E N T S

import Com404Component from '../../../components/com-404/com-404';
import ComInfiniteScrollerComponent from '../../../components/com-infinite-scroller/com-infinite-scroller';
import ComPostComponent from '../../../components/com-post/com-post';
import ComPostCommentComponent from '../../../components/com-post-comment/com-post-comment';
import ComSpinnerInlineComponent from '../../../components/com-spinner-inline/com-spinner-inline';
import ComSpinnerRefresherComponent from '../../../components/com-spinner-refresher/com-spinner-refresher';

//	C L A S S

class SbElementPostPage extends React.Component<props___SbElementPostPage, state___SbElementPostPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _ROUTER: service_Router = new service_Router();

	private readonly funcs___text: funcs_text = new funcs_text();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbElementPostPage,
	) {
		super(props);
		this.state = state___SbElementPostPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___post = async () => {
		const ___temp___poi_collection___props: type___api___response = await this._API.posts___read___single(this.state.post___id);
		this.setState({
			post___props: ___temp___poi_collection___props.data,
			post___props___is_loading: false,
		}, async () => {
			await this.read_props___post_comments();
		});
	}

	private read_props___post_comments = async () => {
		const ___temp___post_comments_collection___props: type___api___response = await this._API.posts_comments___read___multi(this.state.post___id, this.state.post_comments___collection.length);
		this.setState((prevState) => ({
			post_comments___collection: [...prevState.post_comments___collection, ...___temp___post_comments_collection___props.data],
			post_comments___collection___is_loading: false,
			post_comments___collection___is_complete: (___temp___post_comments_collection___props.response === 'no-data') ? true : false,
		}), () => {
			if (this.state.post_comments___collection___is_scrolled_down === false) {
				this.handle___post_comments_section___scrollToBottom();
				this.setState({
					post_comments___collection___is_scrolled_down: true,
				});
			}
		});
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___page___refresh = async (___e: CustomEvent<RefresherEventDetail>) => {
		this.setState({
			post___props: null,
			post___props___is_loading: true,
			post_comments___collection: [],
			post_comments___collection___is_loading: true,
			post_comments___collection___is_complete: false,
			post_comments___collection___is_scrolled_down: false,
		}, async () => {
			await this.read_props___post();
			___e.detail.complete();
		});
	}

	private handle___post_comment___submit = () => {
		this.setState({
			field___new_comment___is_submitting: true,
		}, async () => {
			const ___post_comment___is_submitting: type___api___response = await this._API.posts_comments___submit(this.state.post___id, this.state.field___new_comment___text);
			this.setState({
				field___new_comment___text: '',
				field___new_comment___is_submitting: false,
			}, () => {
				this.handle___post_comments_section___scrollToBottom();
			});
		});
	}

	private handle___post_comments_section___scrollToBottom = () => {
	//	const ___post_comments___section: HTMLElement = document.getElementById('sb-element-post---ionContent');
	//	___post_comments___section.scrollTop = ___post_comments___section.scrollHeight;
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		const ___post_id: string | null = this._ROUTER.get_id(this.props);
		if (___post_id) {
			this.setState({
				post___id: ___post_id,
			}, async () => {
				await this.read_props___post();
			});
		}
	}

	ionViewWillLeave() : void {
		this.setState({
			...state___SbElementPostPage___default
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/discover"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					<IonRefresher slot="fixed" onIonRefresh={ this.handle___page___refresh }>
						<ComSpinnerRefresherComponent/>
					</IonRefresher>

					{(this.state.post___props___is_loading) ? <>

						<ComSpinnerInlineComponent/>

					</> : <>
						{(this.state.post___props !== null) ? <>
							<div className="sb-page---container sb-element-post---container">

								<section className="sb-element-post---container---post">
									<ComPostComponent post___type="normal" post___props={ this.state.post___props } is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props } post___can_route_comments={ false }/>
								</section>

								<section className="sb-element-post---container---comments">
									{(this.state.post_comments___collection___is_complete !== true) ? <>
										<ComInfiniteScrollerComponent is_loading={ this.state.post_comments___collection___is_loading } event___scrollTriggered={ this.read_props___post_comments } />
									</> : <></>}
									<section>
										{this.state.post_comments___collection.map((___p_c: any, ___p_c___idx: number) => {
											return <>
												<ComPostCommentComponent key={ ___p_c___idx } post_comment___props={ ___p_c } is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props }/>
											</>;
										})}
									</section>
								</section>

							</div>
						</> : <>
							<Com404Component/>
						</>}
					</>}

				</IonContent>

				{(this.state.post___props !== null) ? <>
					<IonFooter>
						<section className="container---footer-section---text-input">
							{(this.state.field___new_comment___is_submitting === true) ? <>
								<ComSpinnerInlineComponent />
							</> : <>
								<textarea id="field---poi-description" className="input---nb-outlined" placeholder="Scrivi un commento..." value={ this.state.field___new_comment___text } onInput={(___e: any) => { this.setState({ field___new_comment___text: this.funcs___text.text_input___process(___e.target.value) }); }}></textarea>
								{(this.state.field___new_comment___text.length > 0) ? <>
									<i className="fa-solid fa-check" onClick={() => { this.handle___post_comment___submit(); }}></i>
								</> : <>
									<i className="fa-solid fa-check" style={{opacity:'0.5',filter:'grayscale(1)'}}></i>
								</>}
							</>}
						</section>
					</IonFooter>
				</> : <></>}

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbElementPostPage);