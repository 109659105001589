import React from 'react';
import { IonContent, IonModal } from '@ionic/react';

//	L O C A L I Z A T I O N

import { locale___MdEntryDownloadAppModal } from './md-entry-download-app.locale';

//	S T Y L E

import './md-entry-download-app.scss';

//	L I B S

import { lib_names } from '../../libs/lib.names';

//	T Y P E S

import { type___modal___config } from '../../types/types.modals';

//	S T A T E   -   P R O P S

import { props___MdEntryDownloadAppModal, state___MdEntryDownloadAppModal, state___MdEntryDownloadAppModal___default } from './md-entry-download-app.state';

//	F U N C S

import { funcs_platform } from '../../funcs/funcs.platform';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C O M P O N E N T S

import ComSpinnerInlineComponent from '../../components/com-spinner-inline/com-spinner-inline';

//	C L A S S

export default class MdEntryDownloadAppModal extends React.Component<props___MdEntryDownloadAppModal, state___MdEntryDownloadAppModal>
{

//#region 																							C O N F I G

	private readonly MdEntryDownloadAppModal___modal_config: type___modal___config = {
		backdropDismiss: false,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent(); }
	};

//#endregion

//#region 																							D E C L A R A T I O N S
	
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

	private readonly funcs___platform: funcs_platform = new funcs_platform();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdEntryDownloadAppModal,
	) {
		super(props);
		this.state = state___MdEntryDownloadAppModal___default;
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___close_modal = () => {
		localStorage.setItem(lib_names.localStorage.user___download_app, lib_names.localStorage.user___download_app);
		this.props.event___onDidDismiss();
	}

//#endregion

//#region 																							L I F E C Y C L E

	private event___onDidPresent = () => {
		const ___platform___type: 'ios' | 'android' | null = this.funcs___platform.get_type();
		this.setState({
			platform_type: ___platform___type,
			platform_type___is_loading: false,
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonModal isOpen={ this.props.isOpen } { ...this.MdEntryDownloadAppModal___modal_config }>
				<IonContent forceOverscroll={ false }>
					<section className="md-entry-download-app---container">
						{(this.state.platform_type___is_loading === true) ? <>
						
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<ComSpinnerInlineComponent/>
						
						</> : <>
						
							<img src="/assets/logo.horizontal.svg"/>
						
							<h4>{ this._LOCALE.translate(locale___MdEntryDownloadAppModal.description) }</h4>

							<br />

							{(this.state.platform_type === 'android' || this.state.platform_type === null) ? <>
								<a target="_blank" download={ true } href="/downloads/apks/nearby-v1.1.0-b1195-pre_release.apk">
									<img src="/assets/stores/play-store.svg"/>
								</a>
							</> : <></>}
						
							{(this.state.platform_type === null) ? <>
								<p>{ this._LOCALE.translate(locale___MdEntryDownloadAppModal.link_or) }</p>
							</> : <></>}

							{(this.state.platform_type === 'ios' || this.state.platform_type === null) ? <>
								<a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSfxS_jhNjs_7LZf7rxqr-bIlryFt5WkWCGVQPEwakz5nGV0lw/viewform">
									<img src="/assets/stores/app-store.svg"/>
								</a>
							</> : <></>}

							<br />

							<h6 onClick={ this.handle___close_modal }>
								{ this._LOCALE.translate(locale___MdEntryDownloadAppModal.close) }
							</h6>
							
						</>}
					</section>
				</IonContent>
			</IonModal>
		</>;
	}

//#endregion

}