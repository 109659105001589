import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComUserVerifiedComponent: type___locale___translations___collection = {
	'user_verified_popup': {
		de_de: 'Dies ist ein verifiziertes Profil. Verifizierte Profile gehören verifizierten Persönlichkeiten des öffentlichen Lebens, Influencern, Organisationen und/oder Unternehmen',
		en_us: 'This is a verified profile. Verified profiles belong to verified public figures, influencers, organizations and/or companies',
		es_es: 'Este es un perfil verificado. Los perfiles verificados pertenecen a figuras públicas, personas influyentes, organizaciones y/o empresas verificadas.',
		fr_fr: 'Ceci est un profil vérifié. Les profils vérifiés appartiennent à des personnalités publiques, des influenceurs, des organisations et/ou des entreprises vérifiées',
		it_it: 'Questo è un profilo verificato. I profili verificati appartengono a personaggi pubblici, influencer, organizzazioni e/o aziende verificate',
	},
	'user_verified_popup_link': {
		de_de: 'Erfahren Sie mehr über die Verifizierung',
		en_us: 'Learn more about verification',
		es_es: 'Más información sobre la verificación',
		fr_fr: 'En savoir plus sur la vérification',
		it_it: 'Scopri di più sulla verifica',
	}
};