import { RouteComponentProps } from "react-router";
import { interface___is_logged } from "../../../interfaces/interface.logged";

//	P R O P S

export interface props___SbDigitalMenuEditPage extends RouteComponentProps<{ id: string; }>, interface___is_logged {

}

//	S T A T E

export interface state___SbDigitalMenuEditPage {

	digitalmenu___id: string,
	digitalmenu___props: any | null,
	digitalmenu___props___is_loading: boolean,

	











	ionModal___MdDigitalMenuDishEditModal___isOpen: boolean,
	ionModal___MdDigitalMenuDishEditModal___target_id: string | null,
	ionModal___MdDigitalMenuDishEditModal___target_category: string | null,

}

//	S T A T E   D E F A U L T

export const state___SbDigitalMenuEditPage___default: state___SbDigitalMenuEditPage = {

	digitalmenu___id: '',
	digitalmenu___props: null,
	digitalmenu___props___is_loading: true,









	ionModal___MdDigitalMenuDishEditModal___isOpen: false,
	ionModal___MdDigitalMenuDishEditModal___target_id: null,
	ionModal___MdDigitalMenuDishEditModal___target_category: null,




	
}