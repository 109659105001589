import { RouteComponentProps } from "react-router";

//	P R O P S

export interface props___SbSettingsAccountPage extends RouteComponentProps<{ id: string; }> {

}

//	S T A T E

export interface state___SbSettingsAccountPage {

	user___props: any | null,
	user___props___is_loading: boolean,
	
	field___user___contact_email: string,
	field___user___contact_phone: string,

	


	ionToast___user_is_submitted___result: 'success' | 'error' | false,






}

//	S T A T E   D E F A U L T

export const state___SbSettingsAccountPage___default: state___SbSettingsAccountPage = {

	user___props: null,
	user___props___is_loading: true,

	field___user___contact_email: '',
	field___user___contact_phone: '',




	ionToast___user_is_submitted___result: false,

	






}