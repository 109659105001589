import { type___locale___translations___collection } from "../../types/types.locale";

//	E X P O R T   L O C A L E

export const locale___SbSignPage: type___locale___translations___collection = {
	'section___login': {
		de_de: 'Login',
		en_us: 'Login',
		es_es: 'Acceso',
		fr_fr: 'Se connecter',
		it_it: 'Login',
	},
	'section___register': {
		de_de: 'Registrieren',
		en_us: 'Register',
		es_es: 'Registro',
		fr_fr: 'Registre',
		it_it: 'Registrati',
	},
	'toastSuccess___signSuccess___login': {
		de_de: 'Sie haben sich erfolgreich angemeldet',
		en_us: 'You have logged in successfully',
		es_es: 'Has iniciado sesión correctamente',
		fr_fr: 'Vous vous êtes connecté avec succès',
		it_it: 'Ti sei loggato con successo',
	},
	'toastError___login___username': {
		de_de: 'Benutzername nicht erkannt',
		en_us: 'Username not recognized',
		es_es: 'Nombre de usuario no reconocido',
		fr_fr: 'Nom d\'utilisateur non reconnu',
		it_it: 'Username non riconosciuto',
	},
	'toastError___login___password': {
		de_de: 'Falsches Passwort',
		en_us: 'Wrong Password',
		es_es: 'Contraseña incorrecta',
		fr_fr: 'Mauvais mot de passe',
		it_it: 'Password sbagliata',
	},
	'toastError___login___generic': {
		de_de: 'Allgemeiner Fehler beim Anmelden. Wenn das Problem weiterhin besteht, wenden Sie sich an den Support',
		en_us: 'Generic error during login. If the problem persists, contact support.',
		es_es: 'Error genérico al iniciar sesión, si el problema persiste contacte con soporte',
		fr_fr: 'Erreur générique lors de la connexion, si le problème persiste, contactez le support',
		it_it: 'Errore generico durante, il login, se il problema persiste contatta l\'assistenza',
	},
	'toastError___register___email': {
		de_de: 'Ungültige oder bereits verwendete E-Mail',
		en_us: 'Invalid or already used email',
		es_es: 'Correo electrónico no válido o ya utilizado',
		fr_fr: 'Email invalide ou déjà utilisé',
		it_it: 'Email non valida o già utilizzata',
	},
	'toastError___register___username': {
		de_de: 'Ungültiger oder bereits verwendeter Benutzername',
		en_us: 'Invalid or already used username',
		es_es: 'Nombre de usuario no válido o ya utilizado',
		fr_fr: 'Nom d\'utilisateur invalide ou déjà utilisé',
		it_it: 'Username non valido o già utilizzato',
	},
	'toastError___register___generic': {
		de_de: 'Genereller Fehler bei der Registrierung, versuchen Sie es später noch einmal...',
		en_us: 'Generic error during registration, try again later...',
		es_es: 'Error genérico durante el registro, inténtalo de nuevo más tarde...',
		fr_fr: 'Erreur générique lors de l\'inscription, réessayez plus tard...',
		it_it: 'Errore generico durante la registrazione, riprova più tardi...',
	},
	'login___form___placeholder___username': {
		de_de: 'nutzername',
		en_us: 'username',
		es_es: 'nombre de usuario',
		fr_fr: 'nom d\'utilisateur',
		it_it: 'username',
	},
	'login___form___placeholder___password': {
		de_de: 'passwort',
		en_us: 'password',
		es_es: 'contraseña',
		fr_fr: 'mot de passe',
		it_it: 'password',
	},
	'login___form___password_forgot': {
		de_de: 'Haben Sie Ihr Passwort vergessen?',
		en_us: 'Forgot your password?',
		es_es: '¿Olvidaste tu contraseña?',
		fr_fr: 'Avez-vous oublié votre mot de passe?',
		it_it: 'Hai dimenticato la password?'
	},
	'login___form___eula': {
		de_de: 'Mit der Anmeldung auf der Plattform akzeptiere ich ausdrücklich und uneingeschränkt alle',
		en_us: 'By logging into the platform, I explicitly and fully accept all',
		es_es: 'Al iniciar sesión en la plataforma acepto explícita y plenamente a todos.',
		fr_fr: 'En me connectant à la plateforme, j\'accepte explicitement et pleinement tout le monde',
		it_it: 'Effettuando il login nella piattaforma accetto esplicitamente ed integralmente tutti'
	},
	'login___form___eula_url': {
		de_de: 'die auf dieser Seite einsehbaren Allgemeinen Geschäftsbedingungen',
		en_us: 'the Terms and Conditions viewable on this page',
		es_es: 'los Términos y condiciones visibles en esta página',
		fr_fr: 'les Conditions Générales consultables sur cette page',
		it_it: 'i Termini e le Condizioni visionabili in questa pagina'
	},
	'login___form___button___disabled': {
		de_de: 'Benutzername und Passwort fehlen',
		en_us: 'Username and password are missing',
		es_es: 'Faltan nombre de usuario y contraseña',
		fr_fr: 'Le nom d\'utilisateur et le mot de passe sont manquants',
		it_it: 'Mancano username e password'
	},
	'login___form___button___enabled': {
		de_de: 'Anmeldung',
		en_us: 'Login',
		es_es: 'Acceso',
		fr_fr: 'Se connecter',
		it_it: 'Login'
	},
	'login___form___button___missing_eula': {
		de_de: 'Akzeptieren Sie die Allgemeinen Geschäftsbedingungen',
		en_us: 'accept the terms and conditions',
		es_es: 'aceptar los términos y condiciones',
		fr_fr: 'accepter les termes et conditions',
		it_it: 'accetta i termini e le condizioni'
	},
	'register___form___age___paragraph': {
		de_de: 'Die Anmeldung steht allen Personen ab 14 Jahren offen',
		en_us: 'Registration is open to anyone over the age of 14',
		es_es: 'La inscripción está abierta a cualquier persona mayor de 14 años',
		fr_fr: 'L\'inscription est ouverte à toute personne âgée de plus de 14 ans',
		it_it: 'La registrazione è aperta per tutti i maggiori di 14 anni',
	},
	'register___form___age___paragraph_url': {
		de_de: 'Lesen Sie mehr in den Allgemeinen Geschäftsbedingungen',
		en_us: 'read more in the terms and conditions',
		es_es: 'leer más en los términos y condiciones',
		fr_fr: 'en savoir plus dans les termes et conditions',
		it_it: 'leggi altro nei termini e condizioni',
	},
	'register___form___button_age___enabled': {
		de_de: 'Alter bestätigen',
		en_us: 'Confirm age',
		es_es: 'Confirmar edad',
		fr_fr: 'Confirmer l\'âge',
		it_it: 'Conferma età',
	},
	'register___form___button_age___too_young': {
		de_de: 'Du bist zu jung...',
		en_us: 'You\'re too young...',
		es_es: 'Eres demasiado joven...',
		fr_fr: 'Tu es trop jeune...',
		it_it: 'Sei troppo piccolo...',
	},
	'register___form___placeholder___email': {
		de_de: 'email',
		en_us: 'email',
		es_es: 'email',
		fr_fr: 'email',
		it_it: 'email',
	},
	'register___form___placeholder___username': {
		de_de: 'nutzername',
		en_us: 'username',
		es_es: 'nombre de usuario',
		fr_fr: 'nom d\'utilisateur',
		it_it: 'username',
	},
	'register___form___placeholder___password': {
		de_de: 'passwort',
		en_us: 'password',
		es_es: 'contraseña',
		fr_fr: 'mot de passe',
		it_it: 'password',
	},
	'register___form___eula': {
		de_de: 'Mit der Registrierung auf der Plattform akzeptiere ich ausdrücklich und uneingeschränkt alle',
		en_us: 'By registering on the platform I explicitly and fully accept everyone',
		es_es: 'Al registrarme en la plataforma acepto explícita y plenamente a todos',
		fr_fr: 'En m\'inscrivant sur la plateforme j\'accepte explicitement et pleinement tout le monde',
		it_it: 'Effettuando la registrazione nella piattaforma accetto esplicitamente ed integralmente tutti'
	},
	'register___form___eula_url': {
		de_de: 'die auf dieser Seite einsehbaren Allgemeinen Geschäftsbedingungen',
		en_us: 'the Terms and Conditions viewable on this page',
		es_es: 'los Términos y condiciones visibles en esta página',
		fr_fr: 'les Conditions Générales consultables sur cette page',
		it_it: 'i Termini e le Condizioni visionabili in questa pagina'
	},
	'register___form___button___disabled': {
		de_de: 'Füllen Sie alle Felder aus',
		en_us: 'Fill in all fields',
		es_es: 'Complete todos los campos',
		fr_fr: 'Remplissez tous les champs',
		it_it: 'Compila tutti i campi',
	},
	'register___form___button___enabled': {
		de_de: 'Registrieren',
		en_us: 'Register',
		es_es: 'Registro',
		fr_fr: 'Registre',
		it_it: 'Registrati',
	},
	'register___form___button___missing_eula': {
		de_de: 'Akzeptieren Sie die Allgemeinen Geschäftsbedingungen',
		en_us: 'accept the terms and conditions',
		es_es: 'aceptar los términos y condiciones',
		fr_fr: 'accepter les termes et conditions',
		it_it: 'accetta i termini e le condizioni'
	},
};