import React from 'react';
import { IonCard, IonContent, IonLabel, IonList, IonListHeader, IonModal } from '@ionic/react';

//	S T Y L E

import './md-event-select-category.scss';

//	S T A T E   -   P R O P S

import { props___MdEventSelectCategoryModal, state___MdEventSelectCategoryModal, state___MdEventSelectCategoryModal___default } from './md-event-select-category.state';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';
import { type___modal___config } from '../../types/types.modals';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComEventCategoryComponent from '../../components/com-event-category/com-event-category';
import ComSpinnerInlineComponent from '../../components/com-spinner-inline/com-spinner-inline';

//	C L A S S

export default class MdEventSelectCategoryModal extends React.Component<props___MdEventSelectCategoryModal, state___MdEventSelectCategoryModal>
{

//#region 																							C O N F I G

	private readonly MdEventSelectCategoryModal___modal_config: type___modal___config = {
		breakpoints: [0, 1],
		backdropDismiss: true,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent(); },
		onDidDismiss: () => { this.event___onDidDismiss(); }
	};

//#endregion

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdEventSelectCategoryModal,
	)
	{
		super(props);
		this.state = state___MdEventSelectCategoryModal___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___events_categories = async () => {
		const ___temp___ticket_props: type___api___response = await this._API.events_categories___read___multi();
		this.setState({
			event_category___collection: (___temp___ticket_props.response === 'success') ? ___temp___ticket_props['data'] : [],
			event_category___collection___is_loading: false,
		});
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___event_category___select = (___ec___s: any) => {
		this.setState({
			event_category___selected: ___ec___s,
		}, () => {
			this.props.event___onDidDismiss(this.state.event_category___selected);
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	private async event___onDidPresent() : Promise<void>
	{
		await this.read_props___events_categories();
	}

	private event___onDidDismiss() : void
	{
		this.props.event___onDidDismiss(this.state.event_category___selected);
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonModal isOpen={ this.props.isOpen } { ...this.MdEventSelectCategoryModal___modal_config }>
				<IonContent forceOverscroll={ false }>
					<div className="md-poi-category-select---container">

						<br />

						<IonListHeader>
							<IonLabel>
								<i className="fas fa-list"></i>
								<span>Seleziona Categoria Evento</span>
							</IonLabel>
						</IonListHeader>

						<br />
						<br />
						
						
						{(this.state.event_category___collection___is_loading === true) ? <>
							<br />
							<br />
							<ComSpinnerInlineComponent/>
						</> : <>
							{this.state.event_category___collection.map((___e_c: any, ___e_c___idx: number) => {
								return <>
									<IonCard key={ ___e_c___idx } button={ true } onClick={() => { this.handle___event_category___select(___e_c); }}>
										<IonList>
											<ComEventCategoryComponent event_category___props={ ___e_c } isButton={ true }/>
										</IonList>
									</IonCard>
								</>;
							})}
						</>}
						
					</div>
				</IonContent>
			</IonModal>
		</>;
	}

//#endregion

}