import React from 'react'
import { IonCard } from '@ionic/react';
import { Link } from 'react-router-dom';

//	L O C A L I Z A T I O N

import { locale___ComPoiDisclaimerEditComponent } from './com-poi-disclaimer-edit.locale';

//	S T Y L E

import './com-poi-disclaimer-edit.scss'

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C L A S S

export default class ComPoiDisclaimerEditComponent extends React.Component<{}, {}>
{

//#region 																							C O N S T R U C T O R
		
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonCard className="com-poi-disclaimer-edit---component" color="danger">
				<h6>{ this._LOCALE.translate(locale___ComPoiDisclaimerEditComponent.title) }</h6>
				<span>{ this._LOCALE.translate(locale___ComPoiDisclaimerEditComponent.description) }</span>
				<Link to="/info/eula">{ this._LOCALE.translate(locale___ComPoiDisclaimerEditComponent.learn_more) }</Link>
			</IonCard>
		</>;
	}

//#endregion

}