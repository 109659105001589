import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonRefresher, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';
import { Link } from 'react-router-dom';

//	S T Y L E

import './sb-catalog-carshub-view.scss';

//	S T A T E   -   P R O P S

import { props___SbCatalogCarsHubViewPage, state___SbCatalogCarsHubViewPage, state___SbCatalogCarsHubViewPage___default } from './sb-catalog-carshub-view.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';

//	C O M P O N E N T S

import ComInfiniteScrollerComponent from '../../../components/com-infinite-scroller/com-infinite-scroller';
import ComListEmptyComponent from '../../../components/com-list-empty/com-list-empty';
import ComPoiComponent from '../../../components/com-poi/com-poi';
import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';
import ComSpinnerInlineComponent from '../../../components/com-spinner-inline/com-spinner-inline';
import ComPoiCatalogCarsHubVehicleComponent from '../../../components/com-catalog-item/com-catalog-item';
import { type___catalog___item } from '../../../types/types.catalog';

//	C L A S S

class SbCatalogCarsHubViewPage extends React.Component<props___SbCatalogCarsHubViewPage, state___SbCatalogCarsHubViewPage>
{

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbCatalogCarsHubViewPage,
	)
	{
		super(props);
		this.state = state___SbCatalogCarsHubViewPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___poi = async () => {
		const ___temp___poi___props: type___api___response = await this._API.poi___read___single(this.state.poi___id);
		if (!___temp___poi___props || ___temp___poi___props.response !== 'success' || ___temp___poi___props.data === null) { console.log('404'); }
		this.setState({
			poi___props: ___temp___poi___props.data,
			poi___props___is_loading: false,
		}, async () => {
			await this.read_props___poi_catalog_vehicles();
		});
	}

	private read_props___poi_catalog_vehicles = async () => {
		const ___temp___poi_feed: type___api___response = await this._API.catalog_carshub___vehicles___read___multi(this.state.poi___props['poi_id'], this.state.poi_catalog_vehicles___collection.length);
		
		
		console.log('___temp___poi_feed',___temp___poi_feed)
		
		
		this.setState((prevState) => ({
			poi_catalog_vehicles___collection: [...prevState.poi_catalog_vehicles___collection, ...___temp___poi_feed.data],
			poi_catalog_vehicles___collection___is_loading: false,
			poi_catalog_vehicles___collection___is_complete: (___temp___poi_feed.response === 'no-data') ? true : false,
		}));
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___page___refresh = async (___e: CustomEvent<RefresherEventDetail>) => {
		await this.read_props___poi();
		___e.detail.complete();
	}

//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount() : void
	{
		if (this.props.match.params && this.props.match.params.id)
		{
			this.setState({
				poi___id: this.props.match.params.id,
			}, async () => {
				await this.read_props___poi();
			});
		}
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref={ '/view/poi' + this.state.poi___id }></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					{(this.state.poi___props___is_loading === true) ? <>

						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<ComSpinnerComponent size="small"/>

					</> : <>

						<IonRefresher slot="fixed" onIonRefresh={ this.handle___page___refresh }>
							<ComSpinnerInlineComponent/>
						</IonRefresher>

						<div className="sb-page---container sb-poi-view-feed---container">

							<ComPoiComponent poi_props={ this.state.poi___props } size='large'/>

							<br />

							{this.state.poi_catalog_vehicles___collection.map((___p_c_v: type___catalog___item, ___p_c_v___idx: number) => { return <>
								<Link to={ '/catalog/carshub/vehicle/' + ___p_c_v['item_id'] }>
									<ComPoiCatalogCarsHubVehicleComponent key={ ___p_c_v___idx } item_props={ ___p_c_v }/>
								</Link>
								<br />
						
						
						
						
							</>})}









							{(this.state.poi_catalog_vehicles___collection___is_complete === true) ? <>
								<ComListEmptyComponent list_items_quantity={ this.state.poi_catalog_vehicles___collection.length }/>
							</> : <>
								<ComInfiniteScrollerComponent is_loading={ this.state.poi_catalog_vehicles___collection___is_loading } event___scrollTriggered={ this.read_props___poi_catalog_vehicles } />
							</>}

						</div>
					</>}

				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbCatalogCarsHubViewPage);