//	L I B S

import { lib_names } from '../libs/lib.names';

//	S E R V I C E S

import { service_RestApiService } from './service-api';

//	E X P O R T

export class service_RestAuthLogoutService
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							P E R F O R M   L O G O U T

	async perform___logout() : Promise<void>
	{
		localStorage.removeItem(lib_names.localStorage.user___cookies_accepted);
		localStorage.removeItem(lib_names.localStorage.user___logintoken);

		const ____notification_token: string = localStorage.getItem(lib_names.localStorage.user___notification_token);
		await this._API.notifications___client___unregister(____notification_token);

		localStorage.removeItem(lib_names.localStorage.user___notification_token);
		
		window.location.href = '/';

	}

//#endregion

}