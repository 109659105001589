import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonNote, IonPage, IonRange, IonRefresher, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';
import { Link } from 'react-router-dom';

//	S T Y L E

import './sb-events-view-notifications.scss';

//	S T A T E   -   P R O P S

import { props___SbEventViewNotificationsPage, state___SbEventViewNotificationsPage, state___SbEventViewNotificationsPage___default } from './sb-events-view-notifications.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	F U N C S

import { funcs_datetime } from '../../../funcs/funcs.datetime';
import { funcs_numbers } from '../../../funcs/funcs.numbers';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';
import { service_Router } from '../../../services/service-router';

//	C O M P O N E N T S

import Com404Component from '../../../components/com-404/com-404';
import ComEventComponent from '../../../components/com-event/com-event';
import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';
import ComSpinnerInlineComponent from '../../../components/com-spinner-inline/com-spinner-inline';
import ComEventTimeScheduleComponent from '../../../components/com-event-timeschedule/com-event-timeschedule';

//	C L A S S

class SbEventViewNotificationsPage extends React.Component<props___SbEventViewNotificationsPage, state___SbEventViewNotificationsPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _ROUTER: service_Router = new service_Router();

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();
	private readonly funcs___numbers: funcs_numbers = new funcs_numbers();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbEventViewNotificationsPage,
	) {
		super(props);
		this.state = state___SbEventViewNotificationsPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___poi = async () => {
		const ___temp___event___props: type___api___response = await this._API.events___read___single(this.state.event___id);
		if (!___temp___event___props || ___temp___event___props.response !== 'success' || ___temp___event___props.data === null) { console.log('404'); }
		this.setState({
			event___props: ___temp___event___props.data,
			event___props___is_loading: false,
		}, () => {
			this.setState({
				invite_expiration___datetime___min: this.funcs___datetime.datetime___cut_SZ((new Date()).toISOString() as string),
				invite_expiration___datetime___max: this.funcs___datetime.datetime___cut_SZ((new Date(this.state.event___props['event_datetime_begin'])).toISOString() as string),
				invite_expiration___datetime: this.funcs___datetime.datetime___cut_SZ((new Date(this.state.event___props['event_datetime_end'])).toISOString() as string),
			}, async () => {
				await this.read_props___available_credits();
				await this.read_props___invites_possible_targets();
			});
		});
	}

	private read_props___available_credits = async () => {
		const ___temp___possible_targets: type___api___response = await this._API.credits___read___balance();
		this.setState({
			credits___amount: ___temp___possible_targets['data'] ?? 0,
		});
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___page___refresh = (___e: CustomEvent<RefresherEventDetail>) => {
		this.setState({
			event___props: null,
			event___props___is_loading: true,
		}, async () => {
			await this.read_props___poi();
			___e.detail.complete();
		});
	}

	private read_props___invites_possible_targets = async () => {
		const ___temp___possible_targets: type___api___response = await this._API.events_invites___read___targets(this.state.event___id, this.state.sendable_invites___radius, this.state.sendable_invites___multiplier);
		this.setState({
			sendable_invites___results: ___temp___possible_targets['data']['count_targets'] ?? 1,
			sendable_invites___credits_cost: ___temp___possible_targets['data']['count_credits_cost'] ?? 1,
		});
	}

	private handle___send_invites = async () => {
		const ___temp___possible_targets: type___api___response = await this._API.events_invites___send(this.state.event___id, this.state.sendable_invites___radius, this.state.sendable_invites___multiplier, this.state.invite_expiration___datetime);

		console.log('___temp___possible_targets', ___temp___possible_targets);










	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		const ___event_id: string | null = this._ROUTER.get_id(this.props);
		if (___event_id) {
			this.setState({
				event___id: ___event_id,
			}, async () => {
				await this.read_props___poi();
			});
		}
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref={ '/view/events/' + this.state.event___id }></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>
					{(this.state.event___props___is_loading === true) ? <>

						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<ComSpinnerComponent size="small"/>

					</> : <>

						<IonRefresher slot="fixed" onIonRefresh={ this.handle___page___refresh }>
							<ComSpinnerInlineComponent/>
						</IonRefresher>

						{(this.state.event___props !== null) ? <>
							<div className="sb-page---container sb-events-view-notifications---container">

							<ComEventTimeScheduleComponent event_props={ this.state.event___props }/>

							<br />

							<ComEventComponent event_props={ this.state.event___props } size="normal"/>

								<br />

								<IonListHeader>
									<IonLabel>
										<i className="fas fa-user-plus"></i>
										<span>Invita persone al tuo evento</span>
									</IonLabel>
								</IonListHeader>

								<br />

								<IonCard className="sb-events-view-notifications---slider">
									<IonList>
										<IonItem lines="none">
											<IonLabel><b>Invita persone nel raggio di</b></IonLabel>
											<IonNote slot="end"><b>{ this.state.sendable_invites___radius.toString() + ' km' }</b></IonNote>
										</IonItem>
										<IonItem lines="none">
											<IonRange min={ 1 } max={ 100 } step={ 1 } value={ this.state.sendable_invites___radius } onIonInput={(___e: any) => { this.setState({ sendable_invites___radius: ___e.target.value }); }} onIonChange={(___e: any) => { this.setState({ sendable_invites___radius: ___e.target.value }, () => { this.read_props___invites_possible_targets(); }); }}></IonRange>
										</IonItem>
									</IonList>
									<p>Gli inviti inviati sono targettizzati in base alle preferenze degli utenti e la loro posizione, ci impegnamo ad invitare solo possibili utenti interessati, non invitiamo chi di norma non partecipa agli eventi simili al tuo</p>
								</IonCard>

								<br />

								<IonCard className="sb-events-view-notifications---slider">
									<IonList>
										<IonItem lines="none">
											<IonLabel><b>Moltiplicatore priorità</b></IonLabel>
											<IonNote slot="end"><b>{ 'x' + this.funcs___numbers.number___format_decimal(this.state.sendable_invites___multiplier, 1).toString() }</b></IonNote>
										</IonItem>
										<IonItem lines="none">
											<IonRange min={ 1 } max={ 10 } step={ 0.1 } value={ this.state.sendable_invites___multiplier } onIonInput={(___e: any) => { this.setState({ sendable_invites___multiplier: ___e.target.value }); }} onIonChange={(___e: any) => { this.setState({ sendable_invites___multiplier: ___e.target.value }, () => { this.read_props___invites_possible_targets(); }); }}></IonRange>
										</IonItem>
									</IonList>
									<p>Il moltiplicatore di priorità utilizza un algoritmo per arrivare prima e nei momenti di maggiore attività alle persone che possono essere interessate</p>
								</IonCard>

								<br />

								<IonCard className="sb-events-view-notifications---input">
									<IonList>
										<IonItem lines="none">
											<IonLabel><b>Scadenza inviti</b></IonLabel>
										</IonItem>
										<IonItem lines="none">
											<input type="datetime-local" className="input---nb-outlined" placeholder="dd/mm/yy hh:mm" min={ this.state.invite_expiration___datetime___min } max={ this.state.invite_expiration___datetime___max } defaultValue={ this.state.invite_expiration___datetime } value={ this.state.invite_expiration___datetime } onChange={(___e: any) => { this.setState({ invite_expiration___datetime: ___e.target.value}); }}/>
										</IonItem>
									</IonList>
								</IonCard>

								<br />

								<IonCard>
									<IonList>
										<IonItem lines="none">
											<IonLabel><b>Target Potenziali degli inviti</b></IonLabel>
											<IonNote slot="end">
												<b>{ this.funcs___numbers.number___format_decimal((this.state.sendable_invites___results / 2) , 0).toString() }</b>
												<span> ~ </span>
												<b>{ this.funcs___numbers.number___format_decimal((this.state.sendable_invites___results * 2) , 0).toString() }</b>
											</IonNote>
										</IonItem>
									</IonList>
								</IonCard>

								<br />

								<IonCard>
									<IonList>
										<IonItem lines="none">
											<IonLabel><b>Crediti disponibili</b></IonLabel>
											<IonNote slot="end"><b>{ this.state.credits___amount }</b></IonNote>
											{(this.state.credits___amount <= 0 || this.state.credits___amount < this.state.sendable_invites___credits_cost) ? <>
												<IonNote slot="end" style={{paddingLeft:'7.5pt'}} color="warning">
													<i className="fa-solid fa-triangle-exclamation"></i>
												</IonNote>
											</> : <></>}
										</IonItem>
										<IonItem lines="none">
											<IonLabel><b>Costo in crediti</b></IonLabel>
											<IonNote slot="end"><b>{ this.state.sendable_invites___credits_cost }</b></IonNote>
											{(this.state.credits___amount <= 0 || this.state.credits___amount < this.state.sendable_invites___credits_cost) ? <>
												<IonNote slot="end" style={{paddingLeft:'7.5pt'}} color="warning">
													<i className="fa-solid fa-triangle-exclamation"></i>
												</IonNote>
											</> : <></>}
										</IonItem>
										<IonItem lines="none">
											<IonLabel><b>Costo equivalente in euro</b></IonLabel>
											<IonNote slot="end"><b>{ this.funcs___numbers.number___format_decimal((this.state.sendable_invites___credits_cost / 100), 2) } €</b></IonNote>
											{(this.state.credits___amount <= 0 || this.state.credits___amount < this.state.sendable_invites___credits_cost) ? <>
												<IonNote slot="end" style={{paddingLeft:'7.5pt'}} color="warning">
													<i className="fa-solid fa-triangle-exclamation"></i>
												</IonNote>
											</> : <></>}
										</IonItem>
									</IonList>
								</IonCard>

								<br />

								<p className="sb-events-view-notifications---eula">
									<label htmlFor="sb-events-view-notifications---eula-accepted">
										<input type="checkbox" id="sb-events-view-notifications---eula-accepted" checked={ this.state.sendable_invites___eula_accepted } onChange={(___e: any) => { this.setState({ sendable_invites___eula_accepted: ___e.target.checked })}}/>
										{(this.state.sendable_invites___eula_accepted === true) ? <><i className="far fa-check-square"></i></> : <><i className="far fa-square"></i></>}
										<span>
											<>accetto le condizioni di pagamento e comprendo il funzionamento dei crediti e delle notifiche come descritto nei </> 
											<Link to="/info/eula">Termini e condizioni</Link> 
										</span>
									</label>
								</p>

								<br />

								<IonCard>
									<IonList>
										{(this.state.sendable_invites___eula_accepted === true) ? <>
											<IonItem onClick={ this.handle___send_invites } color="primary" style={{textAlign:'center'}}>
												<IonLabel><b>Invia Inviti</b></IonLabel>
											</IonItem>
										</> : <>
											<IonItem style={{opacity:'0.5',textAlign:'center'}}>
												<IonLabel><b>Invia Inviti</b></IonLabel>
											</IonItem>
										</>}
									</IonList>
								</IonCard>

								<br />
								<br />

							</div>
						</> : <>
							<Com404Component/>
						</>}

					</>}
				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbEventViewNotificationsPage);