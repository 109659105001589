import { type___locale___translations___collection } from '../../types/types.locale';

//	E X P O R T   L O C A L E

export const locale___ComPoiTimetableTableComponent: type___locale___translations___collection = {
	'closed___day': {
		de_de: 'geschlossen',
		en_us: 'Closed',
		es_es: 'Cerrado',
		fr_fr: 'Fermé',
		it_it: 'Chiuso',
	},
};