import { interface___modal } from "../../interfaces/interface.modals";
import { type___geo___lngLat } from "../../types/types.types";

//	P R O P S

export interface props___MdPositionSelectorModal extends interface___modal {

	position___input: type___geo___lngLat | null,

}

//	S T A T E

export interface state___MdPositionSelectorModal {

	position___coords: type___geo___lngLat,
	position___coords___address: string,

}

//	S T A T E   D E F A U L T

export const state___MdPositionSelectorModal___default: state___MdPositionSelectorModal = {
	
	position___coords: { lng: 0, lat: 0 },
	position___coords___address: 'Nessun indirizzo selezionato',

}