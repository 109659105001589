import { RouteComponentProps } from "react-router";
import { type___catalog___item } from "../../../types/types.catalog";

//	P R O P S

export interface props___SbCatalogCarsHubVehicleViewPage extends RouteComponentProps<{ id: string; }> {

}

//	S T A T E

export interface state___SbCatalogCarsHubVehicleViewPage {

	vehicle___id: string,

	vehicle___props: type___catalog___item | null,
	vehicle___props___is_loading: boolean,





	ionModal___MdMediaViewModal___visible: boolean,
	ionModal___MdMediaViewModal___argument: string,

}

//	S T A T E   D E F A U L T

export const state___SbCatalogCarsHubVehicleViewPage___default: state___SbCatalogCarsHubVehicleViewPage = {

	vehicle___id: '',

	vehicle___props: null,
	vehicle___props___is_loading: true,

	



	ionModal___MdMediaViewModal___visible: false,
	ionModal___MdMediaViewModal___argument: '',

}