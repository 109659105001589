import { lib_names } from '../libs/lib.names';
import { type___geo___lngLat } from '../types/types.types';

//	E X P O R T

export class service_GeolocationService
{

//#region 																							L O C A T E

	geolocation___locate = async () : Promise<type___geo___lngLat|null> => {
		const ___return_nil: null = null;
		return new Promise((resolve, reject) => {
			if (!navigator.geolocation) {
				reject(___return_nil);
				return null;
			}
			try {
				navigator.geolocation.getCurrentPosition(
					(___position: GeolocationPosition) => {
						const ___me___geolocation: type___geo___lngLat = { lng: ___position.coords.longitude, lat: ___position.coords.latitude };
	
	
	
	
	
	
	
	
	
						
						resolve(___me___geolocation);
						return ___me___geolocation;
					},
					(___error: GeolocationPositionError) => {
						switch (___error.code) {
							case ___error.PERMISSION_DENIED: reject(___return_nil); return ___return_nil; break;
							case ___error.POSITION_UNAVAILABLE: reject(___return_nil); return ___return_nil; break;
							case ___error.TIMEOUT: reject(___return_nil); return ___return_nil; break;
							default: reject(___return_nil); return ___return_nil; break;
						}
					}
				);
			} catch (___ge: any ) {
				console.log('Error getting location --> ', ___ge)
				reject(___return_nil); return ___return_nil;
			}
		
		}) as Promise<type___geo___lngLat|null>;
	}

//#endregion

}