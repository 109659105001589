import React from 'react'

//	L O C A L I Z A T I O N

import { locale___ComInfiniteScrollerComponent } from './com-infinite-scroller.locale';

//	S T Y L E

import './com-infinite-scroller.scss'

//	S T A T E S   -   P R O P S

import { props___ComTaleComponent  } from './com-infinite-scroller.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C O M P O N E N T S

import ComSpinnerInlineComponent from '../com-spinner-inline/com-spinner-inline';

//	C L A S S

export default class ComInfiniteScrollerComponent extends React.Component<props___ComTaleComponent, {}>
{

//#region 																							D E C L A R A T I O N S 

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

	private element___interactionObserver: IntersectionObserver;
	private element___infiniteScroller: React.RefObject<HTMLDivElement> = React.createRef();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComTaleComponent,
	)
	{
		super(props);
		this.element___infiniteScroller = React.createRef();
	}

//#endregion


//#region 																							H A N D L E R S

	private handleIntersection = (___e_entries: any, ___e_observer: any) => {
		if (!(this.props.is_loading && this.props.is_loading === true)) {
			___e_entries.forEach((___e: any) => {
				if (___e.isIntersecting) { this.props.event___scrollTriggered(); }
			});
		}
	}
	
//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount() : void {
		if ('IntersectionObserver' in window) {
			this.element___interactionObserver = new IntersectionObserver(this.handleIntersection, { root: null, rootMargin: '0px', threshold: 0.1 });
			if (this.element___infiniteScroller.current) { this.element___interactionObserver.observe(this.element___infiniteScroller.current); }
		}
	}

	componentWillUnmount() : void {
		if (this.element___interactionObserver && this.element___infiniteScroller.current) {
			this.element___interactionObserver.unobserve(this.element___infiniteScroller.current);
		}
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="com-infinite-scroller-component---container" ref={ this.element___infiniteScroller }>
				{(this.props.is_loading && this.props.is_loading === true) ? <>
					<ComSpinnerInlineComponent/>
				</> : <>
					<p onClick={() => { this.props.event___scrollTriggered(); }}>
						{ this._LOCALE.translate(locale___ComInfiniteScrollerComponent.load_more) }
					</p>
				</>}
			</div>
		</>;
	}

//#endregion

}