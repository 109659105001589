import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar, withIonLifeCycle } from '@ionic/react';
import packageJson from '../../../../package.json';
import crc from 'crc';

//	L O C A L I Z A T I O N

import { locale___App } from '../../../App.locale';
import { locale___SbInfoAppPage } from './sb-info-app.locale';

//	S T Y L E

import './sb-info-app.scss';

//	S T A T E   -   P R O P S

import { state___SbInfoAppPage, state___SbInfoAppPage___default } from './sb-info-app.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';

//	C O M P O N E N T S

import ComSpinnerTagComponent from '../../../components/com-spinner-tag/com-spinner-tag';
import ComCopyrightComponent from '../../../components/com-copyright/com-copyright';

//	C L A S S

class SbInfoAppPage extends React.Component<{}, state___SbInfoAppPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: {},
	) {
		super(props);
		this.state = state___SbInfoAppPage___default;
	}

//#endregion

//#region 																							V E R S I O N

	version___get___version()
	{
		const ___version: string = packageJson['version'];
		this.setState({ app_version: ___version });
	}

	version___get___build()
	{
		const ___package: string = packageJson['version'];
		const ___package___hash: string = crc.crc16(___package).toString();
		this.setState({ app_version_build: ___package___hash });
	}

//#endregion

//#region 																							L I F E C Y C L E

	async ionViewDidEnter() : Promise<void> {
		this.version___get___version();
		this.version___get___build();
		setTimeout(() => {
			this.setState({
				app_version___is_loading: false
			});
		}, 150);
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/discover" text={ this._LOCALE.translate(locale___App.element___IonBackButton) } />
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>
					<section className="sb-info-app---container">
						
						<div className="sb-info-app---icon">
							<img src="/assets/icons/apple-icon.png" />
						</div>
						
						<section>
							<img src="/assets/logo.horizontal.svg" />
								{(this.state.app_version___is_loading === true) ? <>
									<h3><ComSpinnerTagComponent/></h3>
								</> : <>
									<h3>{ this._LOCALE.translate(locale___SbInfoAppPage.version_and_build, [this.state.app_version, this.state.app_version_build]) }</h3>
								</>}
						</section>

						<br />
						<br />
						<br />
						<br />

						<ComCopyrightComponent/>

					</section>
				</IonContent>
				
			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbInfoAppPage);