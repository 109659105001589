import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	S T Y L E

import './sb-settings-credits.scss';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';
import { props___SbSettingsCreditsPage, state___SbSettingsCreditsPage, state___SbSettingsCreditsPage___default } from './sb-settings-main.state';
import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';
import ComSpinnerInlineComponent from '../../../components/com-spinner-inline/com-spinner-inline';

//	C L A S S

class SbSettingsCreditsPage extends React.Component<props___SbSettingsCreditsPage, state___SbSettingsCreditsPage>
{

//#region 																							D E C L A R A T I O N S
		
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbSettingsCreditsPage,
	) {
		super(props);
		this.state = state___SbSettingsCreditsPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___credit_balance = async () => {
		const ___credit_balance___temp: type___api___response = await this._API.credits___read___balance();
		this.setState({
			credits_amount: ___credit_balance___temp.data as number,
			credits_amount___is_loading: false
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewWillEnter() : void
	{
		this.setState({
			credits_amount: 0,
			credits_amount___is_loading: true
		}, async () => {
			await this.read_props___credit_balance();
		});
	}













//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<IonHeader className="ion-no-border" collapse="fade">
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/settings"></IonBackButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					<section className="sb-settings-credits---container">

						<IonListHeader>
							<IonLabel>Bilancio Crediti</IonLabel>
						</IonListHeader>

						<br />

						{(this.state.credits_amount___is_loading === true) ? <>

							<ComSpinnerInlineComponent />

						</> : <>
						
							<IonList>
								<IonItem className="is-ion-item-button">
									<i className="fas fa-coins"></i>
									<IonLabel>
										{ this.state.credits_amount }
									</IonLabel>
								</IonItem>
							</IonList>
						
						</>}

						





















					</section>

				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbSettingsCreditsPage);