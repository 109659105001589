//	P R O P S

import { type___message___attachment_type } from "../../types/types.messages";

export interface props___ComChatMessageAttachmentComponent {

	message_props: any,

}

//	S T A T E

export interface state___ComChatMessageAttachmentComponent {

	attachment_id: string | null,
	attachment_type: type___message___attachment_type | null,

	attachment_props: any | null,
	attachment_props___is_loading: boolean,

}

//	S T A T E   D E F A U L T

export const state___ComChatMessageAttachmentComponent___default: state___ComChatMessageAttachmentComponent = {
	
	attachment_id: null,
	attachment_type: null,

	attachment_props: null,
	attachment_props___is_loading: true,


}