/* 
 * 
 * 
 * 
 * 								L I B   N A M E S   S H A R E D
 * 
 * 
 * 
 */

export const lib_names_shared = {
	endpoints: {
		external_nopr: 'https://app.nopr.me/',
		media_storage: 'https://media.nearby.coffee/',
		media_user_avatar: 'https://ui-avatars.com/api/?size=256&name=',
		server_carshub: 'https://server.carshub.app/external/v2/nearby/',
		server_nearby: 'https://server.nearby.ws/',
	//	server_nearby: 'http://localhost:3000/',
		server_nopr: 'https://server.nopr.me/external/',
		sharing: 'https://share.nearby.ws/',
	},
	files: {
		input_accepted: '.jpg, .jpeg, .png',
	},
	regex: {
		credential___username: /^[a-zA-Z0-9_]{5,12}$/,
		credential___email: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z_.-]{2,}$/i,
		credential___password: /^(.{6,16})$/,
		css_rotation: /rotate\(([^)]+)deg\)/,
		input___datetime: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/,
		text___url: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
		text___hashtag: /^#\w+/,
		text___usertag: /^@\w+/,
		text___whitespace: /\s+/,
		types___objectId: /\b[a-f0-9]{24}\b/,
	},
}