import React from 'react'

//	S T Y L E

import './com-chat-conversation-micro.scss'

//	S T A T E   P R O P S

import { props___ComChatConversationMicroComponent } from './com-chat-conversation-micro.state';

//	C O M P O N E N T S

import ComChatConversationOnlineComponent from '../com-chat-conversation-online/com-chat-conversation-online';
import ComChatConversationUnreadComponent from '../com-chat-conversation-unread/com-chat-conversation-unread';

//	C L A S S

export default class ComChatConversationMicroComponent extends React.Component<props___ComChatConversationMicroComponent, {}>
{

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="com-chat-conversation-micro---container">
				<section>
					<img src={ this.props.chat_props['chat_with']['user_image_profile'] }/>
					{(this.props.chat_props['chat_messages_unread'] && this.props.chat_props['chat_messages_unread'] > 0) ? <>
						<ComChatConversationUnreadComponent message_unread_quantity={ this.props.chat_props['chat_messages_unread'] }/>
					</> : <></>}
					<ComChatConversationOnlineComponent />
				</section>
			</div>
		</>;
	}

//#endregion

}