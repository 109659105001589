import React from 'react';
import { IonCard, IonContent, IonFooter, IonItem, IonLabel, IonList, IonListHeader, IonModal } from '@ionic/react';
import mapboxgl from 'mapbox-gl';

//	S T Y L E

import './md-position-selector.scss';

//	T Y P E S

import { type___geo___lngLat, type___map___position_response } from '../../types/types.types';
import { type___modal___config } from '../../types/types.modals';

//	S T A T E   -   P R O P S

import { props___MdPositionSelectorModal, state___MdPositionSelectorModal, state___MdPositionSelectorModal___default } from './md-position-selector.state';

//	L I B S

import { lib_names } from '../../libs/lib.names';

//	F U N C S

import { funcs_geolocation } from '../../funcs/funcs.geolocation';

//	S E R V I C E S

import { service_GeolocationService } from '../../services/service-geolocation';

//	C O M P O N E N T S

import ComSpinnerComponent from '../../components/com-spinner/com-spinner';

//	C L A S S

export default class MdPositionSelectorModal extends React.PureComponent<props___MdPositionSelectorModal, state___MdPositionSelectorModal>
{

//#region 																							D E C L A R A T I O N S

	private readonly _GEOLOCATION: service_GeolocationService = new service_GeolocationService();

	private readonly funcs___geolocation: funcs_geolocation = new funcs_geolocation();

	private mapbox_position_selector___map___container: React.RefObject<HTMLDivElement>;
	private mapbox_position_selector___map___loaded: boolean = false;
	private mapbox_position_selector___map: mapboxgl.Map;
	private mapbox_position_selector___map___center: type___geo___lngLat;
	private mapbox_position_selector___map___marker: mapboxgl.Marker;

//#endregion

//#region 																							C O N F I G

	private readonly MdPositionSelectorModal___modal_config: type___modal___config = {
		backdropDismiss: true,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent(); },
		onDidDismiss: () => { this.event___onDidDismiss(); },
	};

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdPositionSelectorModal,
	)
	{
		super(props);
		this.state = state___MdPositionSelectorModal___default;
		this.mapbox_position_selector___map___container = React.createRef();
	}

//#endregion

//#region 																							M A P

	private map___prepare = () => {

		if (this.mapbox_position_selector___map___loaded !== true)
		{

			this.mapbox_position_selector___map = new mapboxgl.Map({
				accessToken: lib_names.mapboxgl.accessToken,
				container: this.mapbox_position_selector___map___container.current!,
				style: lib_names.mapboxgl.style_normal,
				center: [this.mapbox_position_selector___map___center.lng, this.mapbox_position_selector___map___center.lat],
				zoom: lib_names.mapboxgl.zoom,
		//		minZoom: lib_names.mapboxgl.minZoom,
				maxZoom: lib_names.mapboxgl.maxZoom,
				dragRotate: lib_names.mapboxgl.dragRotate,
				doubleClickZoom: lib_names.mapboxgl.doubleClickZoom,
				touchPitch: lib_names.mapboxgl.touchPitch,
				maxPitch: lib_names.mapboxgl.maxPitch,
			});

			this.mapbox_position_selector___map.resize();
			this.mapbox_position_selector___map___loaded = true;

	/*		this.mapbox_position_selector___map.on('preclick', () => {
			//	this.handle___select___map_element___reset();
			});

			this.mapbox_position_selector___map.on('moveend', async () => {
			//	const ___mapbox_position_selector___map___center: mapboxgl.LngLat = this.mapbox___map.getCenter();
			//	this.mapbox_position_selector___map___center___lng = ___mapbox_position_selector___map___center.lng;
			//	this.mapbox_position_selector___map___center___lat = ___mapbox_position_selector___map___center.lat;
			//	await this.map___load___datas();
			});

			*/

			this.mapbox_position_selector___map.on('click', (___e: any) => {
				this.setState({
					position___coords: { lng: ___e.lngLat.lng, lat: ___e.lngLat.lat}
				}, async () => {
					this.mapbox_position_selector___map___marker.setLngLat([ this.state.position___coords.lng, this.state.position___coords.lat ]);
					await this.geolocation___get_address(this.state.position___coords);
				});
			});

			this.mapbox_position_selector___map.on('load', async() => {

				this.mapbox_position_selector___map.resize();

				this.mapbox_position_selector___map___marker = new mapboxgl.Marker()
					.setLngLat([this.state.position___coords.lng, this.state.position___coords.lat])
					.addTo(this.mapbox_position_selector___map);

				this.mapbox_position_selector___map.resize();
				this.mapbox_position_selector___map___loaded = true;

				this.map___fly___to(this.state.position___coords);

			});

		}
	}

	private map___fly___to = (___coords_to: type___geo___lngLat, ___zoom: number = 18) => {
		this.mapbox_position_selector___map.flyTo({
			center: [ ___coords_to.lng, ___coords_to.lat],
			zoom: ___zoom
		});
	//	this.___map.setZoom(lib_names.mapboxgL.maxZoom);
	}

//#endregion

//#region 																							G E O L O C A T I O N

	private geolocation___locate = async () => {
		const ___me___location: type___geo___lngLat | null = await this._GEOLOCATION.geolocation___locate();


		console.log('___me___location', ___me___location)

		this.mapbox_position_selector___map___center = ___me___location ?? { lng: 8, lat: 39 };
		this.map___prepare();
		await this.geolocation___get_address(___me___location);
	}

	private geolocation___get_address = async (___coords: type___geo___lngLat | null) => {
		if (___coords === null) {
			const ___address: string = await this.funcs___geolocation.geolocation___get_address___from_coords(___coords);
			this.setState({
				position___coords___address: ___address
			});
		}
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___position___save = () => {
		const ___to_dismiss___position: type___map___position_response = { coords: this.state.position___coords, address: this.state.position___coords___address };
		this.props.event___onDidDismiss(___to_dismiss___position);
	}

//#endregion

//#region 																							L I F E C Y C L E

	private async event___onDidPresent() : Promise<void> {
		if (this.props.position___input !== null) {
			this.mapbox_position_selector___map___center = this.props.position___input;
			setTimeout( async () => {
				this.map___prepare();
			}, 100);
		} else {
			await this.geolocation___locate();
		}
	}

	private event___onDidDismiss() : void {
		this.mapbox_position_selector___map___loaded = false;
		this.setState({ 
			...state___MdPositionSelectorModal___default
		}, () => {
			this.mapbox_position_selector___map.remove();
		});
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonModal isOpen={ this.props.isOpen } { ...this.MdPositionSelectorModal___modal_config }>

				<IonContent forceOverscroll={ false }>
					<section className="md-modal---container">

						<IonListHeader>
							<IonLabel>
								<i className="fas fa-map"></i>
								<span>Posizione</span>
							</IonLabel>
						</IonListHeader>

						<br />

						<IonList>
							<IonItem lines="none" className="is-ion-item-button">
								<i className="fas fa-map-marked-alt"></i>
								{(this.state.position___coords !== null) ? <>
									<span>{ this.state.position___coords___address }</span>
								</> : <>
									<span>Nessun indirizzo selezionato</span>
								</>}
							</IonItem>
						</IonList>

						<br />

{/**
						{(this.mapbox_position_selector___map___loaded === false) ? <>
							<section className="md-position-selector---selector">
								<ComSpinnerComponent size="small"/>
							</section>
						</> : <></>}
 */}
						<IonCard className="md-position-selector---container">
							<div ref={ this.mapbox_position_selector___map___container } className="md-position-selector---map"/>
						</IonCard>








					</section>
				</IonContent>

				<IonFooter>
					<section className="container---footer-section">
						<button className="is-element is-button is-color---folly is-full-width" onClick={ this.handle___position___save }>
							<i className="fa-solid fa-check"></i>
							<h4>Salva posizione</h4>
						</button>
					</section>
				</IonFooter>

			</IonModal>
		</>;
	}

//#endregion

}