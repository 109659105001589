import { type___locale___translations___collection } from "./types/types.locale";

//	E X P O R T   L O C A L E

export const locale___App: type___locale___translations___collection = {
	'element___IonBackButton': {
		de_de: 'zurück',
		en_us: 'back',
		es_es: 'volver',
		fr_fr: 'revenir',
		it_it: 'indietro',
	},
	












	'__________________': {
		de_de: '__________________',
		en_us: '__________________',
		es_es: '__________________',
		fr_fr: '__________________',
		it_it: '__________________',
	},
	



};