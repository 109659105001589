import React from 'react';
import { IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonPage, IonToolbar, withIonLifeCycle } from '@ionic/react';

//	L O C A L I Z A T I O N

import { locale___App } from '../../../App.locale';
import { locale___SbInfoPresentationPage } from './sb-info-presentation.locale';

//	S T Y L E

import './sb-info-presentation.scss';

//	P R O P S   -   S T A T E

import { props___SbInfoPresentationPage, state___SbInfoPresentationPage, state___SbInfoPresentationPage___default } from './sb-info-presentation.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';

//	C O M P O N E N T S

import ComCopyrightComponent from '../../../components/com-copyright/com-copyright';

//	M O D A L S

import MdEntryLanguageModal from '../../../modals/md-entry-language/md-entry-language';
import MdEntryInterestsModal from '../../../modals/md-entry-interests/md-entry-interests';

//	C L A S S

class SbInfoPresentationPage extends React.Component<props___SbInfoPresentationPage, state___SbInfoPresentationPage>
{

//#region 																							D E C L A R A T I O N S
	
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbInfoPresentationPage,
	) {
		super(props);
		this.state = state___SbInfoPresentationPage___default;
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<MdEntryInterestsModal isOpen={ this.state.ionModal___MdEntryInterestsModal___isOpen } isDismissible={ true } is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props } event___onDidDismiss={() => { this.setState({ ionModal___MdEntryInterestsModal___isOpen: false }); }}/>
				<MdEntryLanguageModal isOpen={ this.state.ionModal___MdEntryLanguageModal___isOpen } isDismissible={ true } event___onDidDismiss={() => { this.setState({ ionModal___MdEntryLanguageModal___isOpen: false }); }}/>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/discover/map" text={ this._LOCALE.translate(locale___App.element___IonBackButton) } />
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>
					<section className="sb-info-presentation---container">
						
						<IonCard>
							<IonList>
								<IonItem className="is-ion-item-button" routerLink="/sign" routerDirection="forward">
									<i className="fa-solid fa-user"></i>
									<IonLabel><b>{ this._LOCALE.translate(locale___SbInfoPresentationPage.go_to_login) }</b></IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

						<br />
						<br />

						<section>
							<img src="/assets/logo.horizontal.svg"/>
							<h4>{ this._LOCALE.translate(locale___SbInfoPresentationPage.motto_title) }</h4>
							<p>{ this._LOCALE.translate(locale___SbInfoPresentationPage.motto_description) }</p>
						</section>
						
						<br />
						<br />
						<br />

						<IonCard>
							<IonList>
								<IonItem className="is-ion-item-button" button={ true } onClick={() => { this.setState({ ionModal___MdEntryInterestsModal___isOpen: true }); }}>
									<i className="fas fa-icons"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbInfoPresentationPage.preferences___interests) }</IonLabel>
								</IonItem>
								<IonItem className="is-ion-item-button" button={ true } onClick={() => { this.setState({ ionModal___MdEntryLanguageModal___isOpen: true }); }}>
									<img src={ '/assets/localization/locale.' + this._LOCALE.language_get() + '.svg' } />
									<IonLabel>{ this._LOCALE.translate(locale___SbInfoPresentationPage.preferences___language) }</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

						<br />

						<IonCard>
							<IonList>
								<IonItem className="is-ion-item-button" button={ true } routerLink="/info/support">
									<i className="fa-solid fa-headset"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbInfoPresentationPage.support___contact_us) }</IonLabel>
								</IonItem>
							{/*
								<IonItem className="is-ion-item-button" button={ true } routerLink="/info/faq">
									<i className="fa-solid fa-question-circle"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbInfoPresentationPage.support___faq) }</IonLabel>
								</IonItem>
							*/}
								<IonItem className="is-ion-item-button" routerLink="/settings/cache" routerDirection="forward">
									<i className="fa-solid fa-database"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbInfoPresentationPage.support___clear_cache) }</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

						<br />

						<IonCard>
							<IonList>
								<IonItem className="is-ion-item-button" routerLink="/info/stats" routerDirection="forward">
									<i className="fa-solid fa-chart-bar"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbInfoPresentationPage.about___stats) }</IonLabel>
								</IonItem>
								<IonItem className="is-ion-item-button" routerLink="/info/eula" routerDirection="forward">
									<i className="fa-solid fa-balance-scale"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbInfoPresentationPage.about___eula) }</IonLabel>
								</IonItem>
								<IonItem className="is-ion-item-button" routerLink="/info/partners" routerDirection="forward">
									<i className="fas fa-crown"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbInfoPresentationPage.about___partners) }</IonLabel>
								</IonItem>
								<IonItem className="is-ion-item-button" routerLink="/info/thanks" routerDirection="forward">
									<i className="fa-solid fa-user-friends"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbInfoPresentationPage.about___thanks) }</IonLabel>
								</IonItem>
								<IonItem className="is-ion-item-button" routerLink="/info/app" routerDirection="forward">
									<i className="fa-solid fa-code-branch"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbInfoPresentationPage.about___version) }</IonLabel>
								</IonItem>
								<IonItem className="is-ion-item-button" routerLink="/info/licences" routerDirection="forward">
									<i className="fa-solid fa-key"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbInfoPresentationPage.about___licences) }</IonLabel>
								</IonItem>
								<IonItem className="is-ion-item-button" routerLink="/info/copyright" routerDirection="forward">
									<i className="fa-solid fa-copyright"></i>
									<IonLabel>{ this._LOCALE.translate(locale___SbInfoPresentationPage.about___copyright) }</IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

					</section>

					<br />
					<br />
					<br />

					<ComCopyrightComponent />

					<br />

				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbInfoPresentationPage);